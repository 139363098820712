/* global angular:true, _:true, window:true, jQuery:true  */
/* jslint regexp: true */
import Logger from 'js-logger';

import { helioscopeConfig } from 'helioscope/app/config';
import { User } from 'helioscope/app/users/resources';
import {
    Messager,
    $state,
    $stateParams,
    Authenticator,
} from 'helioscope/app/utilities/ng';
import { getBetaAccountOverviewURL, getBetaBillingURL } from 'helioscope/app/utilities/url';

const logger = Logger.get('user/controllers');

const mod = angular.module('helioscope.users.controllers', [
    'helioscope.services',
    'helioscope.users.auth',
    'helioscope.users.config',
    'helioscope.libraries.resources',
    'helioscope.users.services',
    'helioscope.users.logos',
]);

const MAX_TRIAL_EXTENSIONS = 1;

class NotificationBar {
    constructor(scope) {
        this.scope = scope;
        this.scope.message = {};
        this.scope.dismissMessage = () => this.dismissMessage();
    }

    dismissMessage() {
        delete this.scope.message;
    }

    success(message) {
        this.scope.message = { type: 'success', text: message };
        this.scope.$apply();
    }

    error(message) {
        this.scope.message = { type: 'error', text: message };
        this.scope.$apply();
    }
}


mod.controller('AccountCtrl', function accountCtrl($scope) { // eslint-disable-line prefer-arrow-callback
    angular.noop($scope);
    if (!$state.includes('account.detail') || $state.params.email.length === 0) {
        logger.log('redirecting to current user account');
        $state.transitionTo('account.detail.user', { email: 'me' });
    }
});

mod.controller('AccountDetailCtrl', ($scope, selectedUser, $rootScope) => {
    $scope.currentUser = selectedUser;

    $rootScope.accountData = {
        currentUser: selectedUser,
    };

    $scope.subscriptionPaid = () => selectedUser.subscription.isActive();
    $scope.notificationBar = new NotificationBar($scope);
});

mod.controller('AccountUserCtrl',
    function accountUserCtrl($scope, ClientBrowser, Logo, logoGalleryDlg) { // eslint-disable-line prefer-arrow-callback
        $scope.newUser = angular.copy($scope.currentUser);
        $scope.isOldIE = ClientBrowser.isOldIE();

        if ($scope.newUser.default_logo_id) {
            Logo.get({}, { logo_id: $scope.newUser.default_logo_id, user: $scope.newUser });
        }

        $scope.openLogoGallery = () => {
            logoGalleryDlg($scope.newUser);
        };

        $scope.submit = () => {
            const notification = Messager.load('Updating your account');
            const user = angular.copy($scope.newUser);

            $scope.isLoading = true;

            delete $scope.newUser.password;
            delete $scope.errors;

            user.$update((u) => {
                angular.extend($scope.currentUser, u);
                $scope.newUser = u;
                $scope.isLoading = false;
                notification.success('Successfully updated your account');
            }, (response) => {
                logger.warn('Could not update profile');
                logger.warn(response);
                $scope.isLoading = false;
                $scope.errors = response.data;

                notification.error('Could not update your account');
            });
        };

        // WARNING - this will not revert user logo changes
        $scope.cancel = () => {
            $scope.newUser = angular.copy($scope.currentUser);
            $scope.form.$setPristine();
        };
    });

mod.controller('PasswordCtrl', function passwordCtrl($scope) { // eslint-disable-line prefer-arrow-callback
    $scope.betaAccountOverview = getBetaAccountOverviewURL();
});


mod.controller('PreferencesCtrl',
    function prefs($scope, wires, $filter) { // eslint-disable-line prefer-arrow-callback
        const wireFilter = $filter('wireFilter');
        $scope.filteredWires = wires;
        $scope.newUser = angular.copy($scope.currentUser);
        $scope.allExperiments = _.get(helioscopeConfig, 'admin_data.all_experiments');

        $scope.updateFilter = () => {
            $scope.filteredWires = wireFilter(wires, $scope.newUser.preferences.wiring);
        };

        $scope.updateFilter();

        $scope.allWires = (wireId) => {
            const wire = _.find(wires, wi => wi.wire_gauge_id === wireId);
            if ($scope.filteredWires.indexOf(wire) !== -1) {
                return $scope.filteredWires;
            }

            return [wire].concat($scope.filteredWires);
        };

        $scope.resolutions = [
            { x: 1200, y: 900 },
            { x: 1600, y: 1200 },
            { x: 2000, y: 1600 },
            { x: 2400, y: 2000 },
        ];

        function matchedResolution() {
            return _.find($scope.resolutions, res => (
                res.x === $scope.newUser.preferences.design_renders.resolution.x &&
                res.y === $scope.newUser.preferences.design_renders.resolution.y),
            );
        }
        $scope.newUser.preferences.design_renders.resolution = matchedResolution();
        $scope.newUser.preferences.designer.label_scale_factor = (
            $scope.newUser.preferences.designer.label_scale_factor || 1.0
        );

        $scope.newUser.preferences.features = $scope.newUser.preferences.features || {};

        $scope.submit = () => {
            const notification = Messager.load('Updating your preferences');
            const user = angular.copy($scope.newUser);

            $scope.isLoading = true;

            delete $scope.newUser.password;
            delete $scope.errors;

            if (user.preferences.mechanical && user.preferences.mechanical.module_id === '') {
                delete user.preferences.mechanical.module_id;
            }

            user.$updatePreferences((us) => {
                $.extend(true, $scope.currentUser, us);
                if (us.email === Authenticator.user().email) {
                    $.extend(true, Authenticator.user(), us);
                }

                $scope.newUser = us;
                $scope.newUser.preferences.design_renders.resolution = matchedResolution();
                $scope.isLoading = false;
                notification.success('Successfully updated your preferences');
            }, (response) => {
                logger.warn('Could not update profile');
                logger.warn(response);
                $scope.isLoading = false;
                $scope.errors = response.data;

                notification.error('Could not update your preferences');
            });
        };

        $scope.cancel = () => {
            $scope.newUser = angular.copy($scope.currentUser);
            $scope.form.$setPristine();
        };
    });

mod.controller('NotActivatedModalCtrl', ['$scope', function notActivatedModalCtrl($scope) {
    $scope.emailSentTo = ($stateParams.email || null);
    $scope.emailToActivate = ($stateParams.email || null);
    $scope.emailStatus = null; // states: null, 'ask', 'sent'


    $scope.resendActivationEmail = () => {
        if ($scope.emailToActivate) {
            $scope.isSending = true;
            User.resendAuthToken({ email: $scope.emailToActivate }, {},
                () => {
                    $scope.emailStatus = 'sent';
                    $scope.isSending = false;
                }, () => {
                    Messager.error(
                        'Error: could not send activation email.  Please try again.', { icon: 'fa fa-minus-circle' },
                    );
                    $scope.isSending = false;
                });
        }
    };
}]);

mod.controller('WelcomeCtrl',
    function welcomeCtrl($modalInstance, $scope, newProjectDlg, user, ContextualHelp, defaultProfiles) {
        const ctrl = this;
        let userBackup = angular.copy(user);
        const unitOptions = {
            ft: {
                distance: 'ft',
                wiring: 'awg',
            },
            m: {
                distance: 'm',
                wiring: 'metric',
            },
        };

        const updaters = {
            units() {
                return user.$updatePreferences();
            },
            profile(profileType) {
                user.system_type = profileType;
                return user.$update();
            },
        };

        this.user = user;
        this.status = {
            units: null,
            profile: null,
        };

        this.profiles = {
            residential: _.find(defaultProfiles, { name: 'Default Residential' }),
            commercial: _.find(defaultProfiles, { name: 'Default Commercial' }),
            utility: _.find(defaultProfiles, { name: 'Default Ground-mount' }),
        };

        this.updateUser = (updateType, profileType = '') => {
            this.status[updateType] = 'saving';

            // keep the wiring in sync
            user.preferences.wiring.wire_units = unitOptions[user.preferences.units.distance].wiring;

            updaters[updateType](profileType).then(
                () => {
                    ctrl.status[updateType] = 'saved';
                    userBackup = angular.copy(user);
                },
                (error) => {
                    // set display back to original units
                    angular.extend(user, userBackup);
                    ctrl.status[updateType] = 'saved';
                    logger.error(error);
                },
            );
        };

        $modalInstance.result.finally(() => {
            if (ContextualHelp.hasHelpModal()) {
                ContextualHelp.showHelpModal();
            }
        });

        this.newProject = () => newProjectDlg.openDialog($scope);
    });

mod.controller('ExpiredModalCtrl', ['$scope', '$modalInstance', 'showProjectLimitHeader', 'trialExtensionCount',
    async function expireModalCtrl($scope, $modalInstance, showProjectLimitHeader, trialExtensionCount) {
        $scope.minimal = true; // hiding some parts of the feature list
        $scope.email = Authenticator.user().email;
        $scope.wasSubscribed = Authenticator.user().latest_subscription != null;
        $scope.loading = false;
        $scope.allowExtensions = trialExtensionCount.count < MAX_TRIAL_EXTENSIONS;

        $scope.hasProjectLimitReached = showProjectLimitHeader ?
            await $scope.user().hasUsersProjectLimitReached() : false;
        $scope.hideFooter = $scope.hasProjectLimitReached || $scope.wasSubscribed;
        $scope.useV1Billing = $scope.user().showV1Billing();
        $scope.betaBillingURL = (product) => getBetaBillingURL($scope.user(), '', {
            product,
            dialog: 'initial',
            interval: 'month',
            referrer: 'expired_account_dialog'
        });
        $scope.isTrialExpired = $scope.user().status === 'expired_trial';
        $scope.isUnpaidPayByInvoice = $scope.user().latest_subscription &&
            $scope.user().latest_subscription.cleaned_status === "unpaid" &&
            $scope.user().latest_subscription.is_pay_by_invoice;
        $scope.isSubscriptionExpired = $scope.user().status === 'expired_subscription';
        $scope.canManageBilling = $scope.user().canManageBilling();
        // Can't use the selfServeDisabled get property because it doesn't evaluate to a boolean within this controller.
        $scope.selfServeDisabled =
        $scope.user().latest_subscription &&
        $scope.user().latest_subscription.is_contract &&
        !$scope.user().latest_subscription.enable_self_serve;
        $scope.showIndependentTiltCopy = $scope.user().hasFeature('enable_independent_tilt_marketing_copy');

        $scope.extendTrial = () => {
            const notify = Messager.load('Requesting trial extension...');
            $scope.loading = true;
            Authenticator.user().$extendTrial(
                { reason: $scope.reason },
                (user) => {
                    Authenticator.loginUser(user);
                    notify.success('Trial extended successfully!');
                    $scope.loading = false;
                    $modalInstance.dismiss();
                },
                () => {
                    notify.error('There was an error extending your trial period. Please contact ' +
                                '<a href="mailto:support@helioscope.com">support</a> if this issue continues.');
                    $scope.loading = false;
                },
            );
        };
        if ($state.current.name === 'home.expired') {
          if ($scope.isUnpaidPayByInvoice) {
              window.location.href = getBetaBillingURL(Authenticator.user(), '', {});
              setTimeout(() => {
                  $modalInstance.close();
              });
          } else if (!Authenticator.user().isExpired()) {
            $state.go('home');
            setTimeout(() => {
                $modalInstance.close();
            });
          }
        }
    }]);

mod.controller('UpdatedTermsModalCtrl', ['$scope', '$log', '$modalInstance',
    ($scope, $log, $modalInstance) => {
        $scope.agree = () => {
            const user = Authenticator.user();
            user.is_gdpr_authorized = true;

            user.$update().then(
                (updatedUser) => {
                    angular.extend(user, updatedUser);
                    $modalInstance.close();
                },
                (error) => {
                    $log.warn('Error updating user GDPR authorization');
                    $log.warn(error);
                    Messager.error('Error accepting updated terms.');
                },
            );
        };

        $modalInstance.result.finally(() => $state.go($stateParams.toState));
    },
]);

mod.controller('UpdatedTOUSnoozeableCtrl', ['$scope', '$modalInstance',
    ($scope, $modalInstance) => {
        $scope.getUser = () => Authenticator.user();

        $scope.accept = () => {
            const user = Authenticator.user();
            const notify = Messager.load('Accepting TOU...');

            user.$acceptTou()
                .then(updatedUser => {
                    angular.extend(user, updatedUser);
                    notify.success('Accept TOU Successful');
                    $scope.btnLoading = false;
                    $modalInstance.close();
                })
                .catch(() => {
                    $scope.btnLoading = false;
                    notify.error('Accept TOU failed');
                });
        };

        $scope.snooze = () => {
            const user = Authenticator.user();
            const notify = Messager.load('Snoozing TOU...');

            user.$snoozeTou()
                .then(updatedUser => {
                    angular.extend(user, updatedUser);
                    notify.success('Snooze TOU Successful');
                    $scope.btnLoading = false;
                    $modalInstance.close();
                })
                .catch(() => {
                    $scope.btnLoading = false;
                    notify.error('Snooze TOU failed');
                });
        };

        $modalInstance.result.finally(() => $state.go($stateParams.toState));
    },
]);

mod.controller('UpdatedTOUFinalCtrl', ['$scope', '$modalInstance',
    ($scope, $modalInstance) => {
        $scope.getUser = () => Authenticator.user();

        $scope.declineLabel = $scope.getUser().subscription.used_seats > 1 ? 'Decline' : 'Decline and cancel the subscription';

        $scope.accept = () => {
            const user = Authenticator.user();
            const notify = Messager.load('Accepting TOU...');

            user.$acceptTou()
                .then(updatedUser => {
                    angular.extend(user, updatedUser);
                    notify.success('Accept TOU Successful');
                    $scope.btnLoading = false;
                    $modalInstance.close();
                })
                .catch(() => {
                    $scope.btnLoading = false;
                    notify.error('Accept TOU failed');
                });
        };

        $scope.decline = () => {
            const user = Authenticator.user();
            const notify = Messager.load('Declining TOU...');

            user.$declineTou()
                .then(updatedUser => {
                    angular.extend(user, updatedUser);
                    notify.success('Decline TOU Successful');
                    $scope.btnLoading = false;
                    $modalInstance.close();
                })
                .catch(() => {
                    $scope.btnLoading = false;
                    notify.error('Decline TOU failed');
                });
        };

        $modalInstance.result.finally(() => {
            $state.go($stateParams.toState);
            window.location.reload();
        });
    },
]);

mod.controller('UserSearchCtrl',
    function userSearchCtrl($scope) { // eslint-disable-line prefer-arrow-callback
        $scope.$watch('searchUserId', (userId) => {
            if (userId) {
                const user = User.cached(userId);
                $state.go('account.detail.user', user);
            }
        });
    });
