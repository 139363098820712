/* eslint-env browser */
/* eslint-disable global-require */

import Raven from 'raven-js';
import moment from 'moment';

import 'helioscope/app/init';
import 'angular-cookies';
import 'angular-ui-router';
import 'angular-messages';
import 'angular-sanitize';
import 'angular-animate';

import { user, User } from 'helioscope/app/users';

import 'helioscope/app/compat';
import 'helioscope/app/config';
import 'helioscope/app/directives';
import 'helioscope/app/filters';

import 'helioscope/app/admin';
import 'helioscope/app/designer';
import 'helioscope/app/libraries';
import 'helioscope/app/projects';
import 'helioscope/app/documentation';
import 'helioscope/app/utilities';
import 'helioscope/app/utilities/ng-react';
import { getBetaBillingURL, getBetaSignupURL } from 'helioscope/app/utilities/url';

import 'helioscope/app/styles';

import { requestHeaders } from 'helioscope/app/utilities/relational/resource';

import { setupSystemAlerts } from 'helioscope/app/system_alerts';
import { SignupCtrl } from 'helioscope/app/controllers';

// only here because we wanted to test Babel on the upload extensions
import 'helioscope/libs/angular-file-upload/angular-file-upload';

import {
    populateProxies,
    Authenticator,
    $state,
} from 'helioscope/app/utilities/ng';

import * as pusher from 'helioscope/app/utilities/pusher';
import * as bugReports from 'helioscope/app/utilities/bug_reports';
import * as statsd from 'helioscope/app/utilities/statsd';
import { getChat } from 'helioscope/app/utilities/chat-wrapper';

import { registerInterceptors } from 'helioscope/app/utilities/relational/angular_interceptors';

import { linkAngularScope } from 'reports/angular-bridge.ts';

import { loadTemplates } from './templates';

window.metrics.jsRunStartTime = performance.now();
let lastFailedState = null;
function bootApp() {
    angular.module('ui', ['ui.bootstrap', 'ui.select2', 'ui.map', 'ui.sortable', 'ui.validate']);

    try {
        angular.module('helioscope.templates');
    } catch (e) {
        angular.module('helioscope.templates', []);
    }

    loadTemplates(require.context('./', true, /^\.\/.*\.html$/));
    angular.module('helioscope',
        [
            'helioscope.templates',
            'helioscope.designer',
            'helioscope.projects',
            'helioscope.users',
            'helioscope.users.teams',
            'helioscope.documentation',
            'helioscope.libraries',
            'helioscope.directives',
            'helioscope.controllers',
            'helioscope.filters',
            'helioscope.compat',
            'helioscope.config',
            'helioscope.admin',
            'helioscope.services',
            'ui',
            'ui.router',
            'ngAnimate',
        ])
        .config((
            $stateProvider, $urlRouterProvider, $locationProvider, $compileProvider, helioscopeConfig,
            $provide, $logProvider,
        ) => {
            /**
             * Error handling configuration
             */
            bugReports.install();
            $compileProvider.debugInfoEnabled(helioscopeConfig.debug);
            $logProvider.debugEnabled(helioscopeConfig.log_debug || false);
            $provide.decorator('$exceptionHandler', ($delegate) => (
                /**
                 * override angularjs error suppression so sourcemaps work.
                 * by default, angular catches all template-expression errors and logs them;
                 * this makes sense in prod, but breaks sourcemaps in development
                 */
                function handleException(exception, cause) {
                    if (helioscopeConfig.debug) {
                        throw exception;
                    } else {
                        $delegate(exception, cause);
                    }
                }
            ));

            $locationProvider.html5Mode(true);

            $urlRouterProvider.when('', '/').otherwise(() => {
                $state.go('error', { statusCode: 404 }, { location: false });
            });

            $stateProvider
                // .state('home', {
                //     url: '/',
                //     templateUrl: require('helioscope/app/partials/home.html'),
                //     controller: 'HomeCtrl',
                //     data: {
                //         helpOverlayUrl: 'helioscope/app/partials/home.help.html',
                //     },
                // })
                .state('home', {
                    url: '/?login',
                    templateUrl: require('helioscope/app/partials/hooome.html'),
                    controller: 'HomeCtrl',
                    data: {
                        helpOverlayUrl: require('helioscope/app/partials/home.help.html'),
                    },
                })
                .state('pricing.signup', {
                    url: '/signup',
                    onEnter: () => $state.go('signup'),
                })
                .state('signup', {
                    url: '/signup',
                    onEnter: () => {window.location.href = getBetaSignupURL()},
                })
                .state('empty', {
                    url: '/empty',
                    templateUrl: require('helioscope/app/partials/empty.html'),
                })

                .state('error', {
                    url: '/error/:statusCode',
                    templateUrl: require('helioscope/app/partials/error.html'),
                    controller: ['$scope', '$stateParams', ($scope, { statusCode = '500' }) => {
                        const errors = {
                            401: {
                                header: '401 Unauthorized',
                                title: 'Sorry, you are not authorized.',
                                details: 'Please login to continue.',
                            },
                            403: {
                                header: '403 Forbidden',
                                title: 'Sorry, that resource is restricted.',
                                details: '',
                            },
                            404: {
                                header: '404 Not Found',
                                title: 'Sorry, requested page not found!',
                                details: '',
                            },
                            500: {
                                header: '500 Server Error',
                                title: 'Sorry, an error has occured.',
                                details: '',
                            },
                        };

                        $scope.error = errors[statusCode] || errors['500'];
                        $scope.canOverride = user.is_admin && lastFailedState && statusCode !== '500';
                        $scope.override = async () => {
                            const { toState, toParams } = lastFailedState;
                            Authenticator.disablePermissions();
                            $state.go(toState, toParams);
                        };
                    }],
                });
        })
        .run(populateProxies)
        .run(($injector) => {
            // provide a bridge for controlling the angular application from
            // within the reports application
            linkAngularScope($injector);
        })
        .run(($rootScope, $stateParams, $log, $http, helioscopeConfig, $window, $q, editableOptions, $location) => {
            $log.log(`Initializing HelioScope version ${helioscopeConfig.version}.`);
            registerInterceptors();

            pusher.initApp(helioscopeConfig, () => {
                if (!$rootScope.$$phase) {
                    $rootScope.$apply();
                }
            });

            requestHeaders['client-version'] = helioscopeConfig.version;
            $http.defaults.headers.common['client-version'] = helioscopeConfig.version;

            requestHeaders['HS_CLIENT'] = 'classic';
            $http.defaults.headers.common['HS_CLIENT'] = 'classic';

            bugReports.installAngularHooks();

            if (helioscopeConfig.user) {
                Authenticator.loginUser(new User(helioscopeConfig.user));
                setupSystemAlerts();
                const urlArgs = $location.search();
                if (urlArgs.as_user) {
                    Authenticator.impersonateUser(urlArgs.as_user);
                }
            } else {
                Authenticator.loginAnonymousUser();

                // ideally should check for pdf_mode=True, but have sceen case with render auth
                // failures due to the browser not getting the appropriate headers from
                // chrome-headless, which means the pdf_mode header would not be present as well
                console.log('design_render_error');
            }

            // enable iframe to hide navbar in reports app
            if ($location.$$search.hideNavbar === true) {
                $rootScope.hideNavbar = true;
            }

            $window.google = $window.google || {};

            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
            $rootScope.hasFeature = feature => user.hasFeature(feature);
            $rootScope.helioscopeConfig = helioscopeConfig;
            $rootScope.hideChat = () => getChat().hideChat();

            editableOptions.theme = 'bs3';
            editableOptions.icon_set = 'external';
            if (helioscopeConfig.statsd) {
                statsd.configure('https://lambda.helioscope.com/statsd_pusher/post_stats');
            }

            $rootScope.$on('$stateChangeStart', (event, toState, _toParams, fromState, _fromParams) => {
                const user = Authenticator.user();

                if (helioscopeConfig.pdf_mode || !user || !user.user_id) {
                    return;
                }

                if (!user.is_gdpr_authorized) {
                    if (toState.name !== 'home.updatedTerms' && fromState.name !== 'home.updatedTerms') {
                        event.preventDefault();
                        $state.go('home.updatedTerms', { toState: toState.name }, { location: false });
                    }
                } else if (user.shouldShowFinalClickwrap) {
                    if (toState.name !== 'home.updatedTOUFinal' && fromState.name !== 'home.updatedTOUFinal') {
                        event.preventDefault();
                        $state.go('home.updatedTOUFinal', { toState: toState.name }, { location: false });
                    }
                } else if (user.shouldShowSnoozeableClickwrapEverywhere) {
                    if (toState.name !== 'home.updatedTOUSnoozeable' &&
                        fromState.name !== 'home.updatedTOUSnoozeable') {
                        event.preventDefault();
                        $state.go('home.updatedTOUSnoozeable', { toState: toState.name }, { location: false });
                    }
                }
            });

            $rootScope.$on('$stateChangeError', (event, toState, toParams, previous, prevParams, rejection) => {
                $log.error(rejection);
                if (!toState || toState.name === 'error') {
                    // if the start failed to transition to the error state, something is really
                    // wrong so give up (usually the server is turned off while developing)
                    return;
                }

                // user either type or status to get status code
                // status is preferred, since that should come straight from the
                // http response object, rejection.type was used by our 'providers',
                // but should be refactored out as you see it
                const statusCode = rejection.status || rejection.type || 500;

                if (statusCode === 'activationError') {
                    $log.log('User has not authorized their account yet');
                    $state.go('home.notActivated');
                    return;
                }

                // trial has expired
                if (statusCode === 402) {
                    const user = Authenticator.user();
                    if (user.latest_subscription && user.latest_subscription.cleaned_status === "unpaid" && user.latest_subscription.is_pay_by_invoice) {
                      window.location.href = getBetaBillingURL(user, '', {});
                    } else {
                      $state.go('home.expired');
                    }
                    // swallow 409s. Used for lockout
                } else if (statusCode === 409) {
                    $log.log('Got lockout error while resolving state');
                } else {
                    // hack: communicate the state to the handler via a global.
                    lastFailedState = { toState, toParams };

                    $state.go('error', { statusCode }, { location: false });
                }
            });

            $rootScope.$on('$stateChangeSuccess', () => getChat().update());

            statsd.submitBootMetrics(window.metrics);
        })
        .run((Messager) => {
            const maintenanceWindowStart = moment.utc('2024-07-12 00:00:00');

            const mainteneaceWindowEnd = maintenanceWindowStart.clone().add(moment.duration(5, 'minutes'));

            if (moment().isBefore(mainteneaceWindowEnd)) {
                const warningStart = maintenanceWindowStart.clone().subtract(moment.duration(30, 'minutes'));
                const scaryWarningStart = maintenanceWindowStart.clone().subtract(moment.duration(2.5, 'minutes'));
                let message = null;
                const getText = (time = moment()) => {
                    if (time.isAfter(scaryWarningStart)) {
                        return (
                            `Warning, HelioScope will be shut down for maintenance imminently.
                            Please save your work immediately.`
                        );
                    }

                    return (
                        `Warning, HelioScope will be shut down for maintenance ${maintenanceWindowStart.fromNow()}.
                        Please save your work and exit as soon as possible.`
                    );
                };

                setInterval(() => {
                    const now = moment();

                    if (now.isAfter(warningStart)) {
                        if (message == null) {
                            message = Messager.load(
                                getText(now), { delay: 0, type: 'error', icon: 'fa fa-minus-circle' });
                        } else {
                            message.progress({ text: getText(now) });
                        }
                    }
                }, 15 * 1000); // poll every 15 seconds
            }
        });
}

Raven.context(() => bootApp());
