/* global StripeCheckout:true */

import { helioscopeConfig } from 'helioscope/app/config';
import { $q } from 'helioscope/app/utilities/ng';
import { asyncLoadScript } from 'helioscope/app/utilities/helpers';

function checkoutInternal(options) {
    const { description, amount, ...rest } = options;

    const deferred = $q.defer();
    StripeCheckout.configure({ // eslint-disable-line new-cap
        key: helioscopeConfig.stripe_public_key,
        image: require('helioscope/app/users/static/fl_logo_payments.png'),
        billingAddress: true,
        allowRememberMe: false,
        ...rest,
        closed: () => {
            deferred.resolve();
        },
        token: async token => {
            deferred.resolve(token);
        },

    }).open({
        name: 'HelioScope by Aurora Solar',
        description,
        amount,
    });

    return deferred.promise;
}

export async function checkout(options) {
    await asyncLoadScript('https://checkout.stripe.com/checkout.js'); // eslint-disable-line new-cap
    const res = await checkoutInternal(options);
    return res;
}
