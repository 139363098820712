import * as THREE from 'three';
import * as statsd from 'helioscope/app/utilities/statsd';
import Logger from 'js-logger';
import Q from 'q';
import { Vector, GeoPoint } from 'helioscope/app/utilities/geometry';
import { AABB2 } from 'helioscope/app/utilities/geometry/geo2';
import { latLonToGoogle, DefaultGlobalMercator } from 'helioscope/app/utilities/maps/spherical_mercator';
import { PrimitiveMeshFill } from './Primitives';
import { makeQuadTexturedGeometryFromPoints } from './GLHelpers';

const TILE_LOADS = new statsd.Counter('designer.tile_loads');
const CACHED_TILE_LOADS = new statsd.Counter('designer.tile_loads.cached');
const logger = Logger.get('TileHelper');

function mapTileKey(zoom, x, y) {
    return `maptile_${zoom}_${x}_${y}`;
}

class TilePosition {
    constructor(x, y, zoom) {
        this.x = x;
        this.y = y;
        this.zoom = zoom;
    }
}

export class TileRegion {
    constructor(minx, maxx, miny, maxy, zoom) {
        this.minx = minx;
        this.maxx = maxx;
        this.miny = miny;
        this.maxy = maxy;
        this.zoom = zoom;
    }

    tilePositions() {
        const { minx, maxx, miny, maxy, zoom } = this;
        const rtn = [];
        for (let x = minx; x <= maxx; ++x) {
            for (let y = miny; y <= maxy; ++y) {
                rtn.push(new TilePosition(x, y, zoom));
            }
        }
        return rtn;
    }

    containsTile(tilePos, onlySameZoomLevel = false) {
        const { x, y, zoom } = tilePos;
        if (onlySameZoomLevel === true && this.zoom !== zoom) return false;

        const multiplier = Math.pow(2, zoom - this.zoom);
        const minx = this.minx * multiplier;
        const maxx = (this.maxx + 1) * multiplier;
        const miny = this.miny * multiplier;
        const maxy = (this.maxy + 1) * multiplier;
        return (x >= minx && x < maxx && y >= miny && y < maxy);
    }

    getCornerTiles() {
        const topLeftTile = new TilePosition(this.minx, this.miny, this.zoom);
        const btmRightTile = new TilePosition(this.maxx, this.maxy, this.zoom);
        return { topLeftTile, btmRightTile };
    }
}

class SingleTile {
    // limit number of tiles being fetched at any one time to keep interaction smooth
    static loadTileLimit = 8;

    constructor(dRenderer, tileHelper, service, tilePos) {
        this.dRenderer = dRenderer;
        this.tileHelper = tileHelper;
        this.service = service;
        this.tilePos = tilePos;
        this.prim = null;
        this.loading = false;
        this.failed = false;
        this.visible = false;

        this.loadedDeferred = Q.defer();
        this.renderedDeferred = Q.defer();
    }

    get rendered() {
        return this.renderedDeferred.promise;
    }
    /**
     * ensure tile is visible
     * return whether or not tile needs rerender (not already rendered or failed to load)
     */
    ensureVisible() {
        this.visible = true;
        if (this.failed || this.primitiveFinished()) {
            return true;
        }

        if (!this.texture && !this.loading) {
            if (this.tileHelper.loadingTiles >= SingleTile.loadTileLimit) {
                return false;
            }

            this.tileHelper.loadingTiles++;
            this.loadTileTexture();
            return false;
        } else if (this.loading) {
            return false;
        } else if (!this.prim) {
            this.renderTilePrimitive();
            return false;
        } else if (this.prim) {
            this.prim.setRenderOptions(this.primOptions);
            return false;
        }

        return true;
    }

    primitiveFinished() {
        return this.prim && this.primOptions.opacity >= 1.0;
    }

    loadTileTexture() {
        const { x, y, zoom } = this.tilePos;
        this.loading = true;
        const loadStart = performance.now();
        this.dRenderer.loadTexture(null, this.service.url(x, y, zoom))
            .then((texture) => {
                this.texture = texture;
                this.loadedDeferred.resolve();
            })
            .catch(() => {
                this.failed = true;
                this.loadedDeferred.reject();
            })
            .finally(() => {
                this.loading = false;
                this.tileHelper.loadingTiles--;

                // from local testing, cached loads appear to take about 30ms
                // while uncached loads take ~120ms from our office
                // tiles loaded at designer start take ~80ms because they have to wait in the request queue
                // choosing 50ms as a cutoff seems like a reasonable, conservative threshold
                if (performance.now() - loadStart > 50) {
                    TILE_LOADS.increment();
                } else {
                    CACHED_TILE_LOADS.increment();
                }
            });
    }

    renderTilePrimitive() {
        const { x, y, zoom } = this.tilePos;
        const { min, max } = DefaultGlobalMercator.tileLatLonBounds(x, y, zoom);

        const quad0 = this.dRenderer.toXY(min);
        const quad1 = this.dRenderer.toXY(new GeoPoint(max.latitude, min.longitude));
        const quad2 = this.dRenderer.toXY(max);
        const quad3 = this.dRenderer.toXY(new GeoPoint(min.latitude, max.longitude));

        const quadPoints = [
            new THREE.Vector3(quad0.x, quad0.y, 0),
            new THREE.Vector3(quad1.x, quad1.y, 0),
            new THREE.Vector3(quad2.x, quad2.y, 0),
            new THREE.Vector3(quad3.x, quad3.y, 0),
        ];
        const quadUVs = [
            new THREE.Vector2(0, 1),
            new THREE.Vector2(0, 0),
            new THREE.Vector2(1, 0),
            new THREE.Vector2(1, 1),
        ];

        this.loadedDeferred.promise
            .then(() => {
                this.primOptions = {
                    geometry: makeQuadTexturedGeometryFromPoints(quadPoints, quadUVs),
                    material: this.dRenderer.inlineShaderMaterial('vertexShaderTexture', 'fragmentShaderTexture'),
                    customCamera: this.tileHelper.bufferCamera,
                    scene: this.tileHelper.bufferLayer,
                    texture: this.texture,
                    fillColor: '#ffffff',
                    opacity: 1.0,
                    depthOffset: zoom * this.dRenderer.tinyZOffset,
                    selectionData: null,
                };

                this.prim = this.dRenderer.renderPrimitive(PrimitiveMeshFill, this.primOptions);
                this.renderedDeferred.resolve();

                this.tileHelper.markTileChanged(this);
            })
            .catch(() => {
                logger.warn('Can not load texture'); // eslint-disable-line no-console
                this.renderedDeferred.reject();
            });
    }

    clearTilePrimitive() {
        this.visible = false;
        if (this.prim) {
            this.prim.clearInstances();
            delete this.primOptions;
            delete this.prim;

            this.tileHelper.markTileChanged(this);
        }

        if (this.texture) {
            this.texture.dispose();
            delete this.texture;
        }
    }
}

export class TileHelper {
    constructor(dRenderer, service) {
        const BUFFER_SIZE = 2048;

        this.dRenderer = dRenderer;
        this.service = service;
        this.baseViewportScale = 0.1;
        this.baseZoom = 20;
        this.maxZoom = 22;
        this.tileCache = {};
        this.loadingTiles = 0;

        this.service = service;

        const latlngCenter = this.dRenderer.toLatLng({ x: 0, y: 0 });
        this.service.getMaxZoom(latlngCenter.latitude, latlngCenter.longitude).then(
            (res) => {
                this.maxZoom = res;
                this.dRenderer.dirtyCamera();
                this.dRenderer.dirtyFrame();
            });
        this.tileChangeListeners = [];

        this.bufferCamera = new THREE.OrthographicCamera();
        this.bufferCamera.up.set(0.0, 1.0, 0.0);
        this.bufferLayer = this.dRenderer.addOffscreenRenderLayer(BUFFER_SIZE, BUFFER_SIZE, this.bufferCamera);
    }

    visibleTilesRendered() {
        const tileRegion = this.internalRenderTiles();
        return Q.all(Object.values(this.tileCache).filter(tile =>
            tileRegion.containsTile(tile.tilePos, true)).map(x => x.rendered));
    }

    visibleExtent() {
        const constrainedCamera = this.dRenderer.primaryCamera.clone();
        const constrainedTheta = Math.max(this.dRenderer.cameraTheta, -Math.PI * 0.4);
        const { up, backward } = this.dRenderer.computeCameraOffsets(constrainedTheta, this.dRenderer.cameraPhi);

        // constrain tiles to avoid infinity tiles
        constrainedCamera.up.set(up.x, up.y, up.z);
        constrainedCamera.position.addVectors(this.dRenderer.cameraCenter, backward);
        constrainedCamera.lookAt(this.dRenderer.cameraCenter);
        constrainedCamera.updateProjectionMatrix();
        constrainedCamera.updateMatrixWorld(true);

        const groundPt1 = this.cameraGroundPoint(constrainedCamera,
            new Vector(0, 0));
        const groundPt2 = this.cameraGroundPoint(constrainedCamera,
            new Vector(0, this.dRenderer.container.clientHeight));
        const groundPt3 = this.cameraGroundPoint(constrainedCamera,
            new Vector(this.dRenderer.container.clientWidth, 0));
        const groundPt4 = this.cameraGroundPoint(constrainedCamera,
            new Vector(this.dRenderer.container.clientWidth, this.dRenderer.container.clientHeight));

        const minx = Math.min(groundPt1.x, groundPt2.x, groundPt3.x, groundPt4.x);
        const maxx = Math.max(groundPt1.x, groundPt2.x, groundPt3.x, groundPt4.x);
        const miny = Math.min(groundPt1.y, groundPt2.y, groundPt3.y, groundPt4.y);
        const maxy = Math.max(groundPt1.y, groundPt2.y, groundPt3.y, groundPt4.y);

        return { minx, maxx, miny, maxy };
    }

    visibleTileRegion(zoom) {
        const { minx, maxx, miny, maxy } = this.coverageRect || this.visibleExtent();
        return this.visibleTilesFromRange(zoom, minx, maxx, miny, maxy);
    }

    visibleTilesFromRange(zoom, minx, maxx, miny, maxy) {
        const minlatlng = this.dRenderer.toLatLng({ x: minx, y: miny });
        const minmaptile = latLonToGoogle(minlatlng.latitude, minlatlng.longitude, zoom);

        const maxlatlng = this.dRenderer.toLatLng({ x: maxx, y: maxy });
        const maxmaptile = latLonToGoogle(maxlatlng.latitude, maxlatlng.longitude, zoom);

        // TODO: MT: deal with wrap-around
        // longitude means we can be backwards
        const mintiley = Math.min(minmaptile.y, maxmaptile.y);
        const maxtiley = Math.max(minmaptile.y, maxmaptile.y);

        return new TileRegion(minmaptile.x, maxmaptile.x, mintiley, maxtiley, zoom);
    }

    removeChildTilesInRegion(tileRegion) {
        for (const tile of Object.values(this.tileCache)) {
            if (tile.tilePos.zoom <= tileRegion.zoom) continue;
            if (tileRegion.containsTile(tile.tilePos)) {
                tile.clearTilePrimitive();
            }
        }
    }

    clearAllTilesByZoom(bzoom) {
        for (const tile of Object.values(this.tileCache)) {
            if (tile.zoom <= bzoom) {
                tile.clearTilePrimitive();
            }
        }
    }

    clearAllData() {
        for (const key of Object.keys(this.tileCache)) {
            this.deleteTile(key);
        }
        this.imageryPrim.clearInstances();
    }

    verifyTilesInRegion(tileRegion) {
        for (const pos of tileRegion.tilePositions()) {
            const { x, y, zoom } = pos;
            const key = mapTileKey(zoom, x, y);
            if (!this.tileCache[key] || !this.tileCache[key].prim) return false;
        }

        return true;
    }

    renderTilesInRegion(tileRegion) {
        let dirty = false;
        const nowTime = performance.now();

        for (const pos of tileRegion.tilePositions()) {
            const { x, y, zoom } = pos;
            const tileKey = mapTileKey(zoom, x, y);
            if (!this.tileCache[tileKey]) {
                this.insertTile(tileKey, pos);
            }

            const tile = this.tileCache[tileKey];
            if (!tile.ensureVisible()) {
                dirty = true;
            }

            // remove tiles covering the same area as this tile
            if (tile.primitiveFinished()) {
                this.removeChildTilesInRegion(new TileRegion(x, x, y, y, zoom));
            }
        }

        for (const tile of Object.values(this.tileCache)) {
            if (tileRegion.containsTile(tile.tilePos)) {
                // set timestamp on visible tiles, old tiles are deleted to keep cache operations fast
                tile.lastUsed = nowTime;
            } else {
                // set flag to allow tiles to be cleared from cache
                tile.visible = false;
            }
        }

        if (dirty) {
            this.dRenderer.dirtyCamera();
            this.dRenderer.dirtyFrame();
        } else if (this.throttledRenderTiles) {
            this.throttledRenderTiles.cancel();
            this.throttledRenderTiles = null;
        }
    }

    removeExtraParentTiles(zoom) {
        let removeZoom;
        for (removeZoom = zoom - 1; removeZoom >= 0; --removeZoom) {
            const tileRegion = this.visibleTileRegion(removeZoom);
            if (!this.verifyTilesInRegion(tileRegion)) {
                break;
            }
        }

        // remove tiles above the level for which we have full coverage
        // remove upper tiles that are covered by lower tiles to save on fill rate
        this.clearAllTilesByZoom(removeZoom);
    }

    deleteOldTilesFromCache() {
        const maxCachedTiles = 300;
        const tileKeys = Object.keys(this.tileCache);
        if (tileKeys.length > maxCachedTiles) {
            const timeDat = _.map(tileKeys, k => ({
                key: k,
                lastUsed: this.tileCache[k].lastUsed,
            }));
            const sortedDat = _.sortBy(timeDat, i => i.lastUsed);
            let removeCnt = tileKeys.length - maxCachedTiles;

            for (const dat of sortedDat) {
                const { key } = dat;
                if (this.tileCache[key].visible) continue;
                this.deleteTile(key);
                if (!--removeCnt) break;
            }
        }
    }

    internalRenderTiles() {
        const deltaZoom =
            Math.log(this.baseViewportScale / this.dRenderer.viewportScale) /
            Math.log(2.0);
        let zoom = this.baseZoom + Math.round(deltaZoom);
        if (zoom > this.maxZoom) zoom = this.maxZoom;

        const tileRegion = this.visibleTileRegion(zoom);
        this.renderTilesInRegion(tileRegion);
        this.deleteOldTilesFromCache();
        this.removeExtraParentTiles(zoom);
        this.broadcastTileChange();

        TILE_LOADS.submit();
        CACHED_TILE_LOADS.submit();

        return tileRegion;
    }

    broadcastTileChange() {
        for (const listener of this.tileChangeListeners) {
            for (const tile of _.uniq(this.changedTiles)) {
                const tileAABB = tileCoordToXYBBox(this.dRenderer, tile.tilePos);
                if (listener.bbox.overlaps(tileAABB)) {
                    listener.fn();
                    break;
                }
            }
        }
        this.changedTiles = [];
    }

    renderMapTiles() {
        const { minx, maxx, miny, maxy } = this.visibleExtent();
        this.coverageRect = { minx, maxx, miny, maxy };

        const updateBufferCamera = () => {
            this.bufferCamera.position.addVectors(this.dRenderer.cameraCenter, new THREE.Vector3(0.0, 0.0, 1.0));
            this.bufferCamera.lookAt(this.dRenderer.cameraCenter);

            this.bufferCamera.left = -(maxx - minx) * 0.5;
            this.bufferCamera.right = (maxx - minx) * 0.5;
            this.bufferCamera.top = (maxy - miny) * 0.5;
            this.bufferCamera.bottom = -(maxy - miny) * 0.5;

            this.bufferCamera.updateProjectionMatrix();
            this.bufferCamera.updateMatrixWorld(true);

            this.bufferCamera.matrixWorldInverse.copy(this.bufferCamera.matrixWorld).invert();
        };

        const remakeImageryPrimitive = () => {
            if (this.imageryPrim) this.imageryPrim.clearInstances();

            const quadPoints = [
                new THREE.Vector3(minx, miny, 0),
                new THREE.Vector3(maxx, miny, 0),
                new THREE.Vector3(maxx, maxy, 0),
                new THREE.Vector3(minx, maxy, 0),
            ];
            const quadUVs = [
                new THREE.Vector2(0, 0),
                new THREE.Vector2(1, 0),
                new THREE.Vector2(1, 1),
                new THREE.Vector2(0, 1),
            ];

            const { texture } = this.bufferLayer.userData.renderTarget;
            texture.wrapT = THREE.ClampToEdgeWrapping;
            texture.wrapS = THREE.ClampToEdgeWrapping;

            const primOptions = {
                texture,
                geometry: makeQuadTexturedGeometryFromPoints(quadPoints, quadUVs),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderTexture', 'fragmentShaderTexture'),
                scene: this.dRenderer.imageryLayer,
                fillColor: '#ffffff',
                opacity: 1.0,
                depthOffset: 0.0,
                selectionData: null,
            };

            this.imageryPrim = this.dRenderer.renderPrimitive(PrimitiveMeshFill, primOptions);
        };

        updateBufferCamera();
        remakeImageryPrimitive();

        if (!this.throttledRenderTiles) {
            this.throttledRenderTiles = _.throttle(() => {
                this.internalRenderTiles();
                return true;
            }, 100);
        }

        const ranFn = this.throttledRenderTiles();
        if (!ranFn) {
            this.dRenderer.dirtyCamera();
            this.dRenderer.dirtyFrame();
        }
    }

    insertTile(tileKey, tilePos) {
        this.tileCache[tileKey] = new SingleTile(this.dRenderer, this, this.service, tilePos);
    }

    deleteTile(tileKey) {
        this.tileCache[tileKey].clearTilePrimitive();
        delete this.tileCache[tileKey];
    }

    /**
     * get ground intersection from client point given arbitrary ortho camera
     */
    cameraGroundPoint(camera, pt, groundZ = 0.0) {
        const ray = this.dRenderer.transformClientToCameraRay(camera, pt);
        const tparam = (ray.origin.z - groundZ) / ray.dir.z;
        const gp = (new THREE.Vector3())
            .copy(ray.dir)
            .multiplyScalar(-tparam)
            .add(ray.origin);
        return gp;
    }

    addTileChangeListener(bbox, fn) {
        this.tileChangeListeners.push({ bbox, fn });
        return () => this.removeTileChangeListener(fn);
    }

    removeTileChangeListener(fn) {
        this.tileChangeListeners = this.tileChangeListeners.filter(({ cb }) => cb !== fn);
    }

    markTileChanged(tile) {
        this.changedTiles.push(tile);
    }

    getPolygonBBox(polygons) {
        const bbox = new AABB2();
        bbox.fromPoints([].concat(...polygons.map(polygon => [...polygon.path])));
        return bbox;
    }

    // Return a list of tiles in the tilecache that are covered in this bbox.
    visibleTilesInBBox(bbox) {
        const deltaZoom =
            Math.log(this.baseViewportScale / this.dRenderer.viewportScale) /
            Math.log(2.0);
        let zoom = this.baseZoom + Math.round(deltaZoom);
        if (zoom > this.maxZoom) zoom = this.maxZoom;

        return Object.values(this.tileCache)
            .filter(tile => tileCoordToXYBBox(this.dRenderer, tile.tilePos).overlaps(bbox)
                && tile.tilePos.zoom === zoom);
    }
}

export function tileCoordToXYBBox(renderer, tile) {
    const { x, y, zoom } = tile;
    const { min, max } = DefaultGlobalMercator.tileLatLonBounds(x, y, zoom);

    const quadPoints = [
        renderer.toXY(min),
        renderer.toXY(new GeoPoint(max.latitude, min.longitude)),
        renderer.toXY(max),
        renderer.toXY(new GeoPoint(min.latitude, max.longitude)),
    ];

    const bbox = (new AABB2()).fromPoints(quadPoints);
    return bbox;
}
