/* global StripeCheckout:true, angular: true */
import Logger from 'js-logger';

import { helioscopeConfig } from 'helioscope/app/config';
import { $q, $http, $modal, Messager } from 'helioscope/app/utilities/ng';

import { Subscription } from './Subscription';

const logger = Logger.get('subscriptions');

export function PaymentService(invoice, subscription, options) {
    options = options || {};
    const deferred = $q.defer();

    StripeCheckout.configure({
        key: helioscopeConfig.stripe_public_key,
        image: require('helioscope/app/users/static/fl_logo_payments.png'),
        billingAddress: true,
        allowRememberMe: false,
        email: options.email,
        token(token, args) {
            deferred.notify('processing');

            $http.post('/api/billing/', {
                token,
                address: args,
                invoice: `${subscription.external_id}||${invoice.external_id}`,
                terms_of_use: !!(options.acceptTou),
            }).success((data) => {
                if (data.status === 'success') {
                    deferred.resolve(data);
                } else {
                    deferred.reject(data);
                }
            }).error(data => deferred.reject(data));
        },
    }).open({
        name: 'HelioScope by Aurora Solar',
        description: subscription.plan.description,
        amount: subscription.cost().total * 100,
    });

    return deferred.promise;
}


export function ShareSubscriptionDlg(subscription) {
    const opts = {
        templateUrl: require('helioscope/app/users/subscriptions/partials/payment.share.modal.html'),
        controller: ShareSubscriptionCtrl,
        backdropFade: true,
        dialogFade: true,
        resolve: { subscription() { return subscription; } },
    };

    return $modal.open(opts);
}

function ShareSubscriptionCtrl($scope, $modalInstance, subscription) {
    'ngInject';

    $scope.subscription = angular.copy(subscription);
    $modalInstance.opened.then(() => { $scope.isOpen = true; });

    $scope.share = () => {
        $scope.isLoading = true;
        const notification = Messager.load('Updating invoice sharing...');

        $scope.subscription.$share((sub) => {
            $scope.isLoading = false;
            subscription.admin_email = sub.admin_email;
            subscription['public'] = sub['public'];

            if (sub['public']) {
                notification.success(`${sub.admin_email} is now the administrative contact for this subscription.` +
                                     'Please keep the sharing links private.');
            } else {
                notification.success('This subscription is now viewable only to the owner, ' +
                                     `${subscription.creator.first_name} ${subscription.creator.last_name}`);
            }

            $modalInstance.close();
        }, () => {
            $scope.isLoading = false;
            notification.error('Could not save sharing settings');
        });
    };

    $scope.cancel = () => {
        $modalInstance.close();
    };
}


export async function updateCustomFields({ subscription, invoice } = {}) {
    const modalInstance = $modal.open({
        templateUrl: require('helioscope/app/users/subscriptions/partials/payment.custom_fields.modal.html'),
        size: 'md',
        controller: CustomFieldCtrl,
        controllerAs: 'ctrl',
        backdropFade: true,
        dialogFade: true,
        resolve: {
            subscription() { return subscription; },
            invoice() { return invoice; },
        },
    });

    return modalInstance.result
        .then(x => x)
        .catch(reason => reason); // modal dismissals will reject, so this causes the rejection to be swallowed
}


export async function prePaymentTOU() {
    const modalInstance = $modal.open({
        templateUrl: require('helioscope/app/users/subscriptions/partials/payment.tou.modal.html'),
        size: 'md',
        controller: PrePaymentTouCtrl,
        controllerAs: 'ctrl',
        backdropFade: true,
        dialogFade: true,
    });

    return modalInstance.result.then(x => x);
}


class CustomFieldCtrl {
    constructor($scope, $modalInstance, subscription, invoice) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.subscription = subscription;
        this.invoice = invoice;

        this.newField = {
            label: '', value: '', recurring: true,
        };

        if (invoice.customer_data != null && invoice.customer_data.custom_fields != null) {
            this.customFields = angular.copy(invoice.customer_data.custom_fields);
        } else {
            this.customFields = [];
        }

        $modalInstance.opened.then(() => { $scope.isOpen = true; });
    }


    addField(field = this.newField) {
        this.customFields.push(field);

        this.newField = {
            label: '', value: '', recurring: true,
        };

        this.dirty = true;
    }

    removeField(idx) {
        this.customFields.splice(idx, 1);
        this.dirty = true;
    }

    async save() {
        if (!this.dirty) {
            Messager.info('No Changes to save');
        }
        this.isLoading = true;
        const notification = Messager.load('Updating custom fields...');

        try {
            const newInvoiceData = { customer_data: { custom_fields: this.customFields } };

            // this is a hack to send just the invoice data to the invoice endpoint
            await (new Subscription(newInvoiceData)).$updateInvoiceCustomFields({
                external_id: this.subscription.external_id,
                invoice_id: this.invoice.external_id,
            });

            this.invoice.customer_data = newInvoiceData.customer_data;
            notification.success('Field added successfully');
            this.dirty = false;
            this.$modalInstance.close('saved');
        } catch (error) {
            notification.error('Problem uploading custom fields');
            logger.error('Error updating custom fields');
            logger.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    cancel() {
        this.$modalInstance.close('canceled');
    }
}

class PrePaymentTouCtrl {
    constructor($scope, $modalInstance) {
        'ngInject';

        this.$modalInstance = $modalInstance;

        $modalInstance.opened.then(() => { $scope.isOpen = true; });
    }


    cancel() {
        this.$modalInstance.close();
    }

    continue() {
        this.$modalInstance.close(true);
    }
}
