import superagent from 'superagent';
import superagentUse from 'superagent-use';

function defaultHeaderPlugin(req: superagent.Request) {
    req.set(DEFAULT_HEADERS);
    return req;
}

// @ts-ignore
const _request = superagentUse(superagent);
_request.use(defaultHeaderPlugin);

// Current FL custom request headers
const HEADERS = {
    impersonateUser: 'IMPERSONATE_USER',
    overridePermissions: 'OVERRIDE_PERMISSIONS',
    logRocketUrl: 'LOG_ROCKET_URL',
    hsClient: 'HS_CLIENT',
};

const DEFAULT_HEADERS = {};

function addDefaultHeader(header: string, value: any) {
    DEFAULT_HEADERS[header] = value;
}

function removeDefaultHeader(header: string) {
    delete DEFAULT_HEADERS[header];
}

function hasDefaultHeader(header: string) {
    return header in DEFAULT_HEADERS;
}

type HTTPMethod = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'HEAD' | 'DELETE';

const requestFn: {
    [k in HTTPMethod]: (url: string) => superagent.SuperAgentRequest;
} = {
    GET: _request.get,
    PUT: _request.put,
    POST: _request.post,
    PATCH: _request.patch,
    HEAD: _request.head,
    DELETE: _request.delete,
};

// superagent use doesn't replace the callable part of the superagent api
const request: superagent.SuperAgentStatic = Object.assign(
    (method: HTTPMethod, url: string) => requestFn[method](url),
    _request,
);

export { request, superagent, addDefaultHeader, removeDefaultHeader, hasDefaultHeader, HTTPMethod, HEADERS };

export default request;
