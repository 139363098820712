import { noop } from 'helioscope/app/utilities/helpers';
import { $q, $rootScope, Messager } from 'helioscope/app/utilities/ng';

import { ResourceUpdateQueue } from './persistence';
import { SinglePropertyChangeDelta, MultiPropertyChangeDelta, StateDelta, makeDelta } from './deltas';
import _ from 'lodash';

/**
 * object that manages state transition for a full undo and redo stack that can handle all
 * CRUD operations on a given Relational Resource
 *
 * changes can be stored as:
 *  - object changes: changes which will generall require 'synchronous'
 *    db actions so as to keep the object hierarchy in the queue intact
 *  - property changes: changes which update a given property of an
 *    already existing object. These are debounced for changes of the
 *    same property and object
 */
export class StateHandler {

    //  how long to debounce undo calls for the same property on a given object
    static UNDO_DEBOUNCE = 5000;

    constructor({ disabled = false } = {}) {
        this.deltas = [];
        this.currentDelta = undefined;
        this.updateQueue = new ResourceUpdateQueue();

        this.callbacks = new Map();
        this.$shifting = false;

        this.disabled = disabled;
    }


    /**
     * register a callback for a given resource type as a resource is modified by the
     * StateHandler, this callback will be executed with the object so that any post update
     * operations can be performerd
     * @param  {RelationalResource}   Resource the constructor used to match callbacks
     * @param  {Function} callback    a callback to execute whenever a resource of type `Resource is changed`
     */
    registerCallback(Resource, callback) {
        this.callbacks.set(Resource, callback);
    }

    /**
     * get the state change callback for an instance of a resource
     * @param  {RelationalResouce} resource instance of a resource that has been modified
     */
    getCallback(resource) {
        const callback = this.callbacks.get(resource.constructor);

        return callback || noop;
    }

    canUndo() {
        return !this.$shifting && this.currentDelta >= 0;
    }

    canRedo() {
        return !this.$shifting && this.currentDelta < (this.deltas.length - 1);
    }

    /**
     * shift the given state of the handler a certain number of steps
     * @param  {int} stepCount  the number of states to shift, + for forward, - for backward
     * @return {promise}        a promise that resolves when the final state change is complete
     */
    async shiftState(stepCount) {
        this.$shifting = true;

        const direction = Math.sign(stepCount);
        const delta = this.deltas[this.currentDelta + (direction > 0 ? 1 : 0)];
        const shift = () => (direction === 1 ? delta.load() : delta.rollback());

        const propertyDelta = (
            delta instanceof SinglePropertyChangeDelta ||
            delta instanceof MultiPropertyChangeDelta ||
            delta.isPropertyDelta === true
        );

        if (!propertyDelta) {
            // if about to do a create/delete operation, then flush the saves before starting
            await this.updateQueue.flush();
        }

        const resource = await shift();
        if (propertyDelta && delta.persistChanges) {
            this.updateQueue.schedule(resource);
        }

        this.currentDelta += direction;

        if (Math.abs(stepCount) > 1) {
            return this.shiftState(stepCount - direction, true);
        }

        this.$shifting = false;
        if (!$rootScope.$$phase) {
            $rootScope.$apply();
        }

        return $q.when(resource);
    }

    redo() {
        if (this.canRedo()) {
            this.shiftState(1);
        }
    }

    undo() {
        if (this.canUndo()) {
            this.shiftState(-1);
        }
    }

    undoStack() {
        return this.deltas.slice(0, this.currentDelta + 1).reverse();
    }

    redoStack() {
        return this.deltas.slice(this.currentDelta + 1, this.length);
    }


    /**
     * create a new state delta and make it the current delta
     * @param {StateDelta} delta a delta representing a state change
     */
    addDelta(delta) {
        //

        // every time you add a delta, you lose the redo history
        if (this.currentDelta !== undefined) {
            this.deltas.length = this.currentDelta + 1;
        }

        this.deltas.push(delta);
        this.currentDelta = this.deltas.length - 1;

        return delta;
    }

    /**
     * Generates and applies any linked changes that are associated with the delta
     * The delta is then updated so that linked changes can be undone if the delta
     * is undone
     */
    makeLinkedChangesAndUpdateDelta(delta) {
        if (delta.resource.linkedPropertyCallback && delta.getPrimaryChanges) {
            const linkedChanges = delta.resource.linkedPropertyCallback(delta.getPrimaryChanges()) || [];
            for (const change of linkedChanges) {
                _.set(delta.resource, change.path, change.newVal);
            }

            delta.setLinkedChanges(linkedChanges);
        }
    }

    /**
     * mark a property change on an existing object this will call the registered callback
     * for the given object type after updating, so any property changes dependencies only
     * need to be handled here
     *
     * if the same property on the same object is modified within a given debounce time
     * (with no intermediate changes), then the state deltas will be merged
     * @param  {object} opts property change options
     */
    markPropertyChange(opts) {
        if (this.disabled) {
            // in theory this should not be quite so hard coded
            Messager.error('Cannot modify a locked design.');
            return $q.reject('design is locked');
        }

        // it would be ideal to auto-debounce this for a given resource
        opts.callback = (opts.callback || this.getCallback(opts.resource));

        const newDelta = makeDelta(opts);
        const current = this.deltas[this.currentDelta];
        const { enableUndo = true } = opts;
        let merged = false;

        this.makeLinkedChangesAndUpdateDelta(newDelta);

        if (enableUndo) {
            if (current && newDelta.timestamp - current.timestamp < StateHandler.UNDO_DEBOUNCE) {
                // if the object and property edited are the same, and there
                // has been less than 5 seconds update the current state
                merged = current.attemptMerge(newDelta);
            }

            if (merged) {
                // if we get a change we lose the future history
                this.deltas.length = (this.currentDelta + 1);
            } else {
                // this state doesn't match the previous object, so store new delta
                this.addDelta(newDelta);
            }
        }

        if (newDelta.persistChanges) {
            this.updateQueue.schedule(opts.resource);
        }

        let ret = null;
        for (const change of newDelta.getChanges()) {
            ret = opts.callback(opts.resource, change.path, change.newVal, change.oldVal);
        }

        return ret;
    }

    /**
     * persist an object an Creation or Deletion to the server and return a promise for when it
     * completes
     * @resource the object to create/delete
     * @options a set of optional callbacks for creation deletion, they are all called back with a
     *          single argument for the resource, should be of the form:
     *          {create: {preflight, onSuccess, onError}, delete: {preflight, onSuccess, onError}}
     * @param  optional args
     *    mode:     'create' or 'delete' a resource
     *    adddelta: add the change to the undo stack
     */
    changeObject(resource, callbacks, { mode = 'create', addDelta = true } = {}) {
        if (this.disabled) {
            // in theory this should not be quite so hard coded
            Messager.error('Cannot modify a locked design.');
            return $q.reject('design is locked');
        }

        const currentCallbacks = callbacks[mode];
        const otherMode = mode === 'create' ? 'delete' : 'create';

        if (currentCallbacks.preflight) {
            currentCallbacks.preflight(resource);
        }

        if (mode === 'create') {
            delete resource[resource._idName];
        }

        return this.updateQueue[mode](resource)
            .then(res => {
                if (addDelta) {
                    this.addDelta(new StateDelta({
                        resource: res,
                        loadText: currentCallbacks.text,
                        loadFn: () => this.changeObject(res, callbacks, { mode, addDelta: false }),
                        rollbackText: callbacks[otherMode].text,
                        rollbackFn: () => this.changeObject(res, callbacks, { mode: otherMode, addDelta: false }),
                    }));
                }

                if (currentCallbacks.onSuccess) {
                    currentCallbacks.onSuccess(res);
                }
                return res;
            })
            .catch(err => {
                if (err === 'duplicate') {
                    return $q.reject(err);
                }

                if (currentCallbacks.onError) {
                    currentCallbacks.onError(err);
                }
                return $q.reject(err);
            });
    }


    /**
     * create an object and add it to the undo stack
     */
    createObject(resource, options) {
        return this.changeObject(resource, options, { mode: 'create', addDelta: true });
    }

    /**
     * delete an object and add it to the undo stack
     */
    deleteObject(resource, options) {
        return this.changeObject(resource, options, { mode: 'delete', addDelta: true });
    }

}
