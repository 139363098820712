import _ from 'lodash';

import { getChat } from 'helioscope/app/utilities/chat-wrapper';
import * as analytics from 'helioscope/app/utilities/analytics';
import { Messager, $filter, $rootScope } from 'helioscope/app/utilities/ng';
import { lidarEnabled } from 'helioscope/app/utilities/lidar/util';

import { SitePlanConfig } from '../designer/SitePlanConfig';
import { MapConfig } from '../designer/MapConfig';
import { Design3DRenderer } from './Design3DRenderer';
import { LOAD_TOAST_MESSAGES, SOURCE_CFG } from './LidarHelper';

const mod = angular.module('helioscope.apollo.directives', ['helioscope.designer.paywall']);

const persistState = {};

mod.directive('apollo', ['paywallDlg', function (paywallDlg) {
    return {
        restrict: 'EA',
        scope: {
            dispatcher: '=',
            canvasSettings: '=',
        },
        templateUrl: require('helioscope/app/apollo/partials/apollo.html'),
        link: ($scope, element, attributes) => {
            const options = {
                addCaptions: attributes.addCaptions === 'true',
                enableShadows: attributes.enableShadows !== 'false',
                enableImagery: attributes.enableImagery !== 'false',
                enableLidar: attributes.enableLidar !== 'false',
                enableInterface: attributes.enableInterface !== 'false',
                enableInteractionTools: attributes.enableInteractionTools !== 'false',
                sitePlanRender: attributes.sitePlanRender === 'true',
                renderConfig: (attributes.renderConfig === 'siteplan') ? SitePlanConfig : MapConfig,
            };

            const persistCamera = attributes.persistCamera !== 'false';

            // NOTE: options.enableLidar is a setting passed in PDF generation components to the directive as a prop
            // 'enable-lidar' to disable lidar. Check desinger.3d_render.html, render.3d.html and shading.html.
            options.enableLidar = lidarEnabled() && options.enableLidar;
            $scope.userOnTrial = $rootScope.user().isOnTrial();

            $scope.options = options;
            const renderer = new Design3DRenderer($scope.dispatcher, options);

            const closeDropdown = () => {
                if ($scope.showDropDown) {
                    $scope.showDropDown = false;
                    $scope.$apply();
                }
                $scope.cancelCloseDropDown();
            };

            $scope.scheduleCloseDropDown = () => {
                $scope.cancelCloseDropDown();
                if ($scope.showDropDown) {
                    $scope.closeFn = _.throttle(closeDropdown,
                        1200, { leading: false, trailing: true });
                    $scope.closeFn();
                }
            };

            $scope.cancelCloseDropDown = () => {
                if ($scope.closeFn) {
                    $scope.closeFn.cancel();
                    $scope.closeFn = null;
                }
            };

            $scope.toggleImageryDropDown = () => {
                $scope.showDropDown = !$scope.showDropDown;
            };

            $scope.toggleImagerySource = (name) => {
                renderer.setImagerySource(name);
            };

            $scope.triggerLidarLoad = () => {
                $scope.dispatcher.renderer.lidarHelper.updateData(true);
            };

            $scope.updateDisplayMode = (mode) => {
                analytics.track('lidar.updateDisplayMode', {
                    mode,
                    project_id: $scope.design.project_id,
                    design_id: $scope.design.design_id,
                    team_id: $rootScope.user().team_id,
                });
                $scope.updateSettingsAndRedrawLidar();
            };

            $scope.toggleLidar = () => {
                const newVisibility = !$scope.dispatcher.renderer.lidarHelper.getVisibility();
                analytics.track('lidar.toggleLidar', {
                    toggle: newVisibility ? 'on' : 'off',
                    project_id: $scope.design.project_id,
                    design_id: $scope.design.design_id,
                    team_id: $rootScope.user().team_id,
                });
                $scope.dispatcher.renderer.lidarHelper.setVisibility(newVisibility);
            };

            $scope.isLidarVisible = () => $scope.dispatcher.renderer &&
                    $scope.dispatcher.renderer.lidarHelper && $scope.dispatcher.renderer.lidarHelper.getVisibility();


            $scope.updateSettingsAndRedrawLidar = () => {
                $scope.dispatcher.stateHandler.updateQueue.schedule($scope.design);
                $scope.dispatcher.renderer.lidarHelper.forceRedraw();
            };

            $scope.trackLidarSettingsOpen = () => {
                analytics.track('lidar.openAdvSettings', {
                    referrer: 'floating menu',
                    project_id: $scope.design.project_id,
                    design_id: $scope.design.design_id,
                    team_id: $rootScope.user().team_id,
                });
            };

            $scope.openLidarPaywall = () => {
                // track paywall click
                analytics.track('paywall.open', {
                    referrer: 'floating_button',
                    modal_name: 'lidar',
                });

                // open paywall modal
                paywallDlg($scope.design);
            };

            $scope.webglUnsupported = () => renderer.initFailed;

            $scope.contactSupport = () => {
                getChat().newMessage('Hello. My browser does not support 3D rendering.');
            };

            const lidarUnsub = [];
            $scope.lidarStatus = { loading: true };

            const initRenderer = async () => {
                const container = element[0].querySelector('#apolloContainer');

                renderer.initRenderer(container, $scope.canvasSettings);

                if (persistCamera &&
                    persistState.cameraState &&
                    $scope.design.design_id === persistState.lastDesignID) {
                    renderer.restoreCameraState(persistState.cameraState);
                } else {
                    renderer.fitProjectView();
                }

                await renderer.onReady();
                $scope.dispatcher.registerRenderer(renderer);

                if (options.enableLidar) {
                    lidarUnsub.push(renderer.lidarHelper.subscribe('lidarLoadStart',
                        () => {
                            const dataSource = renderer.design.lidarSettings.data_source;
                            const dataSourceName = SOURCE_CFG[dataSource].fullName;
                            $scope.lidarStatus.loading = true;
                            if ($scope.notification) {
                                $scope.notification.close();
                            }
                            $scope.notification = Messager.load('Loading '.concat(dataSourceName));
                            $scope.notification.progress(
                                { icon: 'fa fa-spinner fa-spin', text: 'Loading '.concat(dataSourceName) });
                            $scope.$apply();
                        }));

                    lidarUnsub.push(renderer.lidarHelper.subscribe('lidarLoadEnd',
                        (_lidarHelper, { success, status, dataSourceName }) => {
                            const max = $filter('hsDistance')(renderer.lidarHelper.MAX_BOUNDS_SIZE_METERS, 0);
                            const getMessage = LOAD_TOAST_MESSAGES[status];
                            const message = getMessage({ max, dataSourceName });
                            if (success) {
                                $scope.notification.success(message);
                            } else {
                                $scope.notification.error(message);
                            }
                            $scope.lidarStatus.available = success;
                            $scope.lidarStatus.loading = false;
                            $scope.$apply();
                        }));
                }
            };

            $scope.$on('$destroy', () => {
                while (lidarUnsub.length) {
                    const unsub = lidarUnsub.pop();
                    unsub();
                }

                if ($scope.notification) {
                    $scope.notification.close();
                }

                // possible that dispatcher has new renderer already
                $scope.dispatcher.unregisterRenderer(renderer);
                if ($scope.closeFn) {
                    $scope.closeFn.cancel();
                }
                if (persistCamera) {
                    persistState.cameraState = renderer.saveCameraState();
                    persistState.lastDesignID = $scope.design.design_id;
                }
                renderer.shutdownRenderer();
            });

            $scope.design = $scope.dispatcher.design;
            initRenderer($scope.design);
            $scope.imagerySourceName = () => renderer.tileLayerName;
        },
    };
}]);
