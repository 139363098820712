import Logger from 'js-logger';
import {
    Messager,
    pusher,
    $q,
} from 'helioscope/app/utilities/ng';

import * as analytics from 'helioscope/app/utilities/analytics';

import { DesignDispatcher } from 'helioscope/app/designer/events';
import { user } from 'helioscope/app/users';

const logger = Logger.get('renders');

export function designRender3d() {
    'ngInject';

    return {
        restrict: 'EA',
        scope: { design: '=' },
        templateUrl: require('helioscope/app/projects/reports/partials/render.3d.html'),
        link: ($scope) => {
            const design = $scope.design;
            design.initializeDesignScene();
            $scope.dispatcher = new DesignDispatcher(design, {
                pdfSettings: user.preferences.design_renders,
                designMutable: false,
                showWiring: true,
            });

            $scope.dispatcher.loadComponents().then(() => {
                $scope.dispatcher.renderer.setRenderOverrides($scope.preferences);
                // $scope.dispatcher.renderUpdater.updateForShading();
            });

            $scope.preferences = angular.copy(angular.extend({
                modules: true,
                inverters: true,
                combiners: true,
                wiring: true,
                field_segments: true,
                keepouts: true,
                interconnect: true,
            }, user.preferences.design_renders));

            $scope.update = () => {
                angular.extend(user.preferences.design_renders, $scope.preferences);

                user.$updateRenderPreferences()
                    .then(respUser => {
                        angular.extend(user.preferences.design_renders, respUser.preferences.design_renders);
                        angular.extend(
                            user.preferences.design_renders,
                            respUser.preferences.design_renders
                        );
                    })
                    .catch(err => {
                        logger.error('Could not update design preferences', err);
                    });

                $scope.dispatcher.renderer.setRenderOverrides($scope.preferences);
                $scope.dispatcher.renderer.renderDesign(design);
            };

            $scope.$on('pdf:prerender', () => {
                $scope.dispatcher.renderer.renderFrame();
            });

            $scope.$on('$destroy', () => {
                $scope.dispatcher.cleanup();
            });
        },
    };
}

export function designRender() {
    'ngInject';

    return {
        restrict: 'EA',
        scope: { design: '=' },
        templateUrl: require('helioscope/app/projects/reports/partials/render.2d.html'),
    };
}

export function DesignRenderCtrl($scope) {
    'ngInject';

    const userCopy = angular.copy(user);
    userCopy.preferences = userCopy.preferences || {};
    userCopy.preferences.design_renders = userCopy.preferences.design_renders || {};

    $scope.resolutions = [
        { x: 1200, y: 900 },
        { x: 1600, y: 1200 },
        { x: 2000, y: 1600 },
        { x: 2400, y: 2000 },
    ];

    function matchedResolution() {
        return _.find($scope.resolutions, (res) => (res.x === $scope.preferences.resolution.x &&
                                                    res.y === $scope.preferences.resolution.y));
    }

    $scope.preferences = angular.copy(angular.extend({
        modules: true,
        inverters: true,
        combiners: true,
        wiring: true,
        field_segments: true,
        keepouts: true,
        resolution: $scope.resolutions[0],
    }, userCopy.preferences.design_renders));


    $scope.preferences.resolution = matchedResolution();
    let asyncLoaderDeferred; // need to make this global to the directive so that
                             // we dont end up with stuck progress bars

    const channel = pusher(`project@${$scope.design.project_id}`);

    $scope.$on('$destroy', () => {
        channel.unsubscribe();
        if (asyncLoaderDeferred) {
            asyncLoaderDeferred.resolve({ title: 'Exited design' });
        }
    });

    function configureRenderListeners(design, deferred) {
        let errWatch = angular.noop;
        let completeWatch = angular.noop;
        let saveWatch = angular.noop;

        completeWatch = channel.watch('render.complete', ({ design_id: designId, render_url: renderUrl }) => {
            if (designId !== $scope.design.design_id) return;

            deferred.resolve({
                title: `Done rendering ${design.description}`,
                text: `<a href="${renderUrl}" target="_blank">Download it here</a>`,
            });

            errWatch();
            completeWatch();
        });

        saveWatch = channel.watch('render.saving', ({ design_id: designId }) => {
            if (designId !== $scope.design.design_id) return;

            deferred.notify({ text: 'Saving design render', value: 75 });
            saveWatch();
        });

        errWatch = channel.watch('render.error', ({ design_id: designId }) => {
            if (designId !== $scope.design.design_id) return;

            deferred.reject({ title: 'Error rendering design, please try again.' });
            saveWatch();
            errWatch();
            completeWatch();
        });
    }

    $scope.renderDesign = () => {
        if ($scope.design.currently_rendering) {
            logger.warn(`Got double render for design ${$scope.design.design_id}`);
            return;
        }

        asyncLoaderDeferred = $q.defer();

        Messager.asyncLoader('Saving preferences...', asyncLoaderDeferred.promise);

        angular.extend(userCopy.preferences.design_renders, $scope.preferences);

        userCopy.$updateRenderPreferences()
            .then(respUser => {
                angular.extend(userCopy.preferences.design_renders, respUser.preferences.design_renders);
                angular.extend(user.preferences.design_renders,
                               respUser.preferences.design_renders);

                $scope.preferences.resolution = matchedResolution();

                asyncLoaderDeferred.notify({ text: 'Starting render', value: 5 });

                $scope.design.$render()
                    .then(() => {
                        $scope.design.currently_rendering = true; // a minor hack to force the loading state to be
                                                                  // be correct, we could do this on the server side
                                                                  // but then we'd want to do some gymnastics to not
                                                                  // suddenly hide the old render while waiting for a
                                                                  // new one
                        asyncLoaderDeferred.notify({ text: 'Rendering design', value: 20 });
                    })
                    .catch(() => {
                        $scope.design.currently_rendering = false;
                        asyncLoaderDeferred.reject({ title: 'Could not trigger design render' });
                    });

                configureRenderListeners($scope.design, asyncLoaderDeferred);
            })
            .catch(() => {
                asyncLoaderDeferred.reject({
                    text: 'Could not save design preferences',
                    title: undefined,
                });
            });
    };

    $scope.sameSettings = () => angular.equals(userCopy.preferences.design_renders, $scope.preferences);

    if (!$scope.design.render_url && !$scope.design.currently_rendering) {
        analytics.track('design.rerender', {
            design_id: $scope.design.design_id,
            nameplate: _.get($scope.design, 'field_component_metadata.nameplate', 0),
            project_id: $scope.design.project_id,
            team_id: userCopy.team_id,
        });
        $scope.renderDesign();
    }
}
