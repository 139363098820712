/* globals zip:true, angular:true */
import Logger from 'js-logger';
import { keyBy } from 'lodash';

import { Messager, $http, $q, $timeout } from 'helioscope/app/utilities/ng';
import { user } from 'reports/angular-bridge.ts';
import { zipConfig } from 'helioscope/app/config';
import * as bugReports from 'helioscope/app/utilities/bug_reports';
import * as statsd from 'helioscope/app/utilities/statsd';

const logger = Logger.get('component_loader');

function serializeDesignComponents(design) {
    let components = design.getComponents();
    if (components[0] && components[0].component_type === 'interconnect') {
        // the server does not want to see the root interconnect
        components = components[0].children;
    }

    return {
        components,
        mechanical: {
            field_segment_metadata: keyBy(design.field_segments.map(fs => ({
                field_segment_id: fs.field_segment_id,
                racking_structures: fs.serializeRacks(),
            })), 'field_segment_id'),
        },
    };
}

/**
 * This method is incredibly inefficient, especially for complex designs. It sends every field segment and keepout
 * on the design to the backend, which can take a really long time to process. This method should only be used for
 * HS admin-only operations (such as the moveAll/"Move Everything" operation) until we have time to optimize it.
 */
export function saveAllFieldSegmentsAndKeepouts(design) {
    const payload = {
        // Currently we're serializing every field of the field segment/keepout, yet all that's needed is the geometry.
        field_segments: design.field_segments.map(fs => fs.toJSON()),
        keepouts: design.keepouts.map(ko => ko.toJSON()),
    };

    return $http.post(`/api/designs/multi_update/${design.design_id}`, payload);
}

export async function saveDesignComponents(design) {
    const { canCompress } = zipConfig;

    const rawPayload = serializeDesignComponents(design);

    const messageDeferred = $q.defer();
    Messager.asyncLoader('Saving Design Components...', messageDeferred.promise);

    const zipDeferred = $q.defer();
    if (canCompress) {
        const json = angular.toJson(rawPayload);

        zip.createWriter(new zip.Data64URIWriter(), (writer) => {
            // use a TextReader to read the String to add
            writer.add('components.json', new zip.TextReader(json),
                () => writer.close((blob) => zipDeferred.resolve(blob)), // onSucceess
                (currentIndex, totalIndex) => {
                    messageDeferred.notify({ text: 'Compressing data', value: (currentIndex / totalIndex) * 50 });
                },
                (error) => {
                    logger.warn('Compression Error');
                    logger.warn(error);
                    messageDeferred.reject({ text: design.description, title: 'Could Not Compress Design' });
                },
            );
        });
    } else {
        zipDeferred.resolve(rawPayload);
    }

    const payload = await zipDeferred.promise;

    const updateMsg = $timeout(() => {
        messageDeferred.notify({ text: 'Uploading data', value: 88 });
    }, 2000, false);
    messageDeferred.notify({ text: 'Uploading data', value: 64 });

    try {
        // This API deletes the design's old simulations
        const { data } = await $http.post(
            `/api/field_components/${design.design_id}`,
            payload,
            { params: { zip: canCompress } },
        );

        if (data.metadata_only) {
            const wiringLimit = user.hasFeature('enable_15MW_simulations') ? 15 : 7.5;
            messageDeferred.resolve({
                type: 'info',
                title: `Finished Saving ${design.description}`,
                text: `Warning: Designs above ${wiringLimit} MWp cannot be simulated; no wiring data has been saved`,
                delay: 1500,
                nonblock: true,
            });
        } else {
            messageDeferred.resolve({
                title: 'Finished Saving Design',
                text: design.description,
                delay: 1500,
                nonblock: true,
            });
        }
        logger.info(`saved design components for design ${design.design_id}`);

        return data;
    } catch (err) {
        logger.warn(err);
        messageDeferred.reject({ text: design.description, title: 'Could Not Save Design' });
        throw Error('Could not load design components');
    } finally {
        $timeout.cancel(updateMsg);
    }
}
saveDesignComponents = statsd.instrumentAsync(saveDesignComponents, 'saveDesignComponents');  // eslint-disable-line

export async function loadDesignComponents(design) {
    logger.log(`Loading components for design ${design.design_id}`);
    const notification = Messager.load('Downloading array components');

    try {
        const results = await $http.get(`/api/field_components/${design.design_id}`);
        logger.log(`Received components for design ${design.design_id}`);
        notification.progress({ icon: 'fa fa-cogs', text: 'Processing download' });

        if (results.data && results.data.length) {
            const { components, unmatchedModules } = design.loadFieldComponents(results.data);

            notification.success('Successfully downloaded wiring');
            return { components, unmatchedModules };
        }

        notification.success('Generating array components');

        // hack: the dispatcher actually will generate the components, assuming components is empty
        return { components: [], unmatchedModules: [] };
    } catch (exc) {
        logger.warn(exc);
        notification.error('Could not load field components');

        const opts = {
            extra: {
                design_id: design.design_id,
                project_id: design.project_id,
            },
        };
        bugReports.captureException(exc, opts);

        return $q.reject(exc);
    }
}
loadDesignComponents = statsd.instrumentAsync(loadDesignComponents, 'loadDesignComponents');  // eslint-disable-line
