import moment from 'moment';
import _ from 'lodash';

import Logger from 'js-logger';

import * as analytics from 'helioscope/app/utilities/analytics';
import { Bounds } from 'helioscope/app/utilities/geometry';
import {
    $http, ngRefs as ng, $state, $modal, $sce, $rootScope, $document, $q, Messager,
} from 'helioscope/app/utilities/ng';

import { installDebugTools } from 'helioscope/app/designer/debug/debug_tools';
import { InternalClipboard } from 'helioscope/app/designer/persistence/DesignerClipboard';
import { user } from 'helioscope/app/users/auth';
import { isEventFromTextInput, KEY } from 'helioscope/app/utilities/helpers';

import { FIELD_SEGMENT_MAP_ACTIONS, FieldSegment } from '../field_segment';
import { Keepout, KEEPOUT_MAP_ACTIONS } from '../keepout';
import { EntityPremade } from '../premade';
import { COMPONENT_MAP_ACTIONS } from '../wiring_zone';
import { DESIGN_MAP_ACTIONS } from '../actions';
import { Design } from '../Design';
import { BufferRenderer } from '../renderer/Renderer';
import { StateHandler } from '../persistence/StateHandler';
import { PubSub } from './PubSub';
import { RenderUpdater } from '../design_manager/RenderUpdater';
import { DesignManager } from '../design_manager/DesignManager';
import { Overlay } from '../overlays';
import { SimilarObstructionDetectionHelper } from '../../apollo/SimilarObstructionDetectionHelper';

import { saveDesignComponents, loadDesignComponents } from '../components';

const logger = Logger.get('dispatcher');

export class DesignDispatcher extends PubSub {
    constructor(design, settings = {}) {
        super();
        this.design = design;
        this.settings = settings;

        this.unsubscribers = [];

        this.designDirty = false; // track weather the design has been changed
        this.showModules = true;
        this.showWiring = false; // hack to hide wiring unless in the wiring view
        this.showShading = false;

        this.stateHandler = new StateHandler({ disabled: design.locked || !this.settings.designMutable });
        this.renderUpdater = new RenderUpdater(this);
        this.designManager = new DesignManager(this);
        this.internalClipboard = new InternalClipboard();
        this.selectMode = false;

        this.team_id = $rootScope.user().team_id;

        this.resetRenderer();

        if (this.settings.designMutable) {
            this.addStateChangeListeners();
            this.addKeyboardListeners();
            this.addMapEventListeners();
            this.registerStateHandlerCallbacks();
        }

        if (this.settings.showWiring) {
            this._renderWiring = true;
        }

        this.selectedEntity = null;
        this.selectedEntities = new Set();
        this.highlightedEntities = new Set();
        this.isOnPasteMode = false;
        this.sodHelper = new SimilarObstructionDetectionHelper(this);
    }

    keyboardListenerName = 'keydown.designer-input';

    addKeyboardListeners() {
        $document.on(this.keyboardListenerName, (evt) => {
            if (isEventFromTextInput(evt)) {
                return;
            }
            if (evt.keyCode === KEY.SPACE) {
                if (evt.shiftKey) {
                    this.toggleModules();
                    $rootScope.$apply();
                }
            }

            if (evt.keyCode === KEY.s) {
                if (this.isBulkActionsEnabled()) {
                    this.toggleSelectMode();
                    $rootScope.$apply();
                }
            }
        });
    }

    toggleModules() {
        this.showModules = !this.showModules;
        analytics.track('dispatcher.toggle_modules', {
            showModules: this.showModules,
            design_id: this.design.design_id,
            team_id: this.team_id,
            project_id: this.design.project.project_id,
        });

        for (const fs of this.design.field_segments) {
            if (this.showModules) {
                this.renderer.renderModules(fs);
            } else {
                this.renderer.clearSubpolygons(fs);
            }
        }

        if (this.showWiring) {
            if (this.showModules) {
                this.renderer.renderWiringTree(this.design);
            } else {
                this.renderer.clearSubpolygons(this.design);
            }
        }
    }

    unregisterRenderer(renderer) {
        if (renderer instanceof BufferRenderer) {
            logger.debug('Asked to remove BufferRenderer');
            return;
        }

        if (this.renderer !== renderer) {
            logger.warn('Asked to unregister an inactive renderer', renderer);
            return;
        }

        this.renderer.clearDesign(this.design);
        this.renderer.destroy();

        this.resetRenderer();
    }

    resetRenderer() {
        this.renderer = new BufferRenderer();
        this.rendererReady = $q.defer();
    }

    registerRenderer(renderer) {
        const lastRenderer = this.renderer;
        if (lastRenderer) {
            this.unregisterRenderer(lastRenderer);
        }

        this.renderer = renderer;

        if (lastRenderer instanceof BufferRenderer) {
            lastRenderer.replayCalls(renderer);
        }

        if (this.lastActiveSelection) {
            this.renderSurfaceSelection(this.lastActiveSelection);
        }

        this.render();

        this.rendererReady.resolve(renderer);
        this.publish('rendererUpdated', { renderer });

        if (user.hasFeature('admin_debug')) {
            installDebugTools(this);
        }
    }

    highlightEntity(entity, highlight, pubsubOptions) {
        if (highlight) {
            if (!this.highlightedEntities.has(entity)) {
                this.highlightedEntities.add(entity);
                this.publish('entityHighlightChanged', pubsubOptions);
            }
        } else if (this.highlightedEntities.has(entity)) {
            this.highlightedEntities.delete(entity);
            this.publish('entityHighlightChanged', pubsubOptions);
        }
    }

    isBulkActionsEnabled() {
        return $rootScope.user().hasFeature('designer_bulk_actions');
    }

    isSODEnabled() {
        return $rootScope.user().hasFeature('enable_sod_override');
    }

    selectEntity(entity, pubsubOptions, multiple = false) {
        if (!entity) return;

        this.addOrRemoveEntityToSelection(entity, multiple);

        this.changeState(this.selectedEntity, this.selectedEntities.size > 1, multiple);

        this.publish('entitySelectionChanged', pubsubOptions);
    }

    addOrRemoveEntityToSelection(entity, multiple) {
        if (multiple) {
            if (this.selectedEntities.has(entity)) {
                this.selectedEntities.delete(entity);
                if (this.selectedEntities.size === 0) {
                    this.selectedEntity = null;
                }
                if (this.selectedEntities.size === 1) {
                    this.selectedEntity = this.selectedEntities.values().next().value;
                }
            } else {
                this.selectedEntities.add(entity);
                this.selectedEntity = entity;
            }
        } else {
            this.selectedEntities.clear();
            this.selectedEntities.add(entity);
            this.selectedEntity = entity;
        }
    }

    changeState(entity, bulk = false, multiple = false) {
        const showBulkActions = bulk && multiple && this.isBulkActionsEnabled();
        if (showBulkActions) {
            $state.go('designer.design.bulk_actions_panel', {});
            return;
        }

        if (entity instanceof FieldSegment &&
            !$state.includes('designer.design.field_segments.detail') &&
            multiple) {
            $state.go('designer.design.field_segments.detail', {
                field_segment_id: entity.field_segment_id,
            });
        }

        if (entity instanceof EntityPremade &&
            !$state.includes('designer.design.combinedkeepouts')) {
            $state.go('designer.design.combinedkeepouts', {
                entity_premade_id: entity.entity_premade_id,
            });
        }

        if (entity instanceof Keepout &&
            !$state.includes('designer.design.combinedkeepouts')) {
            $state.go('designer.design.combinedkeepouts', {
                keepout_id: entity.keepout_id,
            });
        }

        if (entity instanceof Overlay &&
            !$state.includes('designer.design.overlays')) {
            $state.go('designer.design.overlays', {});
        }
    }

    clearSelection() {
        this.selectedEntity = null;
        this.selectedEntities.clear();
        this.publish('entitySelectionChanged');
    }

    deselectEntity({ routeToFieldSegments = false } = {}) {
        if ((this.selectedEntity instanceof FieldSegment && routeToFieldSegments) ||
            $state.includes('designer.design.bulk_actions_panel')) {
            $state.go('designer.design.field_segments');
        }

        this.clearSelection();
    }

    onRendererReady(callback = _.noop) {
        return this.rendererReady.promise.then(callback);
    }

    async loadComponents() {
        const { design } = this;
        let { components, unmatchedModules } = await loadDesignComponents(design);

        // hack: ensure that FS are genned if there's no wiring tree from the server
        if (_.isEmpty(components) && !_.isEmpty(design.field_segments)) {
            for (const fs of design.field_segments) {
                fs.moduleFill();
            }

            design.generateWiring();

            components = design.getComponents();
            unmatchedModules = [];

            // this enables the wiring to be saved on exit by default, otherwise
            // user would have to manually dirty the design.
            this.designDirty = true;
        }

        return { components, unmatchedModules };
    }

    async render() {
        const { design } = this;
        const { pdfSettings } = this.settings;

        if (this.showWiring && this.showModules) {
            this.renderer.renderWiringTree(design);
        }

        // TODO: MT: kill this when 2d goes away -- just use raw canvas data
        if (pdfSettings) {
            this.renderForPdf(pdfSettings);
        }
    }

    cleanup() {
        if (this.renderer) {
            this.unregisterRenderer(this.renderer);
        }

        _.each(this.unsubscribers, unsub => unsub());
        this.unsubscribers = [];

        if (this.removeStateListener) {
            this.removeStateListener();
        }

        ng.$window.onbeforeunload = undefined;
        $document.off(this.keyboardListenerName);
    }

    /**
     * subscribers to listen for and respond to events that maps will broadcast on user interaction
     */
    addMapEventListeners() {
        for (const action of Object.keys(FIELD_SEGMENT_MAP_ACTIONS)) {
            this.unsubscribers.push(this.subscribe(action, FIELD_SEGMENT_MAP_ACTIONS[action]));
        }

        for (const action of Object.keys(KEEPOUT_MAP_ACTIONS)) {
            this.unsubscribers.push(this.subscribe(action, KEEPOUT_MAP_ACTIONS[action]));
        }

        for (const action of Object.keys(COMPONENT_MAP_ACTIONS)) {
            this.unsubscribers.push(this.subscribe(action, COMPONENT_MAP_ACTIONS[action]));
        }

        for (const action of Object.keys(DESIGN_MAP_ACTIONS)) {
            this.unsubscribers.push(this.subscribe(action, DESIGN_MAP_ACTIONS[action]));
        }
    }

    async onStateChange(event, toState, toParams, fromState, fromParams) {
        // if we event.preventDefault(), we have to re-trigger the transition
        let needsToGoAgain = false;

        // ensure changes are saved before changing state
        if (this.stateHandler.updateQueue.size > 0) {
            event.preventDefault();
            needsToGoAgain = true;
            await this.stateHandler.updateQueue.flush();
        }

        const leavingDesigner = (
            !toState.name.startsWith('designer.design') ||
            toParams.design_id !== fromParams.design_id
        );

        if (this.designDirty && leavingDesigner && !this.design.locked) {
            event.preventDefault();
            needsToGoAgain = true;
            try {
                await this.saveDesign();
            } catch (exception) {
                // TODO - handle exit errors more gracefully
                logger.error(exception);
                needsToGoAgain = false; // don't continually try to redirect on failed saves
            }
        }

        if (needsToGoAgain) {
            $state.go(toState, toParams);
        }
    }

    saveDesign() {
        if (this.noPersist) {
            const promise = $q.defer();
            promise.resolve();
            return promise.promise;
        }

        this.renderUpdater.updateArray();

        // if the wiring algorithm runs after a field segment is created, but before it assigned a
        // unique id, that field segment will be excluded from the wiring tree generation
        const wiredModules = this.design.getFlattenedComponents({ module: true });
        const wiredFieldSegments = new Set(_(wiredModules).map('fieldSegment').value());

        for (const fs of this.design.field_segments) {
            if (!wiredFieldSegments.has(fs)) {
                logger.debug('Had to regenerate wiring before saving');
                this.design.generateWiring();
                break;
            }
        }

        // subtle: clear dirty state before saveDesignComponents()
        // so that in case of an exception, we don't retry it
        this.designDirty = false;

        const projBounds = new Bounds(this.design.zoomPath());

        // update tile cache
        const url = `/api/tile_cache/${this.design.project.project_id}/${this.design.geometry.tile_layer}`;
        const center = this.design.project.location.offsetVector(projBounds.midpoint);
        const payload = _.assign({}, {
            latitude: center.latitude,
            longitude: center.longitude,
            x_extent: projBounds.width,
            y_extent: projBounds.height,
        });
        $http.put(url, payload);

        // instrument distance from center on save
        const abss = [projBounds.minX, projBounds.minY, projBounds.maxX, projBounds.maxY].map(i => Math.abs(i));
        const maxDist = Math.max(...abss);
        const maxBounds = Math.max(projBounds.maxY - projBounds.minY, projBounds.maxX - projBounds.minX);
        analytics.track('design.save_bounds', {
            max_dist: maxDist,
            max_bounds: maxBounds,
            design_id: this.design.design_id,
            project_id: this.design.project_id,
            team_id: this.team_id,
        });

        // This deletes design's old simulations
        return saveDesignComponents(this.design);
    }


    addStateChangeListeners() {
        this.removeStateListener = ng.$rootScope.$on('$stateChangeStart', this.onStateChange.bind(this));

        ng.$window.onbeforeunload = () => {
            if (this.stateHandler.updateQueue.size > 0) {
                this.stateHandler.updateQueue.flush();
                return 'There are unsaved changes, are you sure you want to exit?';
            }

            return undefined;
        };
    }

    /**
     * a list of default callbacks to run after a property changes on a given entity type.
     */
    registerStateHandlerCallbacks() {
        for (const [Resource, callback] of this.designManager.getStateHandlerCallbacks()) {
            this.stateHandler.registerCallback(Resource, (resource, propertyPath, newVal, oldVal) => {
                callback(resource, propertyPath, newVal, oldVal);
                this.publish('resourceUpdated', { resource, propertyPath, newVal, oldVal });
            });
        }
    }

    createMultiPropertyChange(delta) {
        for (const change of delta.changes) {
            _.set(delta.resource, change.path, change.newVal);
        }

        this.stateHandler.markPropertyChange(delta);
    }

    createSinglePropertyChange(delta) {
        const multiDelta = _.assign({}, delta, { changes: [delta] });
        this.createMultiPropertyChange(multiDelta);
    }

    setShowWiring(showWireTree) {
        this.showWiring = (showWireTree === true);
        this.renderUpdater.updateWiring();
    }

    setShowShading(showShading) {
        this.showShading = (showShading === true);
    }

    async renderForPdf(settings) {
        this.setShowWiring(true);
        this.renderer.updateForPdf(this.design, settings);
        this.renderer.renderingCompleted()
            // eslint-disable-next-line no-console
            .then(() => { console.log('design_render_complete'); })
            // communicates to headless chrome that render is finished
            .catch((err) => {
                logger.error(err ? err.message : 'unknown error waiting for rendering to complete');
                // eslint-disable-next-line no-console
                console.log('design_render_error'); // communicate to headless chrome render has failed
            });
    }

    setStateFlags(flags) {
        this.stateFlags = this.stateFlags || {};
        _.assign(this.stateFlags, flags || {});
    }

    getStateFlags() {
        return this.stateFlags || {};
    }

    copySelectedEntitiesToClipboard() {
        if (this.selectedEntities.size > 0) {
            this.internalClipboard.writeToClipboard(Array.from(this.selectedEntities));
            if (this.selectedEntities.size > 1) {
                Messager.info(`Copied ${this.selectedEntities.size} objects to clipboard`);
            } else {
                Messager.info(`Copied ${this.selectedEntity.description} to clipboard`);
            }
        }
    }

    copyEntityFromMenu(entity) {
        this.internalClipboard.writeToClipboard([entity]);
        Messager.info(`Copied ${entity.description} to clipboard`);
    }

    activatePasteMode() {
        if (this.internalClipboard.readFromClipboard().length > 0) {
            this.isOnPasteMode = true;

            if (!this.insertionPromptMessage) {
                this.insertionPromptMessage = Messager.load('Specify insertion point');
            }

            this.renderer.initCursorRendering();
        }
    }

    deactivatePasteMode() {
        if (this.isOnPasteMode) {
            this.isOnPasteMode = false;

            this.renderer.clearSurfaceCursorPrimitives();

            if (this.insertionPromptMessage) {
                this.insertionPromptMessage.close();
                this.insertionPromptMessage = null;
            }
        }
    }

    toggleSelectMode() {
        this.selectMode = !this.selectMode;
        this.onRendererReady(() => {
            if (this.selectMode) {
                this.renderer.activateInteractTool({
                    tool: 'InteractToolBoxSelect',
                });
            } else {
                this.renderer.activateInteractTool(null);
                this.publish('selectionModeExited');
            }
        });
    }

    startSOD(keepoutId) {
        if(this.isSODEnabled()) {
            this.sodHelper.startSOD(keepoutId);
        }
    }
}

export async function createDesignBackup(design, unmatchedModules) {
    if (design.geometry.is_backup) {
        logger.info('Design is a backup so dont create a new backup', design);
        return null;
    } else if (design.geometry.backup_design_id) {
        logger.info('Design already has a backup, dont create a new one', design);
        return null;
    }

    analytics.track('designs.backup',
        { design_id: design.design_id, project_id: design.project_id, team_id: this.team_id });

    const formattedDate = moment().format('YYYY-MM-DD hh:mm:ss');

    const designBackup = new Design(Object.assign(
        {},
        design,
        {
            description: `${design.description} (backup ${formattedDate})`,
            clone_design_id: design.design_id,
            locked: true,
            design_id: null,
        },
    ));

    designBackup.geometry.is_backup = true;

    await designBackup.$save();
    design.geometry.backup_design_id = designBackup.design_id;
    await design.$update();
    await promptDesignBackup(designBackup, unmatchedModules);

    return designBackup;
}

async function promptDesignBackup(designBackup, unmatchedModules) {
    return modalPrompt({
        title: 'Design backup created',
        text: (`
            We have updated our layout algorithms, updating your previous design may result
            in slight changes to the module layout (${unmatchedModules.length} modules may have moved).
            <p><p>
            To ensure you don't lose any data,  we have created a read only backup of the design:
            ${designBackup.description}
        `),
    });
}

export async function modalPrompt({ title, text, icon = 'fa-exclamation-triangle', ...modalOptions } = {}) {
    const modalInstance = $modal.open({
        templateUrl: require('helioscope/app/designer/partials/modal-prompt.html'),
        size: 'md',
        controller() {
            this.title = title;
            this.text = $sce.trustAsHtml(text);
            this.icon = icon;
        },
        controllerAs: 'ctrl',
        ...modalOptions,
    });

    return modalInstance.result
        .then(x => x)
        .catch(reason => reason); // modal dismissals will reject, so this causes the rejection to be swallowed
}
