import { FieldSegment } from 'helioscope/app/designer/field_segment/FieldSegment';
import { Keepout } from 'helioscope/app/designer/keepout/Keepout';
import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { $http } from 'helioscope/app/utilities/ng';
import { RelationalBase } from 'helioscope/app/relational';
import { fetchJSON, postJSON } from 'helioscope/app/utilities/relational';
import { MapConfig } from '../MapConfig';

export class BulkObjects extends RelationalBase {
    constructor(objects) {
        super();
        this.objects = objects;
        this.design_id = objects[0].design_id;
        this.length = objects.length;

        this.field_segments = objects.filter(object => object instanceof FieldSegment);
        this.keepouts = objects.filter(object => object instanceof Keepout);
        this.entity_premades = objects.filter(object => object instanceof EntityPremade);
    }

    _generatePayload() {
        return {
            field_segments: this.field_segments,
            keepouts: this.keepouts,
            entity_premades: this.entity_premades,
        };
    }

    deregisterObjects() {
        this.objects.forEach(object => {
            object.$deregister();
        });
    }

    $delete() {
        return fetchJSON(
            `/api/bulk_operations/${this.design_id}`,
            { body: this._generatePayload(), method: 'DELETE' }
        )
            .then(() => {
                this.deregisterObjects();
                return this;
            })
            .catch(error => Promise.reject(error));
    }

    initializeObjects(createdObjects) {
        this.field_segments.forEach((field_segment, index) => {
            field_segment.$initialize(createdObjects.field_segments[index]);
        });
        this.keepouts.forEach((keepout, index) => {
            keepout.$initialize(createdObjects.keepouts[index]);
        });
        this.entity_premades.forEach((entity_premade, index) => {
            entity_premade.$initialize(createdObjects.entity_premades[index]);
        });
    }

    $save() {
        return postJSON(
            `/api/bulk_operations/${this.design_id}`,
            this._generatePayload()
        )
            .then((response) => {
                this.initializeObjects(response.data)
                return this;
            })
            .catch(error => Promise.reject(error));
    }

    handleDeleteOnSuccess(dispatcher) {
        this.field_segments.forEach(field_segment => {
            dispatcher.designManager.removeFieldSegment(field_segment, this.objects);
        });

        this.keepouts.forEach(keepout => {
            dispatcher.designManager.removeKeepout(keepout, this.objects);
            dispatcher.publish('entityKeepoutsChanged', { deleted: keepout });
        });

        this.entity_premades.forEach(entity_premade => {
            dispatcher.designManager.removePremade(entity_premade);
            dispatcher.publish('entityPremadesChanged', { deleted: entity_premade });
        });

        dispatcher.deselectEntity();
    }

    handleDeleteOnError(dispatcher) {
        this.keepouts.forEach(keepout => {
            dispatcher.publish('entityKeepoutsChanged', { error: keepout });
        });
    }

    handleCreatePreflight(dispatcher) {
        this.field_segments.forEach(field_segment => {
            delete field_segment.field_segment_id;
            dispatcher.designManager.fieldSegmentCallback(field_segment, 'geometry.path');
            dispatcher.renderer.renderFieldSegment(field_segment, { renderOptions: MapConfig.fieldSegment.base });
        });

        this.keepouts.forEach(keepout => {
            delete keepout.keepout_id;
            dispatcher.designManager.keepoutCallback(keepout, 'geometry.path');
            dispatcher.renderer.renderKeepout(keepout, { renderOptions: MapConfig.keepout.base });
        });

        this.entity_premades.forEach(entity_premade => {
            delete entity_premade.entity_premade_id;
            dispatcher.designManager.premadeCallback(entity_premade, 'geometry');
            dispatcher.renderer.renderPremade(entity_premade, { renderOptions: {} });
        });
    }

    handleCreateOnSuccess(dispatcher) {
        dispatcher.deselectEntity();
        this.field_segments.forEach(field_segment => {
            dispatcher.selectEntity(field_segment, {}, true);
        });
        this.keepouts.forEach(keepout => {
            dispatcher.publish('entityKeepoutsChanged', { created: keepout, isClone: true });
            dispatcher.selectEntity(keepout, {}, true);
        });

        this.entity_premades.forEach(entity_premade => {
            dispatcher.publish('entityPremadesChanged', { created: entity_premade, isClone: true });
            dispatcher.selectEntity(entity_premade, {}, true);
        });
    }

    handleCreateOnError(dispatcher) {
        this.field_segments.forEach(field_segment => {
            dispatcher.designManager.removeFieldSegment(field_segment);
        });

        this.keepouts.forEach(keepout => {
            dispatcher.designManager.removeKeepout(keepout);
            dispatcher.publish('entityKeepoutsChanged', { error: keepout });
        });

        this.entity_premades.forEach(entity_premade => {
            dispatcher.designManager.removePremade(entity_premade);
        });
    }

}
