import moment from 'moment';
import { get } from 'lodash';

import { helioscopeConfig } from 'helioscope/app/config';

import { toObject } from 'helioscope/app/utilities/containers';
import { $sce } from 'helioscope/app/utilities/ng';
import { fetchJSON } from 'helioscope/app/utilities/relational/resource';

import { RelationalBase } from 'helioscope/app/relational';


export class Subscription extends RelationalBase {
    static relationName = 'Subscription';

    constructor(data) {
        super(data);

        if (this.subscribers == null) {
            this.subscribers = [];  // protection for users who
        }
    }

    isActive() {
        return (
            this.current_period_end != null && this.status !== 'canceled' && this.current_period_end.isAfter(moment())
        );
    }

    embedUrl(invoice) {
        return $sce.trustAsResourceUrl(`${Subscription.urlRoot()}${this.external_id}/invoice/${invoice.external_id}`);
    }

    pdfUrl(invoice) {
        return $sce.trustAsResourceUrl(`${this.embedUrl(invoice)}/pdf`);
    }

    static urlRoot() {
        return `${helioscopeConfig.url_root}/api/subscriptions/`;
    }

    removeSubscriber(subscriber) {
        const idx = this.subscribers.indexOf(subscriber);
        if (idx !== -1) {
            this.subscribers.splice(idx, 1);
        }
    }

    hasUser(user) {
        const email = user.email.toLowerCase();
        return _.find(this.subscribers, (sub) => sub.email.toLowerCase() === email);
    }

    addSubscriber(user) {
        if (!user) {
            return;
        }

        if (!this.hasUser(user)) {
            this.subscribers.push(user);
        }
    }


    now() {
        return this.test_clock_frozen_time ? moment(this.test_clock_frozen_time) : moment();
    }

    cost() {
        return calculateSubscriptionCost(this.subscribers.length, this.plan, this.discount);
    }

    get canceled() {
        return this.status === 'canceled' || this.status === 'canceled_pending';
    }

    get unpaidInvoices() {
        if (this.invoices != null) {
            return this.invoices.filter(inv => !inv.paid && !inv.closed).length;
        }
        return 0;
    }

    get product() {
        if (Number(this.version) !== 2) {
            return 'basic';
        }

        return get(helioscopeConfig, 'product_metadata.pro.v2_stripe_id') === get(this, 'stripe_data.plan.product')
            ? 'pro'
            : 'basic';
    }

    get isV2() {
        return this.version === 2;
    }
}

Subscription.configureRelationships({
    current_period_end: (date) => moment(date),
    extension_end_date: (date) => moment(date),
});

Subscription.createEndpoint('/api/subscriptions/:external_id',
    { external_id: '@external_id' },
    {
        update: {
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

        share: {
            url: '/api/subscriptions/:external_id/share',
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

        updateInvoice: {
            url: '/api/subscriptions/:external_id/invoice/:invoice_id',
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

        updateInvoiceCustomFields: {
            url: '/api/subscriptions/:external_id/invoice/:invoice_id/custom_fields',
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

        createInvoice: {
            url: '/api/subscriptions/:external_id/create_invoice',
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

        // These affect billing
        cancelSubscription: {
            url: '/api/subscriptions/:external_id/cancel',
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

        pay: {
            url: '/api/subscriptions/:external_id/pay',
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

        updatePayment: {
            url: '/api/subscriptions/:external_id/update_payment',
            method: 'PUT',
            isArray: false,
            params: { external_id: '@external_id' },
        },

    },
);

export async function fetchSubscriptionPricing(subscription, seats) {
    const response = await fetchJSON(
        `/api/subscriptions/${subscription.external_id}/price_preview?seats=${seats}`);

    return response.data;
}


let plans = {};
let discounts = {};
let _loaded = false;

export async function loadStripeData() {
    if (_loaded) {
        return { plans, discounts };
    }

    const response = await fetchJSON('/api/plans/');
    discounts = toObject(response.data.discounts, x => x.stripe_id);
    plans = toObject(response.data.plans, x => x.stripe_id);

    _loaded = true;

    return { plans, discounts };
}

export function calculateSubscriptionCost(seats, plan, discount = null) {
    const baseCost = seats * plan.default_amount;

    let discountAmount = 0;
    if (discount) {
        if (discount.type === 'amount') {
            discountAmount = discount.default_amount;
        } else {
            discountAmount = discount.default_amount * baseCost;
        }
    }

    return { baseCost, discountAmount, total: baseCost - discountAmount };
}

export function setStripeData(data) {
    _loaded = true;

    discounts = data.discounts;
    plans = data.plans;
}

export function invoiceStatus(invoice) {
    if (invoice.paid) {
        return 'Paid';
    }
    if (invoice.closed) {
        return 'Canceled';
    }
    return 'Pending';
}
