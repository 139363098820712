/* tslint:disable:variable-name */
import { fill, inRange, merge, range } from 'lodash';
import moment from 'moment';

import { BaseClass, createAsyncSaver, defaults, ensureProperty, ReduxEndpoint } from 'reports/utils/api';
import { DayOfWeek, Hour, Month, monthsInRange } from 'reports/utils/time';

import { ITier, IParameterDefinition, ParamValueType } from 'reports/modules/financials/params';
import { Seasons } from 'reports/modules/financials/components/ParamRatesSeasonal';

import { schema } from './schema';
import { IAPIQueryOpts } from './types';
import * as team from './team';
import * as user from './user';

export interface IRateSchedule {
    rate_tiers: ITier[];
    name?: string;
}

export interface IEnergyRateSchedule extends IRateSchedule {
    net_meter_flat: number;
}

export interface IScheduleAssignment {
    days: DayOfWeek[] | null; // days of the week this schedule applies
    table: number[]; // array of which rate schedule applies when, length should be 12 (months) * 24 (hours)
}

export interface IDemandConfig {
    rate_schedules: IRateSchedule[];
    tables: IScheduleAssignment[];
}

export interface IEnergyConfig {
    rate_schedules: IEnergyRateSchedule[];
    tables: IScheduleAssignment[];
    net_meter_options: NetMeterType | null;
}

export type IInterval = [Hour, Hour]; // has inclusive start, exclusive end: [12, 19] starts at 12:00, ends at 18:59

export interface ITOUPeriod {
    name: string;
    intervals: IInterval[];
}

export interface ISeasonalRateSchedule extends IRateSchedule {
    tou?: ITOUPeriod; // when in the day this schedule applies
    net_meter_flat?: number; // TBRemoved from IEnergyConfig?
}

export interface ISeasonConfig {
    name: Seasons;
    rate_schedules: ISeasonalRateSchedule[];
    months: {
        start: Month;
        end: Month;
    }; // inclusive months this schedule is valid (start = Apr, end = Sept => Apr 1 - Sept 30)
}

export interface ISeasonalInput {
    business_days: DayOfWeek[] | null;
    seasons: ISeasonConfig[];
}

export interface IFixedCharge {
    type: 'flat' | 'kwh' | 'day' | 'percent';
    name: string;
    amount: number;
}

export interface IMinimumBill {
    type: 'flat' | 'day';
    active: boolean;
    amount: number;
}

export interface IUtilityRateData {
    demand_rates: IDemandConfig | null;
    energy_rates: IEnergyConfig;
    fixed_rates: IFixedCharge[] | null;
    minimum_bill: IMinimumBill | null;
    seasonal_input: ISeasonalInput | null;
}

export type AnnualTrueUpType = {
    net_export_rate_kwh: number;
};

export type NetMeterType = {
    net_meter_type: 'flat_rate';
    nonbypassable_per_kwh: number;
    annual_true_up: AnnualTrueUpType | null;
};

export interface IFixedConfig extends IParameterDefinition<IFixedCharge[]> {
    type: ParamValueType.FixedCharges;
}

export interface IMinimumBillConfig extends IParameterDefinition<IMinimumBill> {
    type: ParamValueType.MinimumBill;
}

export type RateConfig = IDemandConfig | IEnergyConfig;

export type RateSchedule = IEnergyRateSchedule | IRateSchedule;

// Populate table for given TOU period
function setScheduleAssignment(months: Month[], scheduleId: number, table: number[], tou?: ITOUPeriod) {
    const intervals = tou ? tou.intervals : [[0, 24]];

    function isHourInSchedule(hourIdx) {
        for (const [start, end] of intervals) {
            const nonZeroEnd = end === 0 ? 24 : end;
            if (inRange(hourIdx, start, nonZeroEnd)) {
                return true;
            }
        }
        return false;
    }

    for (const month of months) {
        for (let hourIdx = 0; hourIdx < 24; hourIdx += 1) {
            const tableIdx = 24 * (month - 1) + hourIdx;

            const hourInSchedule = !tou || isHourInSchedule(hourIdx);

            if (hourInSchedule) {
                table[tableIdx] = scheduleId;
            }
        }
    }
}

class UtilityRate extends BaseClass {
    utility_rate_id: number;

    team_id: number;
    team?: team.Team;

    description: string;
    apply_date: moment.Moment;
    data: IUtilityRateData;
    public: boolean;

    team_settings: UtilityRateTeam;

    created?: moment.Moment;
    last_modified?: moment.Moment;

    creator_id?: number;
    creator?: user.User;

    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    constructor(data) {
        super(UtilityRate.deserializer(data));
    }

    bookmarked = () => this.team_settings.bookmarked;

    toRateConfig(): IEnergyConfig {
        // return full rate configuration from user seasonal input
        const { seasonal_input: seasonalInput } = this.data;

        if (!seasonalInput) {
            throw Error('No user input for this utility rate');
        }

        const { business_days: businessDays, seasons } = seasonalInput;

        const aggRateSchedules: IEnergyRateSchedule[] = [];
        const tables: IScheduleAssignment[] = [
            {
                // Default schedule table, incl. Weekend + holidays if business days are specified
                days: null,
                table: fill(range(12 * 24), 1),
            },
        ];

        // Append schedule assignment for business days
        if (businessDays) {
            tables.push({
                days: [...businessDays],
                table: fill(range(12 * 24), 1),
            });
        }

        for (const season of seasons) {
            const { months, rate_schedules: rateSchedules } = season;

            const monthsInSeason = monthsInRange(months.start, months.end);

            for (let scheduleIdx = 0; scheduleIdx < rateSchedules.length; scheduleIdx += 1) {
                const schedule = rateSchedules[scheduleIdx];
                const scheduleId = aggRateSchedules.length + 1;

                // Aggregate rate schedules
                aggRateSchedules.push({
                    name: `${season.name}${schedule.tou ? ' ' + schedule.tou.name : ''}`,
                    rate_tiers: schedule.rate_tiers,
                    net_meter_flat: 0.0,
                });

                // Update business days table
                if (businessDays && businessDays.length) {
                    setScheduleAssignment(monthsInSeason, scheduleId, tables[1].table, schedule.tou);
                }

                // Update default (aka weekend & holidays) table with off-peak schedules only
                // (assume last schedule of each season is off-peak)
                if (scheduleIdx === rateSchedules.length - 1) {
                    setScheduleAssignment(monthsInSeason, scheduleId, tables[0].table);
                }
            }
        }

        return {
            tables,
            rate_schedules: aggRateSchedules,
            net_meter_options: null,
        };
    }

    static deserializer = BaseClass.getDeserializer({
        apply_date: (x) => moment(x),
        created: (x) => moment(x),
        last_modified: (x) => moment(x),
        team_settings: ensureProperty<UtilityRateTeam>(
            (teamData, { utility_rate_id }) => new UtilityRateTeam({ utility_rate_id, ...teamData }),
        ),
    });
}

class UtilityRateTeam extends BaseClass {
    utility_rate_id: number;
    team_id?: number;
    bookmarked: boolean;

    constructor(data) {
        super(UtilityRateTeam.deserializer(data));
    }

    static deserializer = defaults({
        bookmarked: false,
    });
}

const schemaObj = schema.addObject(UtilityRate, 'utility_rate', {
    relationships: {
        creator: { schema: user.schemaObj },
        last_modified_by_user: { schema: user.schemaObj },
        team: { schema: team.schemaObj },
    },
});

export interface IUtilityRateForm {
    team_id?: number;

    description: string;
    apply_date?: moment.Moment;
    data: any;
    public?: boolean;
}

interface IUtilityRateQuery extends IAPIQueryOpts {
    description?: string;
    team_id?: number;
    limit?: number;
    detail?: boolean;
}

const endpoint = ReduxEndpoint.fromSchema('/api/utility_rates/', schemaObj, {
    deepSelect: {
        creator: true,
        last_modified_by_user: true,
        team: true,
    },
});

const _api = {
    create: endpoint.post<IUtilityRateForm>(),
    save: endpoint.put('{utility_rate_id}'),
    _convert: (obj) =>
        merge({}, obj, {
            apply_date: moment(obj.apply_date).local().format('YYYY-MM-DD') + 'T00:00:00.000',
        }),
};

const api = {
    index: endpoint.index<IUtilityRateQuery>(),
    get: endpoint.get<{ utility_rate_id: number | string }>('{utility_rate_id}'),
    create: (obj) => _api.create(_api._convert(obj)),
    save: (obj) => _api.save(_api._convert(obj)),
    delete: endpoint.delete('{utility_rate_id}'),

    patchTeamSettings: endpoint.patch<Partial<UtilityRateTeam>, Partial<UtilityRateTeam>>(
        '{utility_rate_id}/team_settings',
        {
            onSuccess: (rawData) =>
                schemaObj.dataLoaded({
                    utility_rate_id: rawData.utility_rate_id,
                    team_settings: rawData,
                }),
        },
    ),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

const saver = createAsyncSaver(schemaObj, api.save, -1);

export { UtilityRate, schemaObj, api, saver, selectors };
