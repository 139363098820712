import * as THREE from 'three';

import { removeInstance, makeWireGeometry } from './GLHelpers';
import { PrimitiveMeshStroke } from './Primitives';

export class InfoHelperGrid {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
    }

    update() {
        // draw axes
        if (!this.majorAxisInstance) {
            const majorAxisMaterial = new THREE.LineBasicMaterial({ color: 0xdddddd, opacity: 1.0, linewidth: 1.0 });

            const material = majorAxisMaterial;
            const geometry = new THREE.BufferGeometry();
            geometry.vertices.push(new THREE.Vector3(-10000, 0, 0.0));
            geometry.vertices.push(new THREE.Vector3(10000, 0, 0.0));
            geometry.vertices.push(new THREE.Vector3(0, -10000, 0.0));
            geometry.vertices.push(new THREE.Vector3(0, 10000, 0.0));

            const instance = new THREE.LineSegments(geometry, material);
            instance.position.set(0, 0, -this.dRenderer.tinyZOffset);
            this.majorAxisInstance = instance;
            this.dRenderer.gridLayer.add(instance);
        }

        // draw grid
        if (!this.minorGridInstance) {
            const minorGridMaterial = new THREE.LineBasicMaterial({ color: 0x666666, opacity: 1.0, linewidth: 1.0 });

            const gridSpacing = 10.0;
            const material = minorGridMaterial;
            const geometry = new THREE.BufferGeometry();
            for (let i = -1000; i <= 1000; i++) {
                if (i === 0) {
                    continue;
                }

                geometry.vertices.push(new THREE.Vector3(-10000, i * gridSpacing, 0.0));
                geometry.vertices.push(new THREE.Vector3(10000, i * gridSpacing, 0.0));
                geometry.vertices.push(new THREE.Vector3(i * gridSpacing, -10000, 0.0));
                geometry.vertices.push(new THREE.Vector3(i * gridSpacing, 10000, 0.0));
            }

            const instance = new THREE.LineSegments(geometry, material);
            instance.position.set(0, 0, -this.dRenderer.tinyZOffset);
            this.minorGridInstance = instance;
            this.dRenderer.gridLayer.add(instance);
        }
    }

    clear() {
        this.majorAxisInstance = removeInstance(this.majorAxisInstance);
        this.minorGridInstance = removeInstance(this.minorGridInstance);
    }
}

export class InfoHelperAxisWidget {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
    }

    update() {
        if (!this.widgetPrimitive) {
            const geometry = makeWireGeometry([
                new THREE.Vector3(0, -2, 0),
                new THREE.Vector3(-8, 12, 0),
                new THREE.Vector3(0, -2, 0),
                new THREE.Vector3(8, 12, 0),
                new THREE.Vector3(-4, -12, 0),
                new THREE.Vector3(-4, -4, 0),
                new THREE.Vector3(4, -12, 0),
                new THREE.Vector3(4, -4, 0),
                new THREE.Vector3(-4, -12, 0),
                new THREE.Vector3(4, -4, 0),
            ]);
            const material = this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire');
            const options = {
                geometry,
                material,
                screenSpace: true,
                strokeWeight: 2.0,
                strokeOpacity: 1.0,
                strokeColor: '#44ff44',
                scene: this.dRenderer.uiWidgetLayer,
            };
            this.widgetPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, options);
        }

        const rightOffset = 54;
        const bottomOffset = 96;
        this.widgetPrimitive.setRenderPosition(new THREE.Vector3(
            this.dRenderer.clientSize.x - rightOffset,
            this.dRenderer.clientSize.y - bottomOffset,
            0));

        const rot = new THREE.Euler(this.dRenderer.cameraTheta, 0, this.dRenderer.cameraPhi);
        this.widgetPrimitive.setRenderRotation(rot);
    }

    clear() {
        if (this.widgetPrimitive) this.widgetPrimitive.clearInstances();
    }
}
