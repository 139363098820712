import { PubSub } from 'helioscope/app/designer/events';
import { $http, $rootScope } from 'helioscope/app/utilities/ng';
import * as pusher from 'helioscope/app/utilities/pusher';
import { v4 as uuidv4 } from 'uuid';
import { Messager } from 'helioscope/app/utilities/ng';

export class SimilarObstructionDetectionHelper extends PubSub {
    constructor(dispatcher) {
        super();
        this.dispatcher = dispatcher;
        this.channelID = null;
    }

    createChannelID() {
        this.channelID = uuidv4()
    }

    async startSOD(keepoutId) {
        if (!this.channelID) {
            this.createChannelID();
        }

        const apiUrl = '/api/keepouts/detect_similar_obstructions';
        const asyncResponse = await $http.post(apiUrl, {
            pusher_channel: this.channelID,
            keepout_id: keepoutId,
            design_id: this.dispatcher.design.design_id,
            imagery_source: this.dispatcher.renderer.tileLayer.id,
        });


        const aggregatedResult = {
            detections: [],
            scores: []
        };

        // TODO: Handle showing these notifications elsewhere - will implement in HEL-3112
        const notification = Messager.load('Starting SOD');

        notification.progress({ icon: 'fa fa-spinner fa-spin', text: '<b>Similar Obstruction Detection in progress...</b>' });

        pusher.promiseFromChannel(this.channelID, (data) => {
            if (data.detections && Array.isArray(data.detections)) {
                aggregatedResult.detections.push(...data.detections);
            }
            if (data.scores && Array.isArray(data.scores)) {
                aggregatedResult.scores.push(...data.scores);
            }
        })
        .then((successMessage) => {
            notification.success(
                `SOD completed Successfully</a>`,
                { delay: 5000 },
            );
            if (aggregatedResult.detections && aggregatedResult.detections.length) {
                this.dispatcher.publish('SODSuccess', aggregatedResult);
            }
        })
        .catch((failureMessage) => {
            console.log('Pusher failure event:', failureMessage);
            notification.error(
                `SOD failed.. Please try again`,
                { delay: 5000 },
            );
            this.dispatcher.publish('SODFailure', failureMessage);
        });
    }
}
