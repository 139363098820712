import * as THREE from 'three';
import { RendererOptions } from '../RendererOptions';
import { drawHandleCircleFill, drawHandleCircleStroke } from '../InteractHelpers';
import {
    DragActionImageOverlayMove,
    DragActionImageOverlayEdgeEdit,
    DragActionImageOverlayVertexEdit,
    DragActionImageOverlayRotationEdit,
} from './InteractOverlay';
import { getFourPoints } from './OverlayHelpers';

export class WidgetImageOverlayCollection {
    constructor(renderer, overlay) {
        this.renderer = renderer;
        this.overlay = overlay;
    }

    createWidget(options) {
        this.renderableWidgets = [];
        if (options.dragHandles) {
            const widget = new WidgetImageOverlayDragHandle(this.renderer, this.overlay);
            widget.createWidget();
            this.renderableWidgets.push(widget);
        }

        if (options.edgeHandles) {
            for (let i = 0; i < 4; i++) {
                const widget = new WidgetImageOverlayEdgeHandle(this.renderer, this.overlay, i);
                widget.createWidget();
                this.renderableWidgets.push(widget);
            }
        }

        if (options.vertexHandles) {
            for (let i = 0; i < 4; i++) {
                const widget = new WidgetImageOverlayVertexHandle(this.renderer, this.overlay, i);
                widget.createWidget();
                this.renderableWidgets.push(widget);
            }
        }

        if (options.rotationHandle) {
            const widget = new WidgetImageOverlayRotationHandle(this.renderer, this.overlay);
            widget.createWidget();
            this.renderableWidgets.push(widget);
        }
        const preframeFn = () => { this.updateWidget(); };
        this.removeUpdate = this.renderer.registerPreFrameCallback(preframeFn, true);
    }

    clearWidgets() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.clearWidget();
            }
            this.renderableWidgets = null;
        }

        if (this.removeUpdate) {
            this.removeUpdate();
            this.removeUpdate = null;
        }
    }

    updateWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.updateWidget(this.renderer.cameraProjectionMatrix);
            }
        }
    }
}

export class WidgetImageOverlayDragHandle {
    constructor(renderer, overlay) {
        this.renderer = renderer;
        this.overlay = overlay;
    }

    createWidget() {
        const selectionData = { widgetObject: this };
        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        this.midpoint = new THREE.Vector3((fourPoints[0].x + fourPoints[1].x + fourPoints[2].x + fourPoints[3].x) / 4.0,
            (fourPoints[0].y + fourPoints[1].y + fourPoints[2].y + fourPoints[3].y) / 4.0, 0);
        const mtx = new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, this.midpoint);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.fillPrimitive = null;
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayMove(
                this.renderer, this.overlay, event));
        return true;
    }
}

export class WidgetImageOverlayEdgeHandle {
    constructor(renderer, overlay, index) {
        this.renderer = renderer;
        this.overlay = overlay;
        this.index = index;
    }

    createWidget() {
        const selectionData = { widgetObject: this };
        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });

        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        const corner = (new THREE.Vector3()).addVectors(fourPoints[this.index], fourPoints[(this.index + 1) % 4])
            .multiplyScalar(0.5);

        const mtx = new THREE.Matrix4();

        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, corner);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }


    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.fillPrimitive = null;
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayEdgeEdit(
                this.renderer, this.overlay, this.index, event));
        return true;
    }
}

export class WidgetImageOverlayVertexHandle {
    constructor(renderer, overlay, index) {
        this.renderer = renderer;
        this.overlay = overlay;
        this.index = index;
    }

    createWidget() {
        const selectionData = { widgetObject: this };
        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });

        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        const mtx = new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, fourPoints[this.index]);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.fillPrimitive = null;
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayVertexEdit(
                this.renderer, this.overlay, this.index, event));
        return true;
    }
}

export class WidgetImageOverlayRotationHandle {
    constructor(renderer, overlay) {
        this.renderer = renderer;
        this.overlay = overlay;
    }

    createWidget() {
        const selectionData = { widgetObject: this };
        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });

        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        const mtx = new THREE.Matrix4();

        // calculate rectangle midpoint
        const rectangleMidPoint = new THREE.Vector3(
            (fourPoints[0].x + fourPoints[1].x + fourPoints[2].x + fourPoints[3].x) / 4.0,
            (fourPoints[0].y + fourPoints[1].y + fourPoints[2].y + fourPoints[3].y) / 4.0,
        );
        // calculate side midpoint

        const sideMidPoint = new THREE.Vector3(
            (fourPoints[1].x + fourPoints[2].x) / 2.0,
            (fourPoints[1].y + fourPoints[2].y) / 2.0,
        );
        // calculate new pos
        const rectMidToSideMidVector = new THREE.Vector3(
            sideMidPoint.x - rectangleMidPoint.x,
            sideMidPoint.y - rectangleMidPoint.y,
        );

        const pos = new THREE.Vector3(
            sideMidPoint.x + rectMidToSideMidVector.x / 2.0,
            sideMidPoint.y + rectMidToSideMidVector.y / 2.0,
        );

        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, pos);

        // need to somehow draw a line from the position to the center of the image.
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.fillPrimitive = null;
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayRotationEdit(
                this.renderer, this.overlay, event));
        return true;
    }
}
