import { get } from 'lodash';

import { FieldSegment } from './FieldSegment';
import { MapConfig } from '../MapConfig';
import { moduleCache } from './racking/caching';

import { $log, Messager, $filter, $state, $q, $rootScope } from 'helioscope/app/utilities/ng';
import { createUniqueDescription } from 'helioscope/app/utilities/helpers';
import * as Geometry from 'helioscope/app/utilities/geometry';
import * as analytics from 'helioscope/app/utilities/analytics';
import { lidarEnabled } from 'helioscope/app/utilities/lidar/util';


export function checkManualModuleOverlap(fs, module) {
    const transforms = fs.layoutEngine().rackingSpaceTransforms();
    const moduleLookup = moduleCache.getRackCache(fs.getRacks(), transforms);
    const results = moduleLookup.findByBox(module.path[0], module.path[2]);
    for (const i of results) {
        if (i.module.manual) return true;
    }

    return false;
}

/**
 * For a given location, returns { adjustedLocation, removedModule }, where adjustedLocation matches
 * a previously-removed location
 * If the point did not touch a removed module, then { location, module } is returned
 * This ensures that toggling back a removed module removed the old location
 */
export function findOverlappingModule(fieldSegment, location) {
    // query layout for module
    const layoutEngine = fieldSegment.layoutEngine();
    const racking = fieldSegment.getRacks();
    const removedModuleLocations = fieldSegment.geometry.removed_module_locations;
    const removedModuleSurfaceLocations = removedModuleLocations.map(pt => fieldSegment.pointOnSurface(pt));
    const args = {
        findRemoved: true,
        removedModuleLocations: removedModuleSurfaceLocations,
    };

    const moduleQuery = layoutEngine.findModuleIntersections(racking, location, args);

    if (!moduleQuery) {
        return { location, module: null, removed: false };
    }

    const { module, removed, removedModuleLocationIdx } = moduleQuery;
    if (removed === false) {
        return { location, module, removed };
    }

    let finalLocation = location;
    if (removedModuleLocationIdx === -1) {
        $log.error(`Can't remove location ${location} from the removed_module_locations list`);
    } else {
        finalLocation = removedModuleLocations[removedModuleLocationIdx];
    }

    return { location: finalLocation, module, removed };
}

export function getToggleModuleDeltaMulti(fieldSegment, locations) {
    const oldVal = (fieldSegment.geometry.removed_module_locations || []).slice();

    // query layout for module
    const addLocations = [];
    const removeLocations = [];

    for (const location of locations) {
        const { location: adjustedLocation, module, removed } = findOverlappingModule(fieldSegment, location);
        if (module) {
            if (removed) removeLocations.push(adjustedLocation);
            else addLocations.push(adjustedLocation);
        }
    }

    // no changes
    if (addLocations.length === 0 && removeLocations.length === 0) {
        return null;
    }

    const newVal = (_.difference(oldVal, removeLocations)).concat(addLocations);

    return { oldVal, newVal };
}

export function getToggleModuleDelta(fieldSegment, location) {
    const oldVal = (fieldSegment.geometry.removed_module_locations || []).slice();
    let newVal = null;

    // query layout for module
    const { location: adjustedLocation, module, removed } = findOverlappingModule(fieldSegment, location);

    // no module was clicked. bail.
    if (!module) {
        return {};
    }

    if (removed) {
        newVal = _.difference(oldVal, [adjustedLocation]);
    } else {
        newVal = oldVal.concat([adjustedLocation]);
    }

    return { module, oldVal, newVal };
}

export function createFieldSegmentCopy(fieldSegment) {
    const fieldSegments = fieldSegment.design.field_segments;
    const taken = fieldSegments.map((fs) => fs.description);
    const fsCopy = new FieldSegment(_.extend({}, fieldSegment, {
        field_segment_id: null,
        description: createUniqueDescription(fieldSegment.description, taken),
        racking: (fieldSegment.racking && fieldSegment.racking.manual_modules && fieldSegment.racking.manual_modules.length > 0) ? fieldSegment.racking : {},
    }));

    return fsCopy;
}

export class FieldSegmentActionsMixin {
    static FIELDSEGMENT_ACTION_DEBOUNCE = 1000;

    constructor(dispatcher, fieldSegment) {
        this.fieldSegment = fieldSegment;
        this.dispatcher = dispatcher;
    }

    openDetail(fieldSegment) {
        $state.go('designer.design.field_segments.detail', { field_segment_id: fieldSegment.field_segment_id });
    }

    panTo({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher, select = false } = {}) {
        dispatcher.renderer.zoom(fieldSegment);

        if (select === true) {
            this.openDetail(fieldSegment);
        }
    }

    lidarAvailable({ dispatcher = this.dispatcher } = {}) {
        if (!dispatcher.renderer) return false;
        return dispatcher.renderer.lidarAvailable();
    }

    hasLidarAccess() {
        return lidarEnabled();
    }

    fitHeightTiltToLIDAR({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        analytics.track('lidar.fieldSegment.fitHeightTiltToLidar', {
            project_id: this.dispatcher.design.project_id,
            design_id: this.dispatcher.design.design_id,
            team_id: $rootScope.user().team_id,
        });

        const pts = dispatcher.renderer.lidarPoints();
        const { tilt, referenceHeight } = Geometry.fitPhysicalSurfaceHeightTiltToPoints(fieldSegment, pts);

        if (tilt == null || referenceHeight == null) {
            Messager.error(`Could not fit ${fieldSegment}`);
            return;
        }

        if (fieldSegment.rack_type === 'flush') {
            dispatcher.createMultiPropertyChange({
                resource: fieldSegment,
                changes: [
                    {
                        path: 'tilt',
                        oldVal: fieldSegment.tilt,
                        newVal: tilt,
                    },
                    {
                        path: 'reference_height',
                        oldVal: fieldSegment.reference_height,
                        newVal: referenceHeight,
                    },
                ],
                loadMessage: `LIDAR fit (height and tilt) ${fieldSegment.description}`,
                rollbackMessage: `Undo LIDAR fit (height and tilt) ${fieldSegment.description}`,
                mergeable: true,
            });
        } else if (fieldSegment.independentTiltEnabled) {
            dispatcher.createMultiPropertyChange({
                resource: fieldSegment,
                changes: [
                    {
                        path: 'independent_tilt_surface_tilt',
                        oldVal: fieldSegment.independent_tilt_surface_tilt,
                        newVal: tilt,
                    },
                    {
                        path: 'reference_height',
                        oldVal: fieldSegment.reference_height,
                        newVal: referenceHeight,
                    },
                ],
                loadMessage: `LIDAR fit (height and tilt) ${fieldSegment.description}`,
                rollbackMessage: `Undo LIDAR fit (height and tilt) ${fieldSegment.description}`,
                mergeable: true,
            });
        }
    }

    fitTiltToLIDAR({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        analytics.track('lidar.fieldSegment.fitTiltToLidar', {
            project_id: this.dispatcher.design.project_id,
            design_id: this.dispatcher.design.design_id,
            team_id: $rootScope.user().team_id,
        });

        const pts = dispatcher.renderer.lidarPoints();
        const tilt = Geometry.fitPhysicalSurfaceTiltToPoints(fieldSegment, pts);

        if (tilt == null) {
            Messager.error(`Could not fit ${fieldSegment}`);
            return;
        }

        if (fieldSegment.rack_type === 'flush') {
            dispatcher.createSinglePropertyChange({
                resource: fieldSegment,
                path: 'tilt',
                oldVal: fieldSegment.tilt,
                newVal: tilt,
                mergeable: true,
                loadMessage: `LIDAR fit (tilt) ${fieldSegment.description}`,
                rollbackMessage: `Undo LIDAR fit (tilt) ${fieldSegment.description}`,
            });
        }
        else if (fieldSegment.independentTiltEnabled) {
            dispatcher.createSinglePropertyChange({
                resource: fieldSegment,
                path: 'independent_tilt_surface_tilt',
                oldVal: fieldSegment.independent_tilt_surface_tilt,
                newVal: tilt,
                mergeable: true,
                loadMessage: `LIDAR fit (surface tilt) ${fieldSegment.description}`,
                rollbackMessage: `Undo LIDAR fit (surface tilt) ${fieldSegment.description}`,
            });
        }
    }

    fitAllToLIDAR({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        analytics.track('lidar.fieldSegment.fitAllToLidar', {
            project_id: this.dispatcher.design.project_id,
            design_id: this.dispatcher.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        const pts = dispatcher.renderer.lidarPoints();
        const { tilt, azimuth, referenceHeight } = Geometry.fitPhysicalSurfaceToPoints(fieldSegment, pts);

        if (tilt == null || azimuth == null || referenceHeight == null) {
            Messager.error(`Could not fit ${fieldSegment}`);
            return;
        }

        if (fieldSegment.rack_type === 'flush') {
            dispatcher.createMultiPropertyChange({
                resource: fieldSegment,
                changes: [
                    {
                        path: 'tilt',
                        oldVal: fieldSegment.tilt,
                        newVal: tilt,
                    },
                    {
                        path: 'azimuth',
                        oldVal: fieldSegment.azimuth,
                        newVal: azimuth,
                    },
                    {
                        path: 'reference_height',
                        oldVal: fieldSegment.reference_height,
                        newVal: referenceHeight,
                    },
                ],
                loadMessage: `LIDAR fit ${fieldSegment.description}`,
                rollbackMessage: `Undo LIDAR fit ${fieldSegment.description}`,
                mergeable: true,
            });
        } else if (fieldSegment.independentTiltEnabled) {
            dispatcher.createMultiPropertyChange({
                resource: fieldSegment,
                changes: [
                    {
                        path: 'independent_tilt_surface_tilt',
                        oldVal: fieldSegment.independent_tilt_surface_tilt,
                        newVal: tilt,
                    },
                    {
                        path: 'independent_tilt_surface_azimuth',
                        oldVal: fieldSegment.independent_tilt_surface_azimuth,
                        newVal: azimuth,
                    },
                    {
                        path: 'reference_height',
                        oldVal: fieldSegment.reference_height,
                        newVal: referenceHeight,
                    },
                ],
                loadMessage: `LIDAR fit ${fieldSegment.description}`,
                rollbackMessage: `Undo LIDAR fit ${fieldSegment.description}`,
                mergeable: true,
            });
        } else {
            const referenceHeight = Geometry.fitPhysicalSurfaceHeightToPoints(fieldSegment, pts);

            if (referenceHeight !== null) {
                dispatcher.createSinglePropertyChange({
                    resource: fieldSegment,
                    path: 'reference_height',
                    oldVal: fieldSegment.reference_height,
                    newVal: referenceHeight,
                    mergeable: true,
                });
            }
        }
    }


    /**
     * align the module layout to start at a specific point and immediately update the field segment
     * layout
     *
     * need to refactor the MapPolygon module so that click events have an XY Location passed in
     */
    alignModules({
        fieldSegment = this.fieldSegment,
        dispatcher = this.dispatcher,
        location } = {}
    ) {
        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'geometry.layout_start',
            oldVal: fieldSegment.geometry.layout_start,
            newVal: location,
            loadMessage: 'Update Layout Start',
            rollbackMessage: 'Undo Layout Start',
        });

        // update the map now to provide instant feedback
        dispatcher.renderUpdater.updateSurface(fieldSegment, { updateLayout: true });
    }

    /**
     * restore any removed modules on a field segment
     */
    restoreModules({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        const oldVal = (fieldSegment.geometry.removed_module_locations || []).slice();

        if (oldVal.length === 0) {
            return;
        }

        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'geometry.removed_module_locations',
            oldVal,
            newVal: [],
            loadMessage: 'Restore all modules',
            rollbackMessage: 'Undo restore modules',
        });
    }

    /**
     * remove any manual adjustments to a field segment so that auto layout works
     */
    removeManualModules({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        const oldVal = get(fieldSegment, 'geometry.racking.manual_modules', []).slice();

        if (oldVal.length === 0) {
            return;
        }

        dispatcher.createMultiPropertyChange({
            resource: fieldSegment,
            path: 'racking.manual_modules',
            oldVal,
            newVal: [],
            loadMessage: `Remove manual modules from ${fieldSegment.description}`,
            rollbackMessage: `Restore manual modules to ${fieldSegment.description}`,
            mergeable: false,
        });
    }

    /**
     * remove any manual adjustments to a field segment so that auto layout works
     */
    restoreAutolayout({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        const oldRemovedModules = get(fieldSegment, 'geometry.removed_module_locations', []).slice();
        const oldManualModules = get(fieldSegment, 'racking.manual_modules', []).slice();

        dispatcher.createMultiPropertyChange({
            resource: fieldSegment,
            changes: [
                {
                    path: 'racking.manual_modules',
                    oldVal: oldManualModules,
                    newVal: [],
                },
                {
                    path: 'geometry.removed_module_locations',
                    oldVal: oldRemovedModules,
                    newVal: [],
                },
            ],
            loadMessage: `Restore auto layout on ${fieldSegment.description}`,
            rollbackMessage: `Undo layout restore on ${fieldSegment.description}`,
            mergeable: false,
        });
    }

    /**
     * toggle modules at a given location
     *
     * As a performance optimization, it breaks the usual immutable racking structure, and instead modifies
     * the field segment's racking.
     */
    toggleModule({
        fieldSegment = this.fieldSegment,
        dispatcher = this.dispatcher,
        location } = {}
    ) {
        const oldVal = (fieldSegment.geometry.removed_module_locations || []).slice();
        let newVal = null;

        // query layout for module
        const { location: adjustedLocation, module, removed } = findOverlappingModule(fieldSegment, location);

        // no module was clicked. bail.
        if (!module) {
            return false;
        }

        if (module.manual) {
            const newManualModules = fieldSegment.racking.manual_modules.slice();
            newManualModules.splice(module.manual_idx, 1);

            dispatcher.createSinglePropertyChange({
                resource: fieldSegment,
                path: 'racking.manual_modules',
                oldVal: fieldSegment.racking.manual_modules,
                newVal: newManualModules,
                rollbackMessage: 'Undo Remove Module',
                loadMessage: 'Redo Remove Module',
            });
            return true;
        }

        if (removed) {
            if (checkManualModuleOverlap(fieldSegment, module)) return false;
            newVal = _.difference(oldVal, [adjustedLocation]);
        } else {
            newVal = oldVal.concat([adjustedLocation]);
        }

        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'geometry.removed_module_locations',
            oldVal,
            newVal,
            rollbackMessage: 'Undo Toggle Module',
            loadMessage: 'Redo Toggle Module',
        });

        return true;
    }

    /**
     * move a field segment to a new location
     */
    move({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        const notification = Messager.load('Drag the field segment to position it');
        dispatcher.renderer.renderFieldSegment(fieldSegment, { renderOptions: MapConfig.fieldSegment.draggable });

        const moveDeferred = $q.defer();

        dispatcher.subscribeOnce('FieldSegment:dragend', (disp, context) => {
            if (fieldSegment === context.fieldSegment) {
                notification.success('Drag the field segment to position it');

                // hack: force it to render it as selected.
                // if the FS was already selected, it may not get re-rendered correctly
                // real fix involves being able to select the fs before this whole process,
                // but switching FS is very slow right now, and that operation completes
                // _after_ the FS was rendered with the move rendering options.
                dispatcher.renderer.renderFieldSegment(fieldSegment, { renderOptions: MapConfig.fieldSegment.dragEnd });
                this.openDetail(fieldSegment);

                moveDeferred.resolve(context.delta);
            }
        });

        return moveDeferred.promise;
    }

    /**
     * despite the name, this is not a copy-paste operation.
     * it duplicates a field segment, and immediately make it draggable to move to a new location.
    */
    copy({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        const fsCopy = createFieldSegmentCopy(fieldSegment);

        // shift the Field Segment so it is in a new spot
        const bounds = new Geometry.Bounds(...fsCopy.geometry.path);
        const baseShift = new Geometry.Vector(bounds.width / 5, -bounds.height / 5);

        fsCopy.move(baseShift, { shiftPath: true });

        this.createFieldSegment({ fieldSegment: fsCopy, delayFirstUpdate: true })
            .then((fs) => this.move({ fieldSegment: fs, dispatcher }))
            .catch(() => Messager.error('Could not copy the field segment at this time'));
    }

    paste({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher, groundPoint } = {}) {
        const fsCopy = createFieldSegmentCopy(fieldSegment);
        const centroid = fsCopy.centroid();
        const baseShift = new Geometry.Vector(groundPoint.x - centroid.x, groundPoint.y - centroid.y);

        fsCopy.move(baseShift, { shiftPath: true });

        this.createFieldSegment({ fieldSegment: fsCopy, delayFirstUpdate: true })
            .catch(() => Messager.error('Could not paste the field segment at this time'));
    }

    /**
     * align the azimuth of a field segment to a given azimuth value, this is typically called from
     * the dimensions flags/edge context menu, which is why Azimuth is part of the controller
     */
    setAzimuth({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher, azimuth } = {}) {
        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'azimuth', // ensure is always in the range of [0, 360]
            newVal: azimuth % 360,
            oldVal: fieldSegment.azimuth,
            filter: $filter('degrees', 1),
        });
    }

    setSurfaceAzimuth({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher, azimuth } = {}) {
        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'independent_tilt_surface_azimuth', // ensure is always in the range of [0, 360]
            newVal: azimuth % 360,
            oldVal: fieldSegment.independent_tilt_surface_azimuth,
            filter: $filter('degrees', 1),
        });
    }

    setSpanToRise({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher, spanToRise } = {}) {
        const oldSpanToRise = fieldSegment.spanToRise;
        const oldRowSpacing = fieldSegment.row_spacing;

        const newSpanToRise = spanToRise;
        fieldSegment.spanToRise = spanToRise;
        const newRowSpacing = fieldSegment.row_spacing;
        const numFilter = $filter('number');

        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'row_spacing',
            oldVal: oldRowSpacing,
            newVal: newRowSpacing,
            mergeable: false,
            loadMessage: `Set Span-to-Rise to ${numFilter(newSpanToRise, 2)}`,
            rollbackMessage: `Restore Span-to-Rise to ${numFilter(oldSpanToRise, 2)}`,
        });
    }

    setGroundCoverageRatio({
        fieldSegment = this.fieldSegment,
        dispatcher = this.dispatcher,
        groundCoverageRatio } = {}
    ) {
        const oldGCR = fieldSegment.groundCoverageRatio;
        const oldRowSpacing = fieldSegment.row_spacing;

        const newGCR = groundCoverageRatio;
        fieldSegment.groundCoverageRatio = groundCoverageRatio;
        const newRowSpacing = fieldSegment.row_spacing;
        const numFilter = $filter('number');

        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'row_spacing',
            oldVal: oldRowSpacing,
            newVal: newRowSpacing,
            mergeable: false,
            loadMessage: `Set GCR to ${numFilter(newGCR, 2)}`,
            rollbackMessage: `Restore GCR to ${numFilter(oldGCR, 2)}`,
        });
    }

    setToDRowSpacing({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher,
        newVal, oldVal, startTime, endTime } = {}) {
        const dateFilter = $filter('date');
        const formatDate = (dt) => dateFilter(dt, 'hh:mm');

        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'row_spacing',
            oldVal,
            newVal,
            mergeable: false,
            loadMessage: `Set Row Spacing for ${formatDate(startTime)} - ${formatDate(endTime)}`,
            rollbackMessage: `Undo Row Spacing for ${formatDate(startTime)} - ${formatDate(endTime)}`,
        });
    }


    setFieldSegmentsCastShadows({ dispatcher = this.dispatcher, design = this.design, fsCastShadows } = {}) {
        for (const fs of design.field_segments) {
            const oldVal = fs.shadow_caster;
            fs.shadow_caster = fsCastShadows;
            // schedule the update on the stateHandler directly because not in undo stack (until multichange)
            dispatcher.stateHandler.updateQueue.schedule(fs);
            dispatcher.designManager.fieldSegmentCallback(fs, 'shadow_caster', fsCastShadows, oldVal);
        }
    }

    changeConfig({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher, delayFirstUpdate = false } = {}) {
        let firstUpdate = true;

        return {
            create: {
                text: `Create Field Segment: ${fieldSegment}`,
                preflight: (fs) => {
                    delete fieldSegment.field_segment_id;

                    dispatcher.designManager.fieldSegmentCallback(fieldSegment, 'geometry.path');
                    dispatcher.renderer.renderFieldSegment(fs, { renderOptions: MapConfig.fieldSegment.base });
                    // ensure the array layout runs and FS metadata is updated
                    dispatcher.renderUpdater.updateArray();
                },
                onSuccess: (fs) => {
                    Messager.success(`Successfully created ${fs.description}`);
                    if (delayFirstUpdate === false || firstUpdate === false) {
                        // hack to signal new Field Segment
                        this.openDetail(fs);
                    }
                    firstUpdate = false;
                },
                onError: (err) => {
                    Messager.error(`Error creating ${fieldSegment}`);
                    dispatcher.designManager.removeFieldSegment(fieldSegment);
                    $log.warn(err);
                },
            },
            delete: {
                text: `Remove Field Segment: ${fieldSegment}`,
                onSuccess: (fs) => {
                    Messager.success(`Successfully deleted ${fs.description}`);
                    $state.go('designer.design.field_segments');
                    dispatcher.designManager.removeFieldSegment(fs);
                },
                onError: (err) => {
                    Messager.error(`Error deleting ${fieldSegment}`);
                    $log.warn(err);
                },
            },
        };
    }

    deleteFieldSegment({ fieldSegment = this.fieldSegment, dispatcher = this.dispatcher } = {}) {
        return dispatcher.stateHandler.deleteObject(fieldSegment, this.changeConfig({ fieldSegment, dispatcher }));
    }

    /**
     * create a field segment and add it to the queue, delayFirstUpdate is a hack for when cloning
     * eliminate early setup until after the first move
     */
    createFieldSegment({ fieldSegment, dispatcher = this.dispatcher, delayFirstUpdate = false } = {}) {
        return dispatcher.stateHandler.createObject(
            fieldSegment,
            this.changeConfig({ fieldSegment, dispatcher, delayFirstUpdate })
        );
    }
}

export const fieldSegmentActions = new FieldSegmentActionsMixin();

export const FIELD_SEGMENT_MAP_ACTIONS = {
    'FieldSegment:click': (dispatcher, { fieldSegment }) => {
        fieldSegmentActions.openDetail(fieldSegment);
    },

    'FieldSegment:updatePath': (dispatcher, { fieldSegment, oldPath, newPath }) => {
        let action;

        const cleanedPath = Geometry.correctOrientation(newPath);

        if (cleanedPath.length === oldPath.length) {
            action = 'path change on';
        } else if (cleanedPath.length > oldPath.length) {
            action = 'add point to';
        } else {
            action = 'remove point from';
        }

        dispatcher.createSinglePropertyChange({
            resource: fieldSegment,
            path: 'geometry.path',
            oldVal: oldPath,
            newVal: cleanedPath,
            mergeable: true,
            loadMessage: `Redo ${action} ${fieldSegment}`,
            rollbackMessage: `Undo ${action} ${fieldSegment}`,
        });
    },

    'FieldSegment:dragend': (dispatcher, { fieldSegment, oldPath, newPath, delta }) => {
        const oldGeometry = _.assign({}, fieldSegment.geometry, { path: oldPath });
        fieldSegment.move(delta, { shiftLocation: false });
        const newGeometry = _.assign({}, fieldSegment.geometry, { path: newPath });

        const changes = [{
            path: 'geometry',
            oldVal: oldGeometry,
            newVal: newGeometry,
        }];

        if (fieldSegment.racking && fieldSegment.racking.manual_modules) {
            const newVal = fieldSegment.racking.manual_modules.slice().map(i => _.assign({}, i, {
                top_left: {
                    x: i.top_left.x + delta.x,
                    y: i.top_left.y + delta.y,
                },
            }));

            changes.push({
                path: 'racking.manual_modules',
                oldVal: fieldSegment.racking.manual_modules,
                newVal,
            });
        }

        dispatcher.createMultiPropertyChange({
            resource: fieldSegment,
            changes,
            mergeable: false,
            loadMessage: `Move ${fieldSegment}`,
            rollbackMessage: `Undo ${fieldSegment} move`,
        });
    },

    /**
     * need to defer hover rendering, because otherwise it can get in the way of click detection
     * also, check for editable as a hack to see if the field segment is already
     * selected
     */
    'FieldSegment:mouseover': (dispatcher, { fieldSegment, editable }) => {
        if (!editable) {
            _.defer(() => { dispatcher.renderer.highlightFieldSegment(fieldSegment, true); });
        }
    },
    'FieldSegment:mouseout': (dispatcher, { fieldSegment, editable }) => {
        if (!editable) {
            _.defer(() => { dispatcher.renderer.highlightFieldSegment(fieldSegment, false); });
        }
    },
};
