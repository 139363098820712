import { $rootScope } from 'helioscope/app/utilities/ng';
import { betaRootUrlGenerator } from 'helioscope/app/utilities/url';
import { getClassicToBetaCopyTextID } from 'helioscope/app/utilities/helpers';
import * as subDirectives from './subscriptions/directives';

const mod = angular.module('helioscope.users.directives', []);

mod.directive('demoCutoffAlert', () => ({
    restrict: 'E',
    templateUrl: require('helioscope/app/users/partials/demo-banner.html'),
    scope: { user: '=' },
    controller($scope) {
        const subscription = $scope.user.subscription;

        $scope.hasInvoice = !!subscription;
        $scope.activeSubscription = $scope.user.activeSubscription();
        $scope.paymentFail = subscription && subscription.has_failed_payment;
        $scope.isV2 = subscription && subscription.isV2;
        $scope.isExpired = $scope.user.isExpired();
        $scope.timeLeft = $scope.user.current_period_end.fromNow();
    },
}));

mod.directive('betaBannerAlert', () => ({
    restrict: 'E',
    templateUrl: require('helioscope/app/users/partials/beta-banner.html'),
    scope: { user: '=', inSidebar: '=', betaPage: '=' },
    controller($scope) {
        $scope.betaURL = `${betaRootUrlGenerator()}/${$scope.betaPage}`;
        $scope.copyTextID = getClassicToBetaCopyTextID($scope.user);
        $scope.contactUsURL = 'mailto:support@helioscope.com';
    },
}));

mod.directive('subscription', subDirectives.subscription);
// mod.directive('newSubscription', subDirectives.newSubscription);
mod.directive('addUser', subDirectives.addUser);
