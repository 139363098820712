import _ from 'lodash';
import * as THREE from 'three';

import { user } from 'helioscope/app/users';
import {
    makeSurfacePolygonGeometrySolid,
    makeWireGeometry,
    makePhysicalSurfaceSegmentPoints,
    makePhysicalSurfaceGeometrySolid,
    makeMultiColorQuadGeometrySolid,
    makeMultiColorQuadGeometryWire,
} from './GLHelpers';
import {
    WidgetSurfaceCollection,
} from './WidgetSurface';
import {
    PrimitiveVirtualLineSegments,
    PrimitiveMeshStroke,
    PrimitiveMeshFill,
} from './Primitives';

import { THE_GROUND } from 'helioscope/app/designer/field_segment';

export class RenderableSurface {
    constructor(renderer, ps, type) {
        this.renderer = renderer;
        this.surface = ps;
        this.surfaceType = type;
    }

    clearRenderable() {
        this.clearSurface();
        this.clearShadows();
        this.clearSetbacks();
        this.clearWidgets();

        this.renderer.dirtyFrame();
    }

    renderRenderable(options) {
        this.clearRenderable();

        if (this.surface !== THE_GROUND) {
            this.renderSurface(options.surfaceOptions);
        }

        this.renderShadows(options.shadowOptions);
        this.renderSetbacks(options.setbackOptions);

        if (this.renderer.options.enableInterface || this.renderer.options.addCaptions) {
            let dragHandles = false;
            let edgeLabels = false;
            let surfaceLabels = false;

            if (options.surfaceOptions.editable && this.renderer.options.enableEditing) {
                dragHandles = true;
                edgeLabels = true;
            } else {
                if (this.surfaceType === 'FieldSegment' && user.preferences.designer.field_segment_labels) {
                    edgeLabels = true;
                } else if (this.surfaceType === 'Keepout' && user.preferences.designer.keepout_labels) {
                    edgeLabels = true;
                }

                if (this.renderer.options.addCaptions) {
                    edgeLabels = false;
                    if (this.surfaceType === 'FieldSegment') surfaceLabels = true;
                }
            }

            if (dragHandles || edgeLabels || surfaceLabels) {
                this.createWidgets({
                    dragHandles,
                    edgeLabels,
                    surfaceLabels,
                });
            }
        }

        this.renderer.dirtyFrame();
    }

    clearChildRenderables() {
        if (this.rackingRenderable) {
            this.rackingRenderable.clearRenderable();
            this.rackingRenderable = null;
        }
    }

    clearSurface() {
        if (this.visualSolidPrimitive) {
            this.visualSolidPrimitive.clearInstances();
            this.visualSolidPrimitive = null;
        }

        if (this.visualWirePrimitive) {
            this.visualWirePrimitive.clearInstances();
            this.visualWirePrimitive = null;
        }

        if (this.selectionSolidPrimitive) {
            this.selectionSolidPrimitive.clearInstances();
            this.selectionSolidPrimitive = null;
        }

        if (this.selectionWirePrimitive) {
            this.selectionWirePrimitive.clearInstances();
            this.selectionWirePrimitive = null;
        }
    }

    clearShadows() {
        if (this.shadowPrimitives) {
            _.each(this.shadowPrimitives, (prim) => { prim.clearInstances(); });
            this.shadowPrimitives = [];
        }
    }

    clearSetbacks() {
        if (this.setbackPrimitives) {
            _.each(this.setbackPrimitives, (prim) => { prim.clearInstances(); });
            this.setbackPrimitives = [];
        }
    }

    updateSurfaceOptions(options) {
        this.surfaceOptions = options;

        if (this.visualSolidPrimitive) {
            this.visualSolidPrimitive.setRenderOptions(this.surfaceOptions);
            this.renderer.dirtyFrame();
        }

        if (this.visualWirePrimitive) {
            this.visualWirePrimitive.setRenderOptions(this.surfaceOptions);
            this.renderer.dirtyFrame();
        }

        if (this.selectionSolidPrimitive) {
            this.selectionSolidPrimitive.getInteractData().draggableSurface = this.isDraggable(options);
        }

        if (this.selectionWirePrimitive) {
            this.selectionWirePrimitive.getInteractData().draggableSurface = this.isDraggable(options);
        }
    }

    renderSurface(options) {
        this.surfaceOptions = options;

        const scene = this.renderer.physicalSurfaceLayer;
        const solidOffset = new THREE.Vector3(0, 0, -this.renderer.tinyZOffset);
        const renderOrder = this.surfaceType === 'Keepout' ? 110 : 100;

        const wirePoints = makePhysicalSurfaceSegmentPoints(this.surface.geometry);
        const wireGeometry = makeWireGeometry(wirePoints);
        const wireMaterial = this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire');

        const wireOptions = _.assign({
            geometry: wireGeometry,
            material: wireMaterial,
            depthOffset: this.renderer.tinyZOffset,
            renderOrder: renderOrder + 1,
            scene,
        }, this.surfaceOptions);

        this.visualWirePrimitive = this.renderer.renderPrimitive(PrimitiveMeshStroke, wireOptions);

        const solidGeometry = makePhysicalSurfaceGeometrySolid(this.surface.geometry, solidOffset);
        const solidMaterial = this.renderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal');

        const solidOptions = _.assign({
            geometry: solidGeometry,
            material: solidMaterial,
            depthOffset: -this.renderer.tinyZOffset,
            renderOrder,
            scene,
        }, this.surfaceOptions);

        this.visualSolidPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, solidOptions);

        if (this.surfaceOptions.selectionData) {
            const dummyMat = this.renderer.graphicResourceCache.dummyMaterial;

            const selectionData = _.assign(
                { draggableSurface: this.isDraggable(this.surfaceOptions) },
                this.surfaceOptions.selectionData);

            this.selectionWirePrimitive = this.renderer.renderPrimitive(
                PrimitiveVirtualLineSegments,
                {
                    points: wirePoints,
                    material: dummyMat,
                    scene: this.renderer.selectionLayerScene,
                    selectionData,
                });
            this.selectionWirePrimitive.setRenderPosition(new THREE.Vector3(0, 0, this.renderer.tinyZOffset));

            this.selectionSolidPrimitive = this.renderer.renderPrimitive(
                PrimitiveMeshFill,
                {
                    geometry: solidGeometry,
                    material: dummyMat,
                    scene: this.renderer.selectionLayerScene,
                    selectionData,
                });
        }
    }

    renderShadows(options) {
        const ps = this.surface;

        const designScene = this.renderer.dispatcher.design.designScene();
        const paths = designScene.shadowPaths(ps);

        const prims = [];

        if (paths && paths.length) {
            const renderOpts = _.assign({
                geometry: makeSurfacePolygonGeometrySolid(paths),
                material: this.renderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal'),
                depthOffset: 2.0 * this.tinyZOffset,
                scene: this.renderer.physicalSurfaceLayer,
                renderOrder: 140,
            }, options);

            prims.push(this.renderer.renderPrimitive(PrimitiveMeshFill, renderOpts));
        }

        this.shadowPrimitives = prims;
    }

    renderSetbacks(options) {
        const ps = this.surface;

        const designScene = this.renderer.dispatcher.design.designScene();
        const paths = designScene.layoutManager.getExclusionPathsForRenderer(ps);

        const prims = [];

        if (paths) {
            const renderOpts = _.assign({
                geometry: makeSurfacePolygonGeometrySolid(paths),
                material: this.renderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal'),
                scene: this.renderer.physicalSurfaceLayer,
                depthOffset: this.tinyZOffset,
                renderOrder: 130,
            }, options);

            prims.push(this.renderer.renderPrimitive(PrimitiveMeshFill, renderOpts));
        }

        this.setbackPrimitives = prims;
    }

    offsetRenderablePosition(delta) {
        if (this.visualWirePrimitive) {
            this.visualWirePrimitive.setRenderPosition(delta);
            this.renderer.dirtyFrame();
        }

        if (this.visualSolidPrimitive) {
            this.visualSolidPrimitive.setRenderPosition(delta);
            this.renderer.dirtyFrame();
        }
    }

    worldMatrix() {
        if (this.visualWirePrimitive) return this.visualWirePrimitive.worldMatrix();
        if (this.visualSolidPrimitive) return this.visualSolidPrimitive.worldMatrix();
        return null;
    }

    createWidgets(options) {
        this.widgetCollection = new WidgetSurfaceCollection(this.renderer, this.surface, this.surfaceType);
        this.widgetCollection.createWidget(options);
    }

    clearWidgets() {
        if (this.widgetCollection) {
            this.widgetCollection.clearWidget();
            this.widgetCollection = null;
        }
    }

    isDraggable(options) {
        return options.draggable ||
            (this.renderer.dispatcher.getStateFlags().keepoutsDraggable && this.surfaceType === 'Keepout');
    }
}

export class RenderableRacking {
    constructor(renderer, fs) {
        this.renderer = renderer;
        this.fieldSegment = fs;

        this.moduleOptionsMap = new WeakMap();
    }

    clearRenderable() {
        if (this.visualSolidPrimitive) {
            this.visualSolidPrimitive.clearInstances();
            this.visualSolidPrimitive = null;
        }

        if (this.visualWirePrimitive) {
            this.visualWirePrimitive.clearInstances();
            this.visualWirePrimitive = null;
        }
    }

    setRenderableOptions(options) {
        this.projection = options.projection;
    }

    renderRenderable(options, modulesToRender = this.getFsModules()) {
        this.clearRenderable();

        const quadSpecs = [];

        for (const module of modulesToRender) {
            const moduleOptions = _.assign({}, options.base, options.wired,
                this.moduleOptionsMap.get(module));
            quadSpecs.push({ object: module, path: module.path, options: moduleOptions });
        }

        if (quadSpecs.length) {
            const moduleOptions = {
                fillColor: '#ffffff',
                fillOpacity: options.base.fillOpacity,
                strokeColor: '#ffffff',
                strokeOpacity: options.racking.strokeOpacity,
                strokeWeight: this.projection === 'Perspective' ? 10 : options.racking.strokeWeight,
                renderOrder: 120,
            };

            const scene = this.renderer.physicalSurfaceLayer;

            const solidQuads = _.map(quadSpecs, spec => ({ path: spec.path, color: spec.options.fillColor }));
            const solidGeometry = makeMultiColorQuadGeometrySolid(solidQuads);
            const solidMaterial = this.renderer.inlineShaderMaterial(
                'vertexShaderQuad', 'fragmentShaderQuad');

            const solidOptions = _.assign({
                geometry: solidGeometry,
                material: solidMaterial,
                depthOffset: 0,
                scene,
            }, moduleOptions);

            this.visualSolidPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, solidOptions);

            const wireQuads = _.map(quadSpecs, spec => ({ path: spec.path, color: spec.options.strokeColor }));
            const wireGeometry = makeMultiColorQuadGeometryWire(wireQuads);
            const wireMaterial = this.renderer.inlineShaderMaterial(
                'vertexShaderColorWire', 'fragmentShaderWire');

            const wireOptions = _.assign({
                geometry: wireGeometry,
                material: wireMaterial,
                depthOffset: 2.0 * this.renderer.tinyZOffset,
                scene,
            }, moduleOptions);

            this.visualWirePrimitive = this.renderer.renderPrimitive(PrimitiveMeshStroke, wireOptions);
        }
    }

    getFsModules(fieldSegment = this.fieldSegment) {
        let modules = [];

        const racking = fieldSegment.getRacks();
        for (const rack of (racking || [])) {
            modules = modules.concat(rack.getModules());
        }

        return modules;
    }

    setModuleOptions(module, options) {
        // Currently only fillColor and strokeColor gets passed down.
        this.moduleOptionsMap.set(module, options);
    }
}
