import { $log, $state, Messager, $rootScope } from 'helioscope/app/utilities/ng';
import { createUniqueDescription } from 'helioscope/app/utilities/helpers';
import * as Geometry from 'helioscope/app/utilities/geometry';

import * as analytics from 'helioscope/app/utilities/analytics';
import { lidarEnabled } from 'helioscope/app/utilities/lidar/util';

import {
    Bounds,
    correctOrientation,
    fitPhysicalSurfaceHeightToHighestInlierPoint
} from 'helioscope/app/utilities/geometry';
import { MapConfig } from '../MapConfig';

import { Keepout } from './Keepout';
import { BulkObjects } from '../persistence/BulkObjects';

import { bulkChangeConfig } from '../actions';

export function createKeepoutCopy(keepout) {
    const keepouts = keepout.design.keepouts;
    const taken = keepouts.map((k) => k.description);
    const koCopy = new Keepout(_.extend({}, keepout, {
        description: createUniqueDescription(keepout.description, taken),
        keepout_id: null,
    }),
    );

    return koCopy;
}
export class KeepoutActionsMixin {
    constructor(dispatcher, keepout) {
        this.dispatcher = dispatcher;
        this.keepout = keepout;
    }

    openDetail(keepout) {
        $state.go('designer.design.keepouts.edit', keepout);
    }

    panTo({ keepout = this.keepout, dispatcher = this.dispatcher, select = false } = {}) {
        dispatcher.renderer.zoom(keepout);

        if (select) {
            this.openDetail(keepout);
        }
    }

    lidarAvailable({ dispatcher = this.dispatcher } = {}) {
        if (!dispatcher.renderer) return false;
        return dispatcher.renderer.lidarAvailable();
    }

    hasLidarAccess() {
        return lidarEnabled();
    }

    fitToLIDAR({ keepout = this.keepout, dispatcher = this.dispatcher } = {}) {
        analytics.track('lidar.keepout.fitToLidar', {
            project_id: this.dispatcher.design.project_id,
            design_id: this.dispatcher.design.design_id,
            team_id: $rootScope.user().team_id,
        });

        const pts = dispatcher.renderer.lidarPoints();
        const referenceHeight = fitPhysicalSurfaceHeightToHighestInlierPoint(keepout, pts);

        if (referenceHeight !== null) {
            dispatcher.createSinglePropertyChange({
                resource: keepout,
                path: 'reference_height',
                oldVal: keepout.reference_height,
                newVal: referenceHeight,
                mergeable: true,
            });
        } else {
            Messager.error(`Could not fit ${keepout}`);
        }
    }

    // TODO: add ability to undo and redo bulk change operations
    updateSetbacks({ dispatcher = this.dispatcher, outerSetback } = {}) {
        const designManager = dispatcher.designManager;

        for (const keepout of dispatcher.design.keepouts) {
            const lastSetback = keepout.outer_setback;
            keepout.outer_setback = outerSetback;

            // schedule the update on the stateHandler directly because not in undo stack
            dispatcher.stateHandler.updateQueue.schedule(keepout);
            designManager.keepoutCallback(keepout, 'outer_setback', outerSetback, lastSetback);
        }
    }

    updateHeights({ dispatcher = this.dispatcher, referenceHeight } = {}) {
        const designManager = dispatcher.designManager;

        for (const keepout of dispatcher.design.keepouts) {
            const lastHeight = keepout.reference_height;
            keepout.reference_height = referenceHeight;

            // schedule the update on the stateHandler directly because not in undo stack
            dispatcher.stateHandler.updateQueue.schedule(keepout);
            designManager.keepoutCallback(keepout, 'reference_height', referenceHeight, lastHeight);
        }
    }

    changeConfig({ keepout = this.keepout, dispatcher = this.dispatcher, isClone = false } = {}) {
        return {
            create: {
                text: `Create Keepout: ${keepout}`,
                preflight: () => {
                    delete keepout.keepout_id;
                    dispatcher.designManager.keepoutCallback(keepout, 'geometry.path');
                    dispatcher.renderer.renderKeepout(keepout, { renderOptions: MapConfig.keepout.base });
                },
                onSuccess: (ko) => {
                    Messager.success(`Successfully created ${ko}`);
                    dispatcher.publish('entityKeepoutsChanged', { created: ko, isClone });
                },
                onError: (err) => {
                    Messager.error(`Error creating ${keepout}`);
                    dispatcher.designManager.removeKeepout(keepout);
                    dispatcher.publish('entityKeepoutsChanged', { error: keepout });
                    $log.warn(err);
                },
            },
            delete: {
                text: `Remove Keepout: ${keepout}`,
                preflight: () => {
                    // todo: this check is to prevent artifacts from being rendered by trying to
                    // exit a keepout state after the keepout has been deleted, should check for
                    // the specific keepout or otherwise cleanup
                    if ($state.is('designer.design.keepouts.edit')) {
                        $state.go('designer.design.keepouts');
                    }
                },
                onSuccess: (ko) => {
                    Messager.success(`Successfully deleted ${ko}`);
                    dispatcher.designManager.removeKeepout(keepout);
                    dispatcher.publish('entityKeepoutsChanged', { deleted: ko });
                },
                onError: (err) => {
                    Messager.error(`Error deleting ${keepout}`);
                    dispatcher.publish('entityKeepoutsChanged', { error: keepout });
                    $log.warn(err);
                },
            },
        };
    }

    deleteKeepout({ keepout = this.keepout, dispatcher = this.dispatcher } = {}) {
        this.dispatcher.selectEntity(keepout, {}, false);
        return dispatcher.stateHandler.deleteObject(keepout, this.changeConfig({ keepout, dispatcher }));
    }

    createKeepout({ keepout, dispatcher = this.dispatcher, isClone = false } = {}) {
        return dispatcher.stateHandler.createObject(keepout, this.changeConfig({ keepout, dispatcher, isClone }));
    }


    move(dispatcher, { keepout, oldPath, newPath, delta }) {
        const oldGeometry = _.assign({}, keepout.geometry, { path: oldPath });
        keepout.move(delta, { shiftLocation: false });
        const newGeometry = _.assign({}, keepout.geometry, { path: newPath });

        if (newGeometry.height_reference) {
            newGeometry.height_reference = Bounds.pathMidPoint(newGeometry.path);
        }

        dispatcher.createSinglePropertyChange({
            resource: keepout,
            path: 'geometry',
            oldVal: oldGeometry,
            newVal: newGeometry,
            mergeable: false,
            loadMessage: `Move ${keepout}`,
            rollbackMessage: `Undo ${keepout} move`,
        });
    }

    createSODDescription(description, number) {
        return `${description} (${number})`;
    }

    createKeepoutCopy(keepout, isSOD = false, number = 1) {
        const keepouts = keepout.design.keepouts;
        const taken = keepouts.map((k) => k.description);
        const koCopy = new Keepout(_.extend({}, keepout, {
            description: isSOD ? this.createSODDescription(keepout.description, number) : createUniqueDescription(keepout.description, taken),
            keepout_id: null,
        }),
        );

        return koCopy;
    }

    paste({ keepout, dispatcher = this.dispatcher, groundPoint }) {
        const koCopy = createKeepoutCopy(keepout);
        const centroid = koCopy.centroid();
        const baseShift = new Geometry.Vector(groundPoint.x - centroid.x, groundPoint.y - centroid.y);

        koCopy.move(baseShift, { shiftPath: true });
        this.createKeepout({ keepout: koCopy, dispatcher: dispatcher, isClone: true });
    }

    createShiftedKeepoutCopy(detection, index, originalCentroid, keepout) {
        const position = new Geometry.Vector(detection.position[0], detection.position[1]);
        const baseShift = new Geometry.Vector(position.x - originalCentroid.x, position.y - originalCentroid.y);
        const koCopy = this.createKeepoutCopy(keepout, true, index + 1);
        koCopy.move(baseShift, { shiftPath: true });
        if (detection.rotation !== 0) {
            koCopy.rotate(detection.rotation);
        }
        return koCopy;
    }

    async generateSimilarKeepouts(keepout, sodResponse) {
        if (sodResponse.detections.length === 0) {
            return { length: 0 };
        }

        const originalCentroid = keepout.centroid();

        const koCopies = sodResponse.detections.map((detection, index) => this.createShiftedKeepoutCopy(detection, index, originalCentroid, keepout));

        const bulkObjects = new BulkObjects(koCopies);

        // const designActionsMixin = new DesignActionsMixin(this.dispatcher, this.dispatcher.design);

        try {
            // Part of bulk object creation is to select the new entities
            const keepoutsCreated = await this.dispatcher.stateHandler.createObject(bulkObjects, bulkChangeConfig(bulkObjects, this.dispatcher));
            this.dispatcher.selectEntity(keepout, {}, true);
            return keepoutsCreated;
        } catch (err) {
            Messager.error('An unknown error has occurred. Please retry the detection');
            $log.warn(err);
            return null;
        }
    }
}

export const keepoutActions = new KeepoutActionsMixin();

export const KEEPOUT_MAP_ACTIONS = {
    'Keepout:click': (dispatcher, { keepout }) => {
        keepoutActions.openDetail(keepout);
    },
    // 'Keepout:rightclick': (dispatcher, context) => {
    //     console.log(context);
    // },

    /**
     * when users alt-drag a keepout, make copy as in Powerpoint/other software
     */
    'Keepout:dragstart': (dispatcher, { keepout, altClick, oldPath }) => {
        if (!altClick) {
            return;
        }

        // pause updates while dragging
        dispatcher.renderUpdater.pause();

        const keepouts = keepout.design.keepouts;
        const taken = keepouts.map((k) => k.description);
        const description = createUniqueDescription(keepout.description, taken);
        const koCopy = new Keepout(
            _.merge({}, keepout,
                {
                    description,
                    keepout_id: null,
                    geometry: { path: oldPath },
                }),
        );

        // initially put the keepout where the old one used to be so that the copy looks smooth
        // render as little as possible ot keep things fast
        _.delay(() => dispatcher.renderer.renderKeepout(koCopy, {
            renderOptions: MapConfig.keepout.base,
            renderSetback: false,
            renderShadow: false,
        }));

        dispatcher.subscribeOnce('Keepout:dragend', (disp, context) => {
            if (context.keepout === keepout) {
                /**
                 * Note: This code should work to swap the paths back and add the polygon,
                 * but there is a bug with Google Maps where if you finish draggin a polygon, move it,
                 * and then immediately click again, it will select the same polygon, regardless of
                 * the new location.  Moving the mouse a few pixels fixes it, but it breaks the
                 * functionality. Example here: https://plnkr.co/edit/Tsh9O3U6fwvMw8B1jJ8t?p=preview
                 *
                 * // put the old polygon back to where it was
                 * keepout.geometry.path = context.oldPath;
                 * dispatcher.renderer.renderKeepout(keepout);
                 *
                 * // and put the new one in teh new location
                 * koCopy.geometry.path = context.newPath;
                 * dispatcher.renderer.renderKeepout(koCopy);
                 *
                 * keepoutActions.createKeepout({ keepout: koCopy, dispatcher });
                 *
                 */

                //  Instead we'll just move the polygon to the new location and put the copy in the
                //  old one, as two separate change events
                keepoutActions.move(dispatcher, { shiftPath: false, ...context });
                keepoutActions.createKeepout({ keepout: koCopy, dispatcher, isClone: true });
                dispatcher.renderer.highlightKeepout(keepout, true);

                // give the user a lag to highlight another field segment before letting the save trigger
                dispatcher.renderUpdater.unpause(1500);
            }
        });
    },
    'Keepout:dragend': (dispatcher, context) => {
        if (!context.altClick) {
            keepoutActions.move(dispatcher, { shiftPath: false, ...context });
            dispatcher.renderer.highlightKeepout(context.keepout, true);
        }
    },

    'Keepout:updatePath': (dispatcher, { keepout, oldPath, newPath }) => {
        let action;

        const cleanedPath = correctOrientation(newPath);

        if (cleanedPath.length === oldPath.length) {
            action = 'path change on';
        } else if (cleanedPath.length > oldPath.length) {
            action = 'add point to';
        } else {
            action = 'remove point from';
        }

        const oldGeometry = _.assign({}, keepout.geometry, { path: oldPath });
        const newGeometry = _.assign({}, keepout.geometry, {
            path: cleanedPath,

            // behavior to match current customer expectations, should be improved
            height_reference: Bounds.pathMidPoint(cleanedPath),
        });


        dispatcher.createSinglePropertyChange({
            resource: keepout,
            path: 'geometry',
            oldVal: oldGeometry,
            newVal: newGeometry,
            mergeable: true,
            loadMessage: `Redo ${action} ${keepout}`,
            rollbackMessage: `Undo ${action} ${keepout}`,
        });
    },

    /**
     * need to defer hover rendering, because otherwise it can get in the way of click detection
     * for object detection
     */
    'Keepout:mouseover': (dispatcher, { keepout, editable, dragging }) => {
        if (!(editable || dragging)) {
            _.defer(() => dispatcher.renderer.highlightKeepout(keepout, true));
        }
    },
    'Keepout:mouseout': (dispatcher, { keepout, editable, dragging }) => {
        if (!(editable || dragging)) {
            _.defer(() => dispatcher.renderer.highlightKeepout(keepout, false));
        }
    },
};
