import { RelationalBase } from 'helioscope/app/relational';
import {
    bearingFromVector,
    Matrix,
    pathContains,
    Plane,
    pointInPolygon,
    signedArea,
    toRadians,
    Vector,
    XY_PLANE,
} from 'helioscope/app/utilities/geometry';

import { arrayItemWrap } from 'helioscope/app/utilities/helpers';
import { has, get } from 'lodash';

export class PhysicalSurface extends RelationalBase {
    getShadingHull() {
        return this;
    }

    containsPath(path) { // todo: axe ?
        return pathContains(this.geometry.path, path);
    }

    containsPoint(point) { // todo: axe ?
        return pointInPolygon(point, this.geometry.path);
    }

    groundArea() {
        return Math.abs(signedArea(this.geometry.path));
    }

    surfaceArea() {
        return Math.abs(signedArea(this.geometry.path)) / Math.cos(toRadians(this.surfaceTilt()));
    }

    surfaceTilt() {
        throw new Error('surface tilt not implemented');
    }

    surfaceAzimuth() {
        throw new Error('surface azimuth not implemented');
    }

    surfaceNormal() {
        let norm = new Vector(0, 0, 1);
        norm = Matrix.rotateX(this.surfaceTilt()).transform(norm);
        norm = Matrix.rotateZ(180 - this.surfaceAzimuth()).transform(norm);
        return norm;
    }

    referencePoint() {
        throw new Error('reference point not implemented');
    }

    get referenceHeight() {
        return this.reference_height || 0;
    }

    set referenceHeight(value) {
        this.reference_height = value;
    }

    referenceBottomHeight() {
        if (this.parentSurface() !== undefined) {
            const pt = this.parentSurface().pointOnSurface(this.referencePoint());
            return pt.z;
        }

        return 0;
    }

    get innerSetback() {
        return this.inner_setback || 0;
    }

    set innerSetback(value) {
        this.inner_setback = value;
    }

    get outerSetback() {
        return this.outer_setback || 0;
    }

    set outerSetback(value) {
        this.outer_setback = value;
    }

    pointOnSurface(pt) {
        return this.surfacePlane.pointFromXY(pt);
    }

    numParents() {
        return this.design.designScene().numParents(this);
    }

    parentSurface() {
        return this.design.designScene().parentSurface(this);
    }

    surfacePath3d() {
        return this.geometry.path_3d;
    }

    hasDerivedGeometry() {
        return has(this, 'geometry.path_3d') && has(this, 'geometry.base_3d');
    }

    centroid() {
        const centroid = this.geometry.path.reduce((acc, vec) => {
            return {
                x: acc.x + vec.x,
                y: acc.y + vec.y,
            };
        }, {x: 0, y: 0});

        return {
            x: centroid.x / this.geometry.path.length,
            y: centroid.y / this.geometry.path.length,
        };
    }

    /**
     * create planes that describe the surface, attempting to presever whatever
     * is already a part of the surface on the server
     */
    initializePlanes() {
        if (this.hasDerivedGeometry()) {
            this.surfacePlane = Plane.fromPath(this.geometry.path_3d);
            this.basePlane = Plane.fromPath(this.geometry.base_3d);
        } else {
            this.basePlane = XY_PLANE;

            if (get(this, 'geometry.path.length', 0) === 0) {
                this.surfacePlane = XY_PLANE;
                return;
            }
            const coplanarPoint = this.referencePoint().addXYZ(0, 0, this.referenceHeight);
            this.surfacePlane = Plane.fromOrientation(this.surfaceTilt(), this.surfaceAzimuth(), coplanarPoint);
        }
    }

    updatePlanes(surfacePlane, basePlane = XY_PLANE) {
        this.surfacePlane = surfacePlane;
        this.basePlane = basePlane;

        this.recompute3DPaths();
    }

    recompute3DPaths() {
        this.geometry.path_3d = this.surfacePlane.pathFromXYs(this.geometry.path);
        this.geometry.base_3d = this.basePlane.pathFromXYs(this.geometry.path);

        if (this.recomputeCallback) this.recomputeCallback();
    }

    // note: this azimuth direction has the opposite sign of what @mengusfungus has used elsewhere
    // but appears to have the correct sign mathmatically (Azimuth 180º = due south = (0, -1, 0))
    azimuthDirection(rotationDelta = 0) {
        return new Vector(
            Math.sin(toRadians(this.surfaceAzimuth() + rotationDelta)),
            Math.cos(toRadians(this.surfaceAzimuth() + rotationDelta)),
            0,
        );
    }

    getEdgeInfo(idx) {
        const surfacePath = this.geometry.path_3d;
        const edgeVec = arrayItemWrap(surfacePath, idx + 1).subtract(arrayItemWrap(surfacePath, idx));

        return {
            distance: edgeVec.length(),
            heading: bearingFromVector(edgeVec),
        };
    }

    zoomPath() {
        return [
            ...(this.geometry.path_3d || this.geometry.path),
            ...(this.geometry.base_path_3d || this.geometry.path),
        ];
    }
}

export class PremadePointSurface extends PhysicalSurface {
    static _unitHullGeometry = null;

    static unitHullGeometry() {
        if (!PremadePointSurface._unitHullGeometry) {
            const radius = 1.0 / Math.cos(2.0 * Math.PI / 16);

            const path = [];
            for (let i = 0; i < 8; ++i) {
                const rad = ((i + 0.5) / 8) * 2.0 * Math.PI;
                path.push(new Vector(Math.cos(rad) * radius, Math.sin(rad) * radius));
            }

            PremadePointSurface._unitHullGeometry = path;
        }

        return PremadePointSurface._unitHullGeometry;
    }

    constructor(entity) {
        super({});

        this.entity = entity;
        this.design = entity.design;

        this.basePlane = XY_PLANE;
        this.surfacePlane = XY_PLANE;

        this.$update = entity.$update ? entity.$update.bind(entity) : null;
    }

    getShadingHull() {
        const unitHull = PremadePointSurface.unitHullGeometry();

        const { parameters } = this.entity.geometry;
        const radius = parameters.top_radius;
        const point = parameters.position;
        const baseHeight = parameters.position_3d.z;
        const botHeight = parameters.bot_height;
        const topHeight = parameters.top_radius * 2.0;
        const height = botHeight + topHeight;

        const geometry = {
            path: _.map(unitHull, i => new Vector(i.x * radius + point.x, i.y * radius + point.y)),
            base_3d: _.map(unitHull, i => new Vector(
                i.x * radius + point.x, i.y * radius + point.y, baseHeight)),
            path_3d: _.map(unitHull, i => new Vector(
                i.x * radius + point.x, i.y * radius + point.y, baseHeight + height)),
        };

        const surfacePlane = Plane.fromPath(geometry.path_3d);

        return new PhysicalSurface({ geometry, surfacePlane });
    }

    surfaceTilt() {
        return 0;
    }

    surfaceAzimuth() {
        return 0;
    }

    referencePoint() {
        return this.geometry.path[0];
    }

    containsPath() {
        return false;
    }

    containsPoint() {
        return false;
    }

    groundArea() {
        return 0;
    }

    castsShadows() {
        return true;
    }

    receivesShadows() {
        return false;
    }

    get outerSetback() {
        const { parameters } = this.entity.geometry;
        return parameters.top_radius;
    }

    get sceneLeafOnly() {
        return true;
    }

    initialize() {
        const point = this.entity.geometry.parameters.position_3d;

        if (point) {
            this.setPoint(point);
        }
    }

    setPoint(point) {
        const eps = 0.001;

        this.geometry = {
            path: [
                new Vector(point.x - eps, point.y - eps),
                new Vector(point.x + eps, point.y - eps),
                new Vector(point.x + eps, point.y + eps),
                new Vector(point.x - eps, point.y + eps),
            ],
        };
    }

    renderOverride(renderer) {
        renderer.renderPremade(this.entity, null);
    }

    recomputeCallback() {
        if (this.entity.proxySurfaceUpdated) this.entity.proxySurfaceUpdated();
    }

    point3D() {
        if (this.hasDerivedGeometry()) return this.geometry.path_3d[0];
        return this.geometry.path[0];
    }
}

/**
 * create a special class for the ground to circumvent any comparisons
 */
class _GroundSurface extends PhysicalSurface {
    constructor(data) {
        super(data);
        this.basePlane = XY_PLANE;
        this.surfacePlane = XY_PLANE;
    }

    geometry = {
        path: [
            new Vector(-1e7, -1e7, 0),
            new Vector(-1e7, 1e7, 0),
            new Vector(1e7, 1e7, 0),
            new Vector(1e7, -1e7, 0),
        ],

        path_3d: [
            new Vector(-1e7, -1e7, 0),
            new Vector(-1e7, 1e7, 0),
            new Vector(1e7, 1e7, 0),
            new Vector(1e7, -1e7, 0),
        ],
    }

    surfaceTilt() {
        return 0;
    }

    surfaceAzimuth() {
        return 0;
    }

    referencePoint() {
        return new Vector(0, 0, 0);
    }

    containsPath() {
        return true;
    }

    containsPoint() {
        return true;
    }

    groundArea() {
        return Number.POSITIVE_INFINITY;
    }

    castsShadows() {
        return false;
    }

    receivesShadows() {
        return true;
    }
}

export const THE_GROUND = new _GroundSurface({
    description: 'THE_GROUND',
});
