import { EditSubscriptionCtrl, AddUserCtrl } from './controllers';

export function subscription() {
    'ngInject';

    return {
        restrict: 'E',
        templateUrl: require('helioscope/app/users/subscriptions/partials/subscription.html'),
        scope: {
            subscription: '=',
            notificationBar: '=',
            user: '=',
        },
        controller: EditSubscriptionCtrl,
        controllerAs: 'editSubscriptionCtrl',
    };
}


export function addUser() {
    'ngInject';

    return {
        restrict: 'E',
        templateUrl: require('helioscope/app/users/subscriptions/partials/add-user.html'),
        scope: {
            callbackController: '=',
            showCancel: '=',
            user: '=',
            subscription: '=?',
            isAnnualSubscription: '=?',
            projectsPerLicense: '=?',
        },
        controller: AddUserCtrl,
        controllerAs: 'addUserCtrl',
    };
}
