import Logger from 'js-logger';
import { get } from 'lodash';

import { helioscopeConfig } from 'reports/angular-bridge.ts';

const logger = Logger.get('analytics');

const googleConversionId = 973842766;

export const conversionLabels = {
    purchase: 'Mq5WCPv5m2QQztKu0AM',
    accoutCreated: 'ub9ECPLVnWMQztKu0AM',
    accountCreatedCustomPage: '-RmLCNbarmMQztKu0AM',
};

export function track(name, payload) {
    if (window.analytics) {
        window.analytics.track(name, payload && {
            ...payload,
            app: window.location.href.includes('forceAngular') ? 'reports' : 'old helioscope',
        });
    }
}

export function identify(user) {
    if (window.analytics) {
        window.analytics.identify(String(user.user_id), {
            first_name: get(user, 'first_name', null),
            last_name: get(user, 'last_name', null),
            email: get(user, 'email', null),
            team: get(user, 'team_id', null),
            team_admin: get(user, 'team_admin'),
            helioscope_admin: get(user, 'is_admin'),
            subscription_status: user.team.is_on_custom_plan ? 'active' : get(user, 'subscription.status'),
            subscription_product: user.team.is_on_custom_plan ? 'custom' : get(user, 'subscription.product', null),
            subscription_version: get(user, 'subscription.version'),
            subscription_length: user.team.is_on_custom_plan ? 'year' : get(user, 'subscription.plan_type', null),
            active: user.is_current,
            user: user.user_id,
            consumption_enforced: get(user, 'team.should_enforce_consumption'),
            // Amplitude only tracks if using semantic versioning format major.minor[.patch]
            version: helioscopeConfig != null ? `${helioscopeConfig.version}.0` : null,
            created: get(user, 'created', null),
            fraud: get(user, 'flagged', null),
        });

        if (get(user, 'team_id', null) !== null) {
            window.analytics.group(String(get(user, 'team_id')));
        }
    }
}

export function trackConversion(user, conversionLabel, conversionValue = null) {
    const data = {
        google_conversion_id: googleConversionId,
        google_conversion_label: conversionLabel,
        google_custom_params: {
            email: user.email,
        },
        google_remarketing_only: true,
    };

    if (conversionValue) {
        data['google_conversion_value'] = conversionValue;
        data['google_conversion_currency'] = 'USD';
    }

    if (!window.google_trackConversion) {
        logger.debug(`convert transaction ${conversionLabel}`, data);
        return;
    }

    window.google_trackConversion(data);
}

export function trackKeyboardAction(action, design, user) {
    if (!design || !user) {
        logger.info('Failed to capture keyboard action. Missing design or user.');
        return;
    }

    track(action, {
        project_id: design.project_id || null,
        design_id: design.design_id || null,
        team_id: user.team_id || null,
    });
}
