import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { actionCreatorFactory, isType, Action } from 'typescript-fsa';

import { addDefaultHeader, removeDefaultHeader, HEADERS } from 'reports/modules/request';
import * as users from 'reports/models/user';
import * as rawFormatters from 'reports/utils/formatters';
import { IAppState } from 'reports/store';

import * as permissions from 'reports/modules/auth/permissions';
import { ReduxEndpoint } from 'reports/utils/api';

export interface IAuthState {
    loggedInUserId?: number;
    impersonateUser?: string | number; // email or user id
}

const ANON_USER = new users.User({
    is_admin: false,
    active_features: [],
});

const LOGOUT_HOME = '/';

// Global user export, can be imported to short circuit Redux state management
let currentUser = ANON_USER;

const actionCreator = actionCreatorFactory('__NOOP');
const noop = actionCreator<undefined>('NOOP');

export const actions = {
    onLogout: () => {
        if (self.document) {
            self.document.location.replace(LOGOUT_HOME);
        }
        return noop(undefined);
    },
    updateCurrentUser: () => (_dispatch: Dispatch, getState: () => IAppState) => {
        currentUser = getUser(getState())!;
    },
};

export interface SetPasswordFormData {
    new_password: string;
    confirm_password: string;
    terms_of_use: boolean;
}

export interface ForgotPasswordFormData {
    email: string;
}

export const api = {
    activate: users.endpoint.put<{ auth_token: string }>('activate/{auth_token}'),
    login: users.endpoint.post<{
        email: string;
        password: string;
        remember_me: boolean;
    }>('login'),
    logout: users.endpoint.post<undefined>('logout', {
        onSuccess: actions.onLogout,
    }),
    verifyPasswordChangeToken: users.endpoint.put<{ token: string }>('validate_token/{token}'),
    setPassword: users.endpoint.post<SetPasswordFormData, { token: string }, { error: string }>(
        '{token}/set_password',
        {
            ...ReduxEndpoint.PassThroughConfig(),
        },
    ),
    forgotPassword: users.endpoint.post<ForgotPasswordFormData, {}>('reset', {
        ...ReduxEndpoint.PassThroughConfig(),
    }),
};

const getState = (state) => state;
const getUserId = (state: IAppState) => state.auth.loggedInUserId;

const getUser = createSelector(getState, getUserId, users.selectors.byId);

const DISTANCE_UNIT_FROM_SYMBOL = { ft: 'foot', m: 'meter' } as const;
const formatters = createSelector(getUser, (user) => {
    // Currently, user's preferred distance unit is stored as a symbol: 'm' or 'ft'.
    const distanceUnits = user == null ? 'meter' : DISTANCE_UNIT_FROM_SYMBOL[user.preferences.units.distance];

    return {
        distance: (meters: number, { unit = distanceUnits, ...otherOpts }: rawFormatters.IDistanceOptions) =>
            rawFormatters.distance(meters, { unit, ...otherOpts }),
    };
});

export const selectors = {
    getUser,
    formatters,
    asUserOverride: (state: IAppState) => state.auth.impersonateUser,
};

export const reducer = (state: IAuthState = { loggedInUserId: undefined }, action: Action<any>): IAuthState => {
    if (isType(action, api.login.done)) {
        const user = action.payload.result;
        currentUser = user;

        const impersonateUser = user._masquerading_admin ? user.email : undefined;
        if (impersonateUser) {
            addDefaultHeader(HEADERS.impersonateUser, user.email);
        }

        return {
            impersonateUser,
            loggedInUserId: user.user_id,
        };
    }

    if (isType(action, api.activate.done)) {
        const user = action.payload.result;
        currentUser = user;

        return {
            impersonateUser: undefined,
            loggedInUserId: user.user_id,
        };
    }

    if (isType(action, api.logout.done)) {
        removeDefaultHeader(HEADERS.impersonateUser);
        removeDefaultHeader(HEADERS.overridePermissions);
        return {
            impersonateUser: undefined,
            loggedInUserId: undefined,
        };
    }

    return state;
};

export { permissions, currentUser as user };
