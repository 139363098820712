/* global angular:true, _:true */
import moment from 'moment';

import { user as loggedInUser } from 'helioscope/app/users';

const mod = angular.module(
    'helioscope.admin.directives',
    ['helioscope.users.resources', 'helioscope.libraries.services', 'ngCookies'],
);

const displayUser = (user) => {
    /*
     * We need to escape the formatted text here, because it comes from an untrusted source (users themselves) and the
     * select-2 directive doesn't HTML-escape the displayed text when rendering it to the DOM.
     */
    if (user && user.user_id) {
        return _.escape(`${user.first_name} ${user.last_name} (${user.email})`);
    } else if (user) {
        return _.escape(user.text);
    }
    return '';
};

mod.directive('userSelector', (User, select2DirectiveFactory, select2QueryFactory) => {
    const userSelectOptions = {
        resource: User,
        id: user => user && user.user_id,
        formatter: displayUser,
        itemFromId: userId => User.cached(userId),
        noResultsText: 'No Results Found',
        cacheName: 'userLibrary',
        initialQuery: (args) => (
            User.query(Object.keys(args).length > 0 ? args : { team_id: loggedInUser.team_id }).$promise
        ),
        searchQuery: select2QueryFactory({
            resource: User,
            sortFn: user => `${user.last_name} ${user.first_name}`,
            groupBy: (user) => {
                if (user.team != null) {
                    return user.team.name;
                } else {
                    return user.company ? user.company : 'Unaffiliated';
                }
            },
        }),
    };

    return select2DirectiveFactory('userSelector', userSelectOptions);
});

mod.directive('downtimeAlert', () => ({
    restrict: 'EA',
    scope: { startTimeUtc: '@', duration: '@' },
    templateUrl: require('helioscope/app/admin/partials/downtime-alert.html'),
    controller: ['$cookies', '$scope', function ctrl($cookies, $scope) {
        const start = moment.utc($scope.startTimeUtc, 'YYYY-MM-DD HH:mm:ss').local();
        const end = start.clone().add(moment.duration(120, 'minutes'));
        const displayStart = start.clone().subtract(moment.duration(10, 'days'));
        this.startDate = start.format('MMM Do');
        this.startTime = start.format('LT');
        this.endTime = end.format('LT');
        if (moment().isBefore(displayStart) || moment().isAfter(end)) {
            this.hidden = true;
            return;
        }

        this.hidden = $cookies.get('hideDowntimeAlert');

        this.close = () => {
            $cookies.put('hideDowntimeAlert', true);
            this.hidden = true;
        };
    }],
    controllerAs: 'downtimeAlertCtrl',
}));
