import * as THREE from 'three';

// This is 1 greater than the max of the int4 type in postgres
export const MAX_OVERLAY_ORDER = 2147483648;

export function getCenterPoint(fourPoints) {
    const p1 = new THREE.Vector3(fourPoints[0].x, fourPoints[0].y, 0);
    const p2 = new THREE.Vector3(fourPoints[2].x, fourPoints[2].y, 0);
    return (new THREE.Vector3()).addVectors(p1, p2).multiplyScalar(0.5);
}

export function applyRotationToFourPoints(fourPoints, angle) {
    const centerPoint = getCenterPoint(fourPoints);
    const transformMatrix = new THREE.Matrix3();
    transformMatrix.set(
        Math.cos(angle), -1 * Math.sin(angle), 0,
        Math.sin(angle), Math.cos(angle), 0,
        0, 0, 0,
    );

    for (const point of fourPoints) {
        point.sub(centerPoint).applyMatrix3(transformMatrix).add(centerPoint);
    }
    return fourPoints;
}

export function getFourPoints(overlay, rotate = true) {
    const p1 = overlay.overlay_parameter.quad_points[0];
    const p2 = overlay.overlay_parameter.quad_points[1];
    const p3 = overlay.overlay_parameter.quad_points[2];
    const p4 = overlay.overlay_parameter.quad_points[3];
    const angle = overlay.overlay_parameter.rotation_angle; // radian

    const fourPoints = [
        (new THREE.Vector3(p1.x, p1.y, 0)),
        (new THREE.Vector3(p2.x, p2.y, 0)),
        (new THREE.Vector3(p3.x, p3.y, 0)),
        (new THREE.Vector3(p4.x, p4.y, 0)),
    ];

    if (rotate) {
        return applyRotationToFourPoints(fourPoints, angle);
    } else {
        return fourPoints;
    }
}
