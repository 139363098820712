export const SitePlanConfig = {
    mapDiv: 'map_canvas',

    fieldSegment: {
        baseZIndex: 0,
        base: {
            strokeColor: '#000000',
            fillOpacity: 0.0,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            editable: false,
        },
        parentOverride: {
        },
        active: {
        },
        hover: {
        },
        setback: {
            fillColor: '#888888',
            opacity: 1.0,
            strokeWeight: 0,
            editable: false,
            clickable: false,
        },
        draggable: {
        },
        dragEnd: {
        },
    },

    module: {
        base: {
            fillColor: '#ffffff',
            strokeColor: '#000000',
            zIndex: 10003,
            clickable: false,
            strokeWeight: 1,
            fillOpacity: 0.0,
            strokeOpacity: 0.5,
        },
        racking: {
            strokeWeight: 1.4,
            fillColor: '#ffffff',
            strokeColor: '#000000',
            zIndex: 10001,
            fillOpacity: 0.0,
            strokeOpacity: 1.0,
            clickable: false,
            editable: false,
        },
        frames: {
            fillOpacity: 0.0,
            fillColor: '#ffffff',
            strokeColor: '#000000',
            zIndex: 10002,
            strokeWeight: 1.3,
            strokeOpacity: 1.0,
        },
        active: {
        },
        wired: {
        },
    },
    keepout: {
        baseZIndex: 100,
        base: {
            strokeColor: '#000000',
            fillOpacity: 0.0,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            editable: false,
        },
        hover: {
        },
        active: {
        },
        setback: {
            fillColor: '#888888',
            opacity: 1.0,
            strokeWeight: 0,
            editable: false,
            clickable: false,
        },
        shadow: {
        },
        activeShadow: {
        },
    },

    string: {
        strokeColor: '#00AA00',
        strokeWeight: 0.6,
        zIndex: 10006,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },
    bus: {
        strokeColor: '#22BB22',
        strokeWeight: 2,
        zIndex: 10006,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },
    acRun: {
        strokeColor: '#CC6600',
        strokeWeight: 3,
        zIndex: 10007,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },
    acBranch: {
        strokeColor: '#CC6600',
        strokeWeight: 0.6,
        zIndex: 10007,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },

    inverter: {
        title: 'Inverter',
        icon: {
            url: require('helioscope/app/designer/static/inverter-icon.png'),
            size: [30, 30],  // new google.maps.Size(30, 30),
            origin: [0, 0],  // new google.maps.Point(0, 0),
            anchor: [25, 25],  // new google.maps.Point(25, 25),
            scaledSize: [30, 30],  // new google.maps.Size(30, 30),
        },
        zIndex: 10007,
        draggable: true,
    },

    combiner: {
        title: 'Combiner',
        icon: {
            url: require('helioscope/app/designer/static/combiner-icon.jpeg'),
            size: [10, 13],
            origin: [0, 0],
            anchor: [5, 6],
            scaledSize: [10, 13],
        },
        draggable: true,
        zIndex: 10008,
    },
    interconnect: {
        title: 'Interconnect',
        draggable: true,
        zIndex: 10010,
    },
    acPanel: {
        title: 'AC Panel',
        icon: {
            url: require('helioscope/app/designer/static/panel-icon.jpeg'),
            size: [10, 13],
            origin: [0, 0],
            anchor: [5, 6],
            scaledSize: [10, 13],
        },
        draggable: true,
        zIndex: 10009,
    },

};
