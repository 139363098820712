/* eslint-env browser */

import Logger from 'js-logger';

import { chain, mapValues, cloneDeep, extend, zip, sumBy, groupBy, get } from 'lodash';
import * as analytics from 'helioscope/app/utilities/analytics';

import { $q, $filter, $http, $rootScope } from 'helioscope/app/utilities/ng';
import { hslToRGB } from 'helioscope/app/utilities/colors';
import { wait } from 'helioscope/app/utilities/helpers';
import { toRadians } from 'helioscope/app/utilities/geometry/math';

import { shortMonths, helioscopeConfig } from 'helioscope/app/config';

import { DesignDispatcher } from 'helioscope/app/designer/events';
import { aggregateSimResults } from 'helioscope/app/designer/shading/shade_calculator';
import { NewShadeCalculator } from 'helioscope/app/designer/shading/new_shade_calculator';
import { addCanvasImageOverlays } from 'helioscope/app/utilities/ng-pdf';
import { findOptimalTiltAzimuth } from 'helioscope/app/designer/shading/single_diode';

const logger = Logger.get('reports/shade');

const MAX_TILE_WAIT = 7500;

const RENDER_DEFAULTS = {
    modules: true,
    inverters: false,
    combiners: false,
    wiring: false,
    field_segments: true,
    keepouts: true,
    interconnect: false,
};

export class ShadingReportCtrl {
    constructor(simulation, $scope) {
        'ngInject';

        this.simulation = simulation;
        this.design = simulation.design;
        this.scenario = simulation.scenario;
        this.$scope = $scope;
        this.team_id = $rootScope.user().team_id;

        analytics.track('shade_report.view', {
            nameplate: this.design.field_component_metadata.nameplate,
            project_id: this.design.project.project_id,
            design_id: this.design.design_id,
            team_id: this.team_id,
        });

        this.requestedRenders = [];

        this.design.initializeDesignScene();

        this.dispatcher = new DesignDispatcher(simulation.design, {
            designMutable: false,
            showWiring: true,
        });

        const { design, scenario } = this;

        this.hasActualModuleData = false && simulation.metadata.module_level_data_id != null;

        if (this.hasActualModuleData) {
            this.shadeCalculator = new ActualsShadeCalculator(simulation);
        } else {
            this.shadeCalculator = new NewShadeCalculator(design, scenario.weather_dataset, scenario.horizon);
        }

        this.generateShadeReport();

        $scope.$on('$destroy', () => {
            this.dispatcher.cleanup();
        });
    }

    async loadDesign() {
        await this.dispatcher.loadComponents();
        this.updateRender(RENDER_DEFAULTS);
    }

    async updateRender(renderSettings) {
        this.dispatcher.renderer.setRenderOverrides(renderSettings);
        this.dispatcher.renderer.renderDesign(this.design);

        this.dispatcher.renderer.fitProjectView();
    }

    async getOptimalPoaData() {
        const project = this.design.project;
        const dataset = this.scenario.weather_dataset;
        const weatherDatasetId = dataset.weather_dataset_id;

        let optimal;
        if (project.geometry.optimal_orientations && project.geometry.optimal_orientations[weatherDatasetId]) {
            optimal = project.geometry.optimal_orientations[weatherDatasetId];
        } else {
            optimal = await findOptimalTiltAzimuth(
                this.design.project,
                this.scenario.weather_dataset,
                this.design.field_segments[0].module_characterization,
            );
        }

        this.optimalPoaData = {
            poaIrradiance: optimal.poa_irradiance,
            tilt: optimal.tilt,
            azimuth: optimal.azimuth,
        };

        return this.optimalPoaData;
    }

    async generateShadeReport() {
        const designReady = this.loadDesign();
        const simulationReady = this.shadeCalculator.initialize();

        const [optimalPoaData, fsModuleResults] = await $q.all([
            this.getOptimalPoaData(),
            designReady.then(() => simulationReady).then(() => this.shadeCalculator.getModuleResults()),
            this.dispatcher.onRendererReady().then(() => this.dispatcher.renderer.tileHelperReady.promise),
        ]);

        const summary = this.summarizeResults(fsModuleResults);
        this.renderModuleColors(summary, optimalPoaData);

        const promises = [this.dispatcher.renderer.tileHelper.visibleTilesRendered().then(() => 'success')];

        if (helioscopeConfig.debug === false) {
            promises.push(wait(MAX_TILE_WAIT).then(() => 'timeout'));
        }

        const res = await Promise.race(promises);

        if (res === 'timeout') {
            logger.error('Timed out waiting for map tile promise');
        }

        await this.createRenders();
    }

    /**
     * color modules based on how shaded they are.  We probably need to upgrade the algorithm
     * for how to color them, because very little art was put into it
     */
    renderModuleColors({ fieldSegmentSummary }, optimalPoaData) {
        const optimalPoaIrradiance = optimalPoaData.poaIrradiance;

        const renderer = this.dispatcher.renderer;
        const allModuleResults = _(fieldSegmentSummary).map('adjModuleResults').flatten().value();

        const worstPoa = Math.min(optimalPoaIrradiance * 0.7, ...allModuleResults.map((x) => x.shadedIrradiance));
        const denominator = optimalPoaIrradiance - worstPoa;

        for (const { module, shadedIrradiance } of allModuleResults) {
            // the worst module will be 1, an unshaded module oriented optimally will be 0
            const shadeScalar = (optimalPoaIrradiance - shadedIrradiance) / denominator;

            renderer.setModuleRenderOptions(module, {
                fillColor: heatMapColorforValue(shadeScalar),
            });
        }

        const numFilter = $filter('number');
        this.heatmapLabels = [0, 0.25, 0.5, 0.75, 1].map((value) => {
            const poa = (optimalPoaIrradiance - worstPoa) * value + worstPoa;
            return {
                value,
                label: `${numFilter(poa / 1000, 0)}, (${numFilter((100 * poa) / optimalPoaIrradiance, 0)}%)`,

                // modules in the heatmap are shaded, so desaturate the color in the legend (to 0.65)
                // to darken the gradient to match
                color: heatMapColorforValue(value, 0.65),
            };
        });
    }

    requestRender(settings, callback) {
        this.requestedRenders.push([settings, callback]);
    }

    async createRenders() {
        // add an overlay on top of the current renderer to hide the snapshotting
        const removeImageOverlay = await addCanvasImageOverlays(this.dispatcher.renderer.container);

        const viewCamera = this.dispatcher.renderer.saveCameraState();
        for (const [{ azimuth, elevation }, callback] of this.requestedRenders) {
            const snapshotSettings = {
                cameraTheta: toRadians(elevation - 90),
                cameraPhi: toRadians(180 - azimuth),
            };

            this.dispatcher.renderer.restoreCameraState(extend(cloneDeep(viewCamera), snapshotSettings));
            this.dispatcher.renderer.zoomScene();
            await this.dispatcher.renderer.cameraUpdateCompleted(); // eslint-disable-line no-await-in-loop
            await this.dispatcher.renderer.tileHelper.visibleTilesRendered(); // eslint-disable-line no-await-in-loop
            const { blob } = await this.dispatcher.renderer.capture(); // eslint-disable-line no-await-in-loop
            callback(URL.createObjectURL(blob));
        }

        this.dispatcher.renderer.restoreCameraState(viewCamera);
        removeImageOverlay();
        this.$scope.$apply(); // need to force a digest given the await
    }

    summarizeResults(fsModuleResults) {
        const designSummary = { ...this.simulation.metadata.monthly_data };
        for (let monthIdx = 0; monthIdx < 12; monthIdx++) {
            if (designSummary[monthIdx + 1] === undefined) {
                designSummary[monthIdx + 1] = {
                    global_horizontal_irradiance: 0,
                    grid_power: 0,
                    nameplate_power: 0,
                    poa_irradiance: 0,
                    shaded_irradiance: 0,
                    total_irradiance: 0,
                };
            }
        }

        this.shadingSummary = {
            fieldSegmentSummary: summarizeFieldSegments(fsModuleResults, designSummary),
            designSummary,
            modules: sumBy(fsModuleResults, ({ fieldSegment }) => get(fieldSegment, 'data.modules', 0)),
            power: sumBy(fsModuleResults, ({ fieldSegment }) => get(fieldSegment, 'data.power', 0)),
        };

        this.$scope.$apply(); // ensure that the table updates ASAP

        return this.shadingSummary;
    }
}

// http://stackoverflow.com/questions/12875486/what-is-the-algorithm-to-create-colors-for-a-heatmap
function heatMapColorforValue(value, saturation = 1.0) {
    const hue = ((1.0 - value) * 120) / 360; // 120 should give a base color of green for the highest performing modules
    return hslToRGB(hue, saturation, 0.5, 1);
}

/**
 * summarize the field segment results calculated from client shading + single diode model
 * and adjust to match a simulation from the server
 */
function summarizeFieldSegments(fsModuleResults, designSummary) {
    const fieldSegmentSummary = [];

    for (const { fieldSegment, moduleResults } of fsModuleResults) {
        if (moduleResults.length === 0) {
            continue;
        }

        fieldSegmentSummary.push({
            fieldSegment,
            rawModuleResults: moduleResults, // array by month of individual module results
        });
    }

    return normalizeSimulation(fieldSegmentSummary, designSummary);
}

/**
 * takes a raw Module Results array, which has an obect for each module that
 * includes both annual and monthly results and pivots it returning an array
 * with entries for each month that contain an array of module results for that period
 */
function pivotModuleResultsToMonthly(moduleResults) {
    const rtn = new Array(12);

    for (let month = 0; month < 12; month++) {
        const monthlyModules = [];
        rtn[month] = monthlyModules;

        for (const { module, monthly } of moduleResults) {
            monthlyModules.push({
                module,
                ...monthly[month + 1],
            });
        }
    }

    return rtn;
}

/**
 * takes a monthly array of modules results and pivots it back into a single
 * of module results that each include the monthly breakdown
 */
function pivotModuleResultsFromMonthly(monthlyModuleResults) {
    const moduleCount = monthlyModuleResults[0].length;

    const rtn = new Array(moduleCount);

    for (let mod = 0; mod < moduleCount; mod++) {
        const monthly = {};
        for (let month = 0; month < 12; month++) {
            monthly[month + 1] = monthlyModuleResults[month][mod];
        }

        const moduleResult = {
            module: monthlyModuleResults[0][mod].module,
            ...aggregateSimResults(Object.values(monthly), false),
        };

        moduleResult.monthly = monthly;

        rtn[mod] = moduleResult;
    }

    return rtn;
}
/**
 * augment field segment results with results adjusted to match a simulation from the server
 */
function normalizeSimulation(fieldSegmentSummary, designSummary) {
    const fsModuleResultsByMonth = fieldSegmentSummary.map(({ rawModuleResults }) =>
        pivotModuleResultsToMonthly(rawModuleResults),
    );

    // will adjust each everything on a month by month basis
    const adjFsModuleResultsByMonth = fieldSegmentSummary.map(() => new Array(12));

    for (let month = 0; month < 12; month++) {
        const monthModuleResults = fsModuleResultsByMonth.map((monthlyModuleResults) => monthlyModuleResults[month]);
        const normalizedResults = flattenAndReconstruct(monthModuleResults, (flattenedModuleResults) =>
            normalizeSimulationMonth(flattenedModuleResults, designSummary[month + 1]),
        );

        for (let fs = 0; fs < monthModuleResults.length; fs += 1) {
            adjFsModuleResultsByMonth[fs][month] = normalizedResults[fs];
        }
    }

    const rtn = [];
    for (const [adjModuleResultsByMonth, fsSummary] of zip(adjFsModuleResultsByMonth, fieldSegmentSummary)) {
        const adjModuleResults = pivotModuleResultsFromMonthly(adjModuleResultsByMonth);

        rtn.push({
            adjModuleResults,
            adjResults: aggregateSimResults(adjModuleResults),
            ...fsSummary,
        });
    }

    return rtn;
}

/**
 * return an identically structured object to module results, but with the results normalized
 * to match the provided simulation results
 */
function normalizeSimulationMonth(moduleResults, simResult) {
    const nameplates = [];
    const rawPoaIrradiance = [];
    const rawShadeLoss = [];
    const rawShadedAcEnergy = [];

    for (const { module, poaIrradiance, shadedIrradiance, shadedAcEnergy } of moduleResults) {
        nameplates.push(module.fieldSegment.module_characterization.power);
        rawPoaIrradiance.push(poaIrradiance);
        rawShadeLoss.push(poaIrradiance - shadedIrradiance);
        rawShadedAcEnergy.push(shadedAcEnergy);
    }

    const {
        poa_irradiance: simPoaIrradiance,
        shaded_irradiance: simShadedIrradiance,
        grid_power: simShadedAcEnergy,
    } = simResult;

    // because these values are in kWh/m^2 they need to be normalized according to the weighted average
    const adjPoaIrradiance = normalizeWeightedAvg(simPoaIrradiance, rawPoaIrradiance, nameplates);

    const adjShadeLoss = normalizeWeightedAvg(simPoaIrradiance - simShadedIrradiance, rawShadeLoss, nameplates);

    // because this is the total for the whole array, just normalize the aggregate value
    const adjShadedAcEnergy = normalizeTotal(simShadedAcEnergy, rawShadedAcEnergy);

    const rtn = [];

    for (let mod = 0; mod < moduleResults.length; mod++) {
        const { module, acEnergy } = moduleResults[mod];

        rtn.push({
            module,

            poaIrradiance: adjPoaIrradiance[mod],
            shadedIrradiance: adjPoaIrradiance[mod] - adjShadeLoss[mod],
            shadedAcEnergy: adjShadedAcEnergy[mod],
            acEnergy, // largeley meaningless, but keeps the types consistent
        });
    }

    return rtn;
}

/**
 * take an array of arrays, flatten it to run through the callback, and then
 * return the results back into the original structure.
 *
 * requires a callback that takes a flat array and returns an identical length flat array
 * that respects object order
 */
function flattenAndReconstruct(arrayOfArrays, processArrayCallback) {
    const lengths = [];
    const flattened = [];

    for (const array of arrayOfArrays) {
        lengths.push(array.length);
        flattened.push(...array);
    }

    // results must be the same length and order as flattened
    const results = processArrayCallback(flattened);

    const rtn = [];
    for (const length of lengths) {
        rtn.push(results.splice(0, length));
    }

    return rtn;
}

const MIN_VALUE = 1e-4; // ensures that with a zero data array and a non-zero targetValue you get reasonable results

/**
 * normalize the values in dataarray so that they're average matches the targetValue when
 * weighted by an optional array of `weightings`
 */
function normalizeWeightedAvg(targetValue, dataArray, weightings) {
    let weightedSum = 0;
    let totalWeight = 0;

    // even when using the minimum value, allocate the delta based on the weightings
    // rather than uniformly across the data array
    const minValues = normalizeTotal(MIN_VALUE, weightings);
    const adjValues = new Array(dataArray.length);

    for (let i = 0; i < dataArray.length; i++) {
        adjValues[i] = dataArray[i] + minValues[i];
        weightedSum += weightings[i] * adjValues[i];
        totalWeight += weightings[i];
    }

    const weightedValue = weightedSum / totalWeight;
    const factor = targetValue / weightedValue;

    return adjValues.map((x) => x * factor);
}

/**
 * normalize the sum value of dataArray to match the targetValue
 */
function normalizeTotal(targetValue, dataArray) {
    let sum = 0;

    for (const value of dataArray) {
        sum += value + MIN_VALUE;
    }
    const factor = targetValue / sum;

    return dataArray.map((x) => (x + MIN_VALUE) * factor);
}

export function fieldSegmentShadingTable() {
    function summarizeOrientiations(fieldSegment, adjModuleResults) {
        const groupedModResults = groupBy(adjModuleResults, (res) => res.module.rotation);
        const modulePower = fieldSegment.module_characterization.power;

        const baseAzimuth = fieldSegment.azimuth - 90;

        const rtn = [];

        for (const rotation of Object.keys(groupedModResults)) {
            const modResults = groupedModResults[rotation];

            rtn.push({
                azimuth: (baseAzimuth + parseFloat(rotation)) % 360,
                tilt: fieldSegment.tilt,
                power: modResults.length * modulePower,
                modules: modResults.length,
                results: aggregateSimResults(modResults),
            });
        }

        return rtn;
    }

    return {
        restrict: 'EA',
        scope: { summary: '=', simulation: '=', optimalPoaData: '=', showMinTsrf: '=' },
        templateUrl: require('helioscope/app/projects/reports/partials/shading.field_segments.html'),
        link(scope) {
            scope.math = Math;
            scope.$watch('::summary', (summary) => {
                if (!summary) return;
                scope.fsSummary = summary.fieldSegmentSummary.map(({ fieldSegment, adjResults, adjModuleResults }) => ({
                    fieldSegment,
                    adjResults,
                    subarrays:
                        fieldSegment.rack_type === 'dual' ? summarizeOrientiations(fieldSegment, adjModuleResults) : [],
                }));
                scope.minShadedIrradiance = Math.min(
                    ...scope.fsSummary.map(({ adjResults }) => adjResults.minShadedIrradiance),
                );
            });
        },
    };
}

export function monthlyShadingTable() {
    return {
        restrict: 'EA',
        scope: { summary: '=' },
        templateUrl: require('helioscope/app/projects/reports/partials/shading.monthly.html'),
        link(scope) {
            scope.shortMonths = shortMonths;
        },
    };
}

export function designSnapshot() {
    return {
        restrict: 'EA',
        scope: { ctrl: '=', perspective: '=' },
        templateUrl: require('helioscope/app/projects/reports/partials/shading.snapshot.html'),
        link: (scope) => {
            scope.imgSrc = null;
            scope.ctrl.requestRender(scope.perspective, (src) => {
                scope.imgSrc = src;
            });
        },
    };
}

/**
 * turn any element into a vertical color gradient
 */
export function gradientKey() {
    function removeFromDom(elements) {
        for (const el of elements) {
            el.parentNode.removeChild(el);
        }

        elements.length = 0;
    }

    return {
        restrict: 'EA',
        scope: { labels: '=' },
        link(scope, [element]) {
            const domLabels = [];
            scope.$watch('labels', (labels) => {
                removeFromDom(domLabels);

                if (!labels) return;

                const elHeight = element.offsetHeight;
                const elWidth = element.offsetWidth;

                const colors = [];

                for (const { value, label, color } of labels) {
                    const labelEl = document.createElement('div');
                    labelEl.innerHTML = label;
                    element.appendChild(labelEl);
                    domLabels.push(labelEl);

                    labelEl.style.width = '300px';
                    labelEl.style.position = 'absolute';
                    labelEl.style.left = `${elWidth + 2}px`;
                    labelEl.style.bottom = `${value * elHeight - labelEl.offsetHeight / 2}px`;

                    colors.push(`${color} ${(value * 100).toFixed(0)}%`);
                }

                element.style.background = `linear-gradient(${colors.join(',')})`;
            });
        },
    };
}

class ActualsShadeCalculator {
    constructor(simulation) {
        this.simulation = simulation;
    }

    initialize() {
        this.moduleResultsPromise = $http.get(`/api/simulations/${this.simulation.simulation_id}/module_level`);
        return true;
    }

    async getModuleResults() {
        // fieldSegment, moduleResults
        const actualResults = (await this.moduleResultsPromise).data;

        const design = this.simulation.design;
        const fieldModuleMap = design.getFieldModuleMap();
        // final structure needs to be
        // Array<{
        //      fieldSegment: FieldSegment,
        //      moduleResults: Array<{
        //          module: fieldModule,
        //          acEnergy, poaIrradiance, shadedAcEnergy,
        //          monthy: {[1..12]: { acEnergy, poaIrradiance, shadedAcEnergy }}
        //      }>
        // }>

        return chain(actualResults)
            .map((monthlyModuleResults, fieldModuleId) => {
                const module = fieldModuleMap[fieldModuleId];

                const monthly = mapValues(monthlyModuleResults, ActualsShadeCalculator.reformat);

                return {
                    module,
                    monthly,
                    ...ActualsShadeCalculator.aggregate(monthly),
                };
            })
            .groupBy((moduleResult) => moduleResult.module.fieldSegment.field_segment_id)
            .toPairs()
            .map(([fieldSegmentId, moduleResults]) => ({
                fieldSegment: design.fieldSegment(fieldSegmentId),
                moduleResults,
            }))
            .value();
    }

    static reformat(moduleResult) {
        return {
            poaIrradiance: moduleResult.poa_irradiance,
            shadedIrradiance: moduleResult.shaded_irradiance,
            shadedAcEnergy: moduleResult.power, // # best proxy
        };
    }

    static aggregate(monthlyResults) {
        const result = { poaIrradiance: 0, shadedIrradiance: 0, shadedAcEnergy: 0 };

        for (const res of Object.values(monthlyResults)) {
            result.poaIrradiance += res.poaIrradiance;
            result.shadedIrradiance += res.shadedIrradiance;
            result.shadedAcEnergy += res.shadedAcEnergy;
        }

        return result;
    }
}
