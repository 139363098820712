import Logger from 'js-logger';

import { round, get, filter, find } from 'lodash';
import moment from 'moment';

import { INITIAL_TOU_DATE } from 'helioscope/app/constants';
import * as analytics from 'helioscope/app/utilities/analytics';
import { getChat } from 'helioscope/app/utilities/chat-wrapper';
import { Authenticator, Messager, $modal, $state, $stateParams } from 'helioscope/app/utilities/ng';
import { getBetaBillingURL } from 'helioscope/app/utilities/url';
import { helioscopeConfig } from 'helioscope/app/config';

import { user as loggedInUser, User } from 'helioscope/app/users';

import { PaymentService, ShareSubscriptionDlg, updateCustomFields, prePaymentTOU } from './services';
import { checkout } from './stripe';
import { fetchSubscriptionPricing, calculateSubscriptionCost, invoiceStatus, Subscription } from
    './Subscription';


const logger = Logger.get('subscriptions/controllers');

const DEFAULT_PROJECTS_PER_LICENSE_PER_MONTH = 10;

export class EditSubscriptionCtrl {
    constructor($scope) {
        'ngInject';


        this.scope = $scope;
        this.subscription = $scope.subscription;
        this.invoiceStatus = invoiceStatus;
        this.notificationBar = $scope.notificationBar;
        this.dirty = false;
        this.loading = false;
        this.canEdit = this.subscription.status === 'active';
        this.user = $scope.user;
        this.showCreditCards = false;
        this.isAdmin = loggedInUser.is_admin || loggedInUser._masquerading_admin;
        this.proLearnMoreLink = 'https://helioscope.aurorasolar.com/pricing/';
        this.contactSupportLinkAnnual = `mailto:support@helioscope.com?subject=HelioScope Subscription&body=Hello,%0D%0A%0D%0A
I would like to speak to someone on the Support team about updating to annual billing.%0D%0A%0D%0A
Thank you,%0D%0A%0D%0A`;
        this.contactSupportLinkPro = `mailto:support@helioscope.com?subject=Interested in Pro plan&body=Hello,%0D%0A%0D%0A
I would like to speak to someone on the Support team about upgrading to the Pro plan.%0D%0A%0D%0A
Thank you,%0D%0A%0D%0A`;
        this.customPlanSalesLink = `mailto:sales@helioscope.com?subject=HelioScope Subscription&body=Hello, I would like to speak to someone in Sales about a Custom plan.%0D%0A%0D%0A
About my business:%0D%0A%0D%0A
How many projects does your business average per month and per year?%0D%0A
(Enter your answer here)%0D%0A%0D%0A
What percentage of your monthly and annual projects do you anticipate will be less than 50KW in scale?%0D%0A
(Enter your answer here)%0D%0A%0D%0A
Is your business-focus primarily in the residential or commercial/industrial space?%0D%0A
(Enter your answer here)
        `;

        this.extendDays = 5;
    }

    get seatsDelta() {
        return this.subscription.paid_seats - this.subscription.subscribers.length;
    }

    get isAnnual() {
        return this.subscription.plan_type === 'year';
    }

    get productMetadata() {
        return helioscopeConfig.product_metadata[this.subscription.product_key];
    }

    get additionalProjectsPerLicensePerMonth() {
        const productMetadata = this.productMetadata;
        return productMetadata ? productMetadata.limits.projects_per_license_per_month : DEFAULT_PROJECTS_PER_LICENSE_PER_MONTH;
    }

    get additionalProjectsPerLicensePerYear() {
        return this.additionalProjectsPerLicensePerMonth * 12;
    }

    get projectsPerYearForAllLicenses() {
        return this.additionalProjectsPerLicensePerYear * this.subscription.paid_seats;
    }

    toggleCards() {
        this.showCreditCards = !this.showCreditCards;
    }

    removeSubscriber(subscriber) {
        this.subscription.removeSubscriber(subscriber);
        this.dirty = true;
        this.refreshPricing();
    }

    addUserStarted() {
        this.showAddUserDialog = true;
    }

    addUserFinished(subscriber) {
        this.showAddUserDialog = false;
        if (!subscriber) {
            return;
        }

        if (this.subscription.hasUser(subscriber)) {
            Messager.info(`You already have ${subscriber.first_name} ${subscriber.last_name} (${subscriber.email})`);
        } else {
            this.subscription.addSubscriber(subscriber);
        }

        this.dirty = true;
        this.refreshPricing();
    }

    async refreshPricing() {
        // only stripe-managed subscriptions are supported
        if (!this.subscription.stripe_id) {
            return;
        }

        this.loading = true;
        const seats = this.subscription.subscribers.length;

        this.updatedPricing = await fetchSubscriptionPricing(this.subscription, seats);
        this.loading = false;
    }

    async createInvoice() {
        try {
            await this.subscription.$createInvoice();
            $state.go('account.detail.billing', {}, { reload: true });
        } catch (err) {
            const { error = 'Error creating invoice' } = err.data;
            this.notificationBar.error(error);
        }
    }
    get description() {
        const { subscription } = this;
        const exp = subscription.current_period_end;
        if (!exp) {
            return '';
        }

        const cost = calculateSubscriptionCost(
            subscription.subscribers.length,
            subscription.plan,
            subscription.discount,
        );

        let description = '';

        if (this.subscription.canceled) {
            description = `This subscription will expire on ${exp.format('LL')}.`;
        } else {
            description = `This subscription will renew on ${exp.format('LL')}, with the cost of $${(cost.total.toFixed(2))}.`;
        }

        if (this.updatedPricing && this.updatedPricing.amount_due > 0) {
            const amount = round(this.updatedPricing.amount_due / 100, 2);
            description += `\n$${amount} will be billed to your card today.`;
        }

        return description;
    }

    async applyChanges() {
        const args = {};
        if (this.updatedPricing) {
            args['prorate_timestamp'] = this.updatedPricing['prorate_timestamp'];
        }

        try {
            await this.subscription.$update(args);
            this.scope.notificationBar.success('Subscription has been updated');
            this.dirty = false;
            this.updatedPricing = null;
        } catch (e) {
            const { errorMsg } = e.data;
            this.scope.notificationBar.error(errorMsg || 'Error updating subscription');
        }
    }

    async updatePayment() {
        const token = await checkout({
            panelLabel: 'Update Payment Method',
            description: this.subscription.plan.description,
            email: this.subscription.admin_email || this.user.email,
        });

        if (_.isEmpty(token)) {
            return;
        }

        const notification = Messager.load('Processing');

        try {
            this.subscription = await this.subscription.$updatePayment({ token: token.id });
            this.scope.notificationBar.success('Payment method has been updated');
        } catch (e) {
            this.scope.notificationBar.error('Error updating payment method');
        } finally {
            notification.close();
        }
    }

    async retryPay() {
        this.loading = true;
        try {
            this.subscription = await this.subscription.$pay();
            this.scope.notificationBar.success('Subscription has been paid');
        } catch (e) {
            logger.error(`Error paying subscription ${e}`);
        } finally {
            this.loading = false;
        }
    }

    async cancelSubscription() {
        try {
            await this.subscription.$cancelSubscription();
            this.scope.notificationBar.success('Subscription has been canceled');
        } catch (e) {
            const { error = 'Error canceling subscription' } = e.data;
            this.scope.notificationBar.error(error);
        }
    }
    async updatedPaymentAndPay() {
        await this.updatePayment();
        await this.retryPay();
    }

    async extendUsers() {
        const payload = {
            reason: (
                `Subscription ${this.subscription.subscription_id} ` +
                `adjusted by admin: ${loggedInUser._masquerading_admin_email || loggedInUser.email}`
            ),
            days: this.extendDays,
        };

        return Promise.all(this.subscription.subscribers.map(u => (new User(u)).$extendTrial(payload)));
    }

    cancelAccount() {
        $modal.open({
            templateUrl: require('helioscope/app/users/subscriptions/partials/cancel.html'),
            windowClass: 'centered-modal',
            controller: function cancelAccount($scope) {
                'ngInject';

                $scope.showSupport = () => {
                    getChat().newMessage("Hello. I'd like to cancel my account.");
                    $scope.$close();
                };
            },
        });
    }
}

export class AddUserCtrl {
    constructor($scope) {
        'ngInject';

        this.scope = $scope;
        this.callbackController = $scope.callbackController;

        this.userDomain = AddUserCtrl.getDomain($scope.user.email);
        this.subscriptionAllowsNonTeammates = ($scope.user.team.subscription_type === 'v1_non_1_to_1' ||
            $scope.user.hasFeature('allow_v1_multi_team_subscriptions'));
        this.userTeamId = $scope.user.team_id;

        this.userToAdd = {};
        this.isSearching = false;
        this.showCancel = this.scope.showCancel;
        this.isAnnualSubscription = this.scope.isAnnualSubscription;
        this.projectsPerLicense = this.scope.projectsPerLicense;
        this.isNewSubscription = this.scope.subscription === undefined;

        this.existingUserButtonLabel = this.isNewSubscription ? 'Add existing user' : 'Add license for existing user';
        this.newUserButtonLabel = this.isNewSubscription ? 'Add new user' : 'Add license for new user';

        this.checker = _.debounce(async (email) => {
            try {
                const users = await User.query({ email }).$promise;

                const foundUser = find(users, u => u.email === email);

                if (foundUser != null) {
                    // duplicate object since it will be modified directly by ng
                    this.userToAdd = Object.assign({}, foundUser);
                }
                this.hasValidSubscription = this.validators.hasValidSubscription();
                this.isValidUser = this.validators.isValidUser();
                this.isTeamMember = this.isValidUser && this.validators.isTeamMember();

                this.failsTeamMemberCheck =
                    (this.isValidUser && !this.isTeamMember && !this.subscriptionAllowsNonTeammates);
                this.isError = this.hasValidSubscription || this.failsTeamMemberCheck;
            } finally {
                this.isSearching = false;
            }
        }, 300);
    }

    static getDomain(email) {
        return `@${email.replace(/.*@/, '')}`;
    }

    get existingUser() {
        return !!this.userToAdd.user_id;
    }

    emailChanged() {
        this.userToAdd = {
            email: this.userToAdd.email,
            first_name: this.userToAdd.first_name,
            last_name: this.userToAdd.last_name,
        };

        this.isSearching = true;
        this.checker(this.userToAdd.email);
    }

    reset() {
        this.userToAdd = {};
    }

    addUserClicked() {
        this.callbackController.addUserFinished(this.userToAdd);
        this.reset();
        this.form.$setPristine(true);
    }

    closeClicked() {
        this.callbackController.addUserFinished(null);
    }

    validators = {
        hasValidEmail: (email) => !email || AddUserCtrl.getDomain(email) === this.userDomain || loggedInUser.is_admin,
        hasValidSubscription: () =>
            !!(this.userToAdd.user_id && this.userToAdd.subscription && this.userToAdd.subscription.external_id),
        isValidUser: () => !!(this.userToAdd.user_id && this.userToAdd.team_id),
        isTeamMember: () => !!(this.userToAdd.user_id && this.userToAdd.team_id === this.userTeamId),
    };
}

export class NewSubscriptionCtrl {
    constructor($scope, stripeData) {
        'ngInject';

        const { plans, discounts } = stripeData;
        this.scope = $scope;

        this.monthlyPlan = plans['helioscope_monthly_new'];
        this.annualPlan = plans['helioscope_annual_new'];
        this.defaultDiscountForAnnualPlan = '15%';

        this.currentUser = $scope.currentUser;
        this.isAdmin = loggedInUser.is_admin;

        this.existingPlan = undefined;

        if (this.teamHasActiveSubscription()) {
            this.existingPlan = this.currentUser.team.latest_subscription_plan_type === 'year' ?
                this.annualPlan : this.monthlyPlan;
        }

        // don't show this area when we're not supposed to be around
        if (this.currentUser.subscription) {
            $state.go('account.detail.billing');
            return;
        }

        this.subscription = new Subscription({
            creator_id: $scope.currentUser.user_id,
            subscribers: [this.currentUser],
        });
        // As of 12/2022, stripeData only contains non-deprecated discounts.
        this.discounts = Object.values(discounts);
        this.licenseType = ($stateParams.licenseType === 'group' ? 'group' : 'individual');

        // hack: ng-resource will copy the data passed in the constructor, which breaks model binding
        this.subscription.plan = this.existingPlan ||
            ($stateParams.plan === 'annual' ? this.annualPlan : this.monthlyPlan);
        this.subscription.discount = this.existingPlan ?
            this.matchDiscount(
                this.currentUser.team.latest_subscription_discount_stripe_id,
                this.discounts,
            ) : undefined;

        if (loggedInUser.email !== this.currentUser.email) {
            this.betaBillingURL = getBetaBillingURL($scope.user(), this.currentUser.email);
        } else {
            this.betaBillingURL = getBetaBillingURL($scope.user());
        }
    }

    matchDiscount(stripeId, discounts) {
        const [match] = discounts.filter((discount) => discount.stripe_id === stripeId);
        return match;
    }

    teamHasActiveSubscription() {
        return this.currentUser.team.num_active_subscriptions > 0;
    }

    addUserFinished(subscriber) {
        if (!subscriber) {
            return;
        }

        if (this.subscription.hasUser(subscriber)) {
            Messager.info(`You already have ${subscriber.first_name} ${subscriber.last_name} (${subscriber.email})`);
        } else {
            this.subscription.addSubscriber(subscriber);
        }
    }

    removeSubscriber(subscriber) {
        this.subscription.removeSubscriber(subscriber);
    }

    submitSubscription() {
        this.scope.processing = true;

        const loader = Messager.load(`Creating invoice for subscription to ${this.subscription.plan.description} ` +
            `for ${this.subscription.subscribers.length} accounts`);

        // hack: our data model is broken (note the plan being an object vs being a stripe_id).
        // Fix later
        const newSubscription = new Subscription({
            plan: this.subscription.plan.stripe_id,
            discount: this.subscription.discount ? this.subscription.discount.stripe_id : null,
            subscribers: this.subscription.subscribers,
            creator_id: this.subscription.creator_id,
        });

        newSubscription.$save((sub) => {
            loader.success('Successfully created invoice');
            this.scope.processing = false;
            this.scope.currentUser.subscription = sub;
            if (Authenticator.user().user_id === this.scope.currentUser.user_id) {
                Authenticator.user().subscription = sub;
            }
            $state.go('payments', { external_id: sub.external_id, external_invoice_id: sub.invoices[0].external_id });
        }, (response) => {
            const errors = response.data.errors;
            const defaultMessage = response.data.errorMsg || 'Could not create subscription.';
            const planDeprecatedMessage = 'plan' in errors ? errors['plan'][0] : false;
            const message = planDeprecatedMessage || defaultMessage;
            loader.error(message);
            this.scope.processing = false;
        });
    }
}

export class AccountBillingCtrl {
    constructor($scope, allV1Subscriptions) {
        'ngInject';

        this.$scope = $scope;

        this.allV1Subscriptions = allV1Subscriptions;
        this.showAllV1Subscriptions = false;

        // subscriptions with terminal status cannot be actioned on
        this.terminalStatuses = ['canceled', 'incomplete_expired'];

        this.betaBillingURL = loggedInUser.email !== $scope.currentUser.email
            ? getBetaBillingURL($scope.user(), $scope.currentUser.email, {})
            : getBetaBillingURL($scope.user(), '', {});

        this.activeV1Subscriptions = (
            _(allV1Subscriptions)
                .filter(sub => !this.terminalStatuses.includes(sub.status))
                .sortBy('current_period_end')
                .reverse()
                .value()
        );

        this.canceledV1Subscriptions = (
            _(allV1Subscriptions)
                .filter(sub => this.terminalStatuses.includes(sub.status))
                .sortBy('current_period_end')
                .reverse()
                .value()
        );

        if ($scope.currentUser.subscription) {
            // this will be any subscription that is not closed
            this.activeSubscription = $scope.currentUser.subscription;
        }

        if (!$scope.currentUser.activeSubscription() && $state.current.name === 'account.detail.billing') {
            $state.go('account.detail.billing.payment');
        }
    }

    deleteSubscription() {
        const $scope = this.$scope;

        if ($scope.currentUser.subscription) {
            const sub = new Subscription($scope.currentUser.subscription);
            const notification = Messager.load('Removing this subscription...');

            sub.$delete(() => {
                notification.success('Deleted subscription successfully');

                this.allV1Subscriptions = filter(this.allV1Subscriptions, su => su.external_id !== sub.external_id);
                delete $scope.currentUser.subscription;

                $state.go('account.detail.billing', {}, { reload: true });
            }, (resp) => {
                notification.error(`Could not delete this subscription. ${resp.data.error || ''}`);
            });
        }
    }
}


export class PaymentCtrl {
    constructor($scope, subscription) {
        'ngInject';

        $scope.subscription = subscription;

        if ($state.is('payments')) {
            // if no invoice specified get the most recent unpaid invoice
            // make paid invoices always sorted before unpaid invoices
            // regardless of time
            const latestInvoice = _(subscription.invoices).sortBy(
                (i) => (new Date(i.created)).getTime() / (i.paid === true ? 10000000 : 1),
            ).last();

            $state.go('payments.invoice', { invoice_external_id: latestInvoice.external_id }, { location: 'replace' });
        }
    }
}


export class PaymentInvoiceCtrl {
    constructor($scope, invoice) {
        'ngInject';

        const { subscription } = $scope;

        $scope.invoice = invoice;
        $scope.errorMsg = '';
        $scope.successMsg = '';

        $scope.invoiceStatus = invoiceStatus;

        $scope.isAdmin = loggedInUser.is_admin || loggedInUser._masquerading_admin;

        $scope.data = {};
        const extraDays = subscription.plan.stripe_id === 'helioscope_annual' ? 365 : 31;
        $scope.data.newPeriodEnd = moment().add(extraDays, 'days').toDate();

        $scope.pay = async function pay() {
            let loader;
            let acceptTou = false;
            $scope.errorMsg = '';
            $scope.successMsg = '';

            if (new Date() > INITIAL_TOU_DATE && !loggedInUser.tou_accepted) {
                const result = await prePaymentTOU();
                if (!result) {
                    return;
                }
                acceptTou = true;
            }

            PaymentService($scope.invoice, $scope.subscription, { acceptTou, email: $scope.user().email }).then(
                (data) => {
                    if (data.status === 'success') {
                        analytics.trackConversion(
                            subscription.creator, analytics.conversionLabels.purchase, subscription.cost().total,
                        );
                        analytics.track('subscriptions-created-new_subscription', {
                            app: 'old helioscope',
                            billing_frequency: subscription.plan.stripe_id === 'helioscope_monthly' ? 'month' : 'year',
                            product_plan: 'basic',
                            plan_price: subscription.plan.default_amount,
                            seat_count: subscription.paid_seats,
                            subscription_id: subscription.external_id,
                            team_id: subscription.team_id,
                            user_id: subscription.creator.user_id,
                        });
                        loader.success(`You successfully submitted payment for invoice ${$scope.invoice.external_id}`);
                    } else {
                        loader.fail('Payment Failed');
                        return;
                    }

                    $scope.processing = false;
                    $scope.successMsg = (
                        'Thanks for signing up for HelioScope! You will receive an email confirmation shortly.'
                    );

                    $scope.invoice.paid = true;
                    angular.extend($scope.subscription,
                        data.subscription,
                        { current_period_end: moment(data.subscription.current_period_end) });

                    if (Authenticator.user()) {
                        Authenticator.user().current_period_end = $scope.subscription.current_period_end;
                        Authenticator.user().subscription = $scope.subscription;
                        Authenticator.loginUser(Authenticator.user());
                    }
                },
                (data) => {
                    loader.error('This transaction failed');
                    $scope.errorMsg = data.errorMsg;
                    $scope.is3dsError = !!data.errorMsg.includes('3DS');
                    $scope.processing = false;
                },
                (msg) => {
                    if (msg === 'processing') {
                        loader = Messager.load('Processing payment information...');
                        $scope.processing = true;
                    }
                },
            );
        };

        $scope.share = () => {
            ShareSubscriptionDlg($scope.subscription);
        };

        $scope.updateCustomFields = async () => {
            const result = await updateCustomFields({ subscription, invoice });

            if (result === 'saved') {
                $state.reload();
            }
        };

        $scope.markAsPaid = async () => {
            const newEnd = moment($scope.data.newPeriodEnd);

            const newInvoiceData = {
                ...invoice,
                mark_paid: true,
                new_period_end: newEnd.toISOString(),
            };

            await (new Subscription(newInvoiceData)).$updateInvoice({
                external_id: subscription.external_id,
                invoice_id: invoice.external_id,
            });

            subscription.current_period_end = newEnd;
            invoice.paid = true;
            invoice.closed = true;
            subscription.status = 'active';

            $state.reload();
        };

        // 11-29-22: Don't show the invoice view if the subscription is closed before 11-15-22 and out of band.
        // Those invoices render w/ new base price change values rather than maintaining old values, so users will
        // be directed to download the cached invoice PDF.
        $scope.showInvoice = () => {
            const rolloverDate = moment('2022-11-15');
            if (rolloverDate.isAfter(invoice.created)) {
                return (!invoice.closed && invoice.total === subscription.total) || !!invoice.stripe_id;
            }
            return true;
        };
    }
}
