/* globals angular:true */
import { wireLibrary } from 'helioscope/app/utilities/ng';

import { user } from 'helioscope/app/users/auth';
import { loadGoogleMaps } from 'helioscope/app/utilities/maps';
import { Design } from 'helioscope/app/designer/Design';
import { Profile } from 'helioscope/app/users';
import { loadClipper } from 'helioscope/app/utilities/geometry/clipper';
import * as documentSet from './document_set';

import * as reports from './reports';


angular.module('helioscope.projects.directives.reports', [])
    .directive('designRender', reports.designRender)
    .directive('designRender3d', reports.designRender3d)
    .controller('DesignRenderCtrl', reports.DesignRenderCtrl)
    .directive('fieldSegmentShadingTable', reports.fieldSegmentShadingTable)
    .directive('monthlyShadingTable', reports.monthlyShadingTable)
    .directive('gradientKey', reports.gradientKey)
    .directive('designSnapshot', reports.designSnapshot);

(function (angular) {
    'use strict';

    angular.module('helioscope.projects', ['helioscope.projects.controllers',
                                           'helioscope.projects.config',
                                           'helioscope.projects.resources',
                                           'helioscope.projects.services',
                                           'helioscope.projects.directives',
                                           'helioscope.projects.directives.reports']);

    var mod = angular.module('helioscope.projects.config', ['ui.router', 'helioscope.users.auth',
                                                            'ui.bootstrap.tooltip', 'helioscope.services']);

    mod.config(['$stateProvider', 'auth.accessLevels', function ($stateProvider, accessLevels) {
        $stateProvider.
            state('projects', {
                url: '/projects',
                controller: 'ProjectCtrl',
                template: "<div ui-view></div>",
                abstract: true,
                resolve: {
                    user: ['Authenticator', function (a) {return a.authorize(accessLevels.user); }]
                }
            }).
            state('projects.list', {
                url: '?page',
                controller: 'ProjectListCtrl',
                templateUrl: require('helioscope/app/projects/partials/projects.html'),
                resolve: {
                    projects: Project => Project.query({ limit: 10000, include_archived: true }).$promise,
                },
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/projects.list.help.html'
                }
            }).
            state('projects.share_result', {
                url: '/share/{share_hash}',
                controller: 'ProjectShareCtrl',
                templateUrl: require('helioscope/app/projects/partials/project.users.share_result.html'),
                resolve: {
                    project: ['ShareProjectProvider', '$stateParams', function (p, s) {return p(s); }],
                }
            }).
            state('projects.detail', {
                url: '/{project_id:[0-9]+}',
                controller: 'ProjectDetailCtrl',
                templateUrl: require('helioscope/app/projects/partials/project.details.html'),
                resolve: {
                    project: ['ProjectProvider', '$stateParams', function (p, s) {return p(s); }]
                },
                'abstract': true,
            }).
            state('projects.detail.designs', {
                url: '',
                templateUrl: require('helioscope/app/projects/partials/designs/list.html'),
                controller: 'ProjectDesignCtrl',
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/designs/list.help.html'
                }
            }).
            modalState('projects.detail.designs.detail', {
                url: '/designs/{design_id:[0-9]+}',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        windowClass: 'full-screen-modal',
                        controller: 'ProjectDesignDetailModalCtrl',
                        controllerAs: 'designDetailCtrl',
                        templateUrl: require('helioscope/app/projects/partials/designs/summary.modal.html'),
                        resolve: {
                            wires: () => wireLibrary(),
                            clipper: () => loadClipper(),
                            design: () => Design.get($stateParams).$promise,
                        }
                    };
                }],
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/reports/annual.modal.help.html'
                }
            }).
            state('projects.detail.designs.print_detail', {
                url: '/designs/{design_id:[0-9]+}/print',
                views: {
                    '@': {
                        controller: 'ProjectDesignDetailPrintCtrl',
                        templateUrl: require('helioscope/app/projects/partials/designs/summary.printable.html'),
                        resolve: {
                            design: ['$stateParams', (stateParams) => Design.get(stateParams).$promise],
                        }
                    }
                }
            }).
            state('projects.detail.scenarios', {
                url: '/conditions',
                templateUrl: require('helioscope/app/projects/partials/scenarios/list.html'),
                controller: 'ProjectScenarioCtrl',
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/scenarios/list.help.html'
                }
            }).
            modalState('projects.detail.scenarios.new', {
                url: '/new',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        windowClass: 'scenario-modal',
                        templateUrl: require('helioscope/app/projects/partials/scenarios/new.html'),
                        controller: 'ScenarioCtrl',
                        resolve: {
                            googleMaps: () => loadGoogleMaps(),
                            scenario: ['$q', 'ProjectProvider', 'Authenticator', 'Scenario', 'DefaultScenarioParameters', async function ($q, pp, Authenticator, Scenario, DefaultScenarioParameters) {
                                // return a new scenario for this project, based on the user preferences and defaults
                                const project = await pp($stateParams, true);

                                const profileArgs = {};
                                if (project.profile_id) {
                                    profileArgs.project_id = project.project_id;
                                } else if (user.default_profile_id) {
                                    profileArgs.profile_id = user.default_profile_id;
                                }

                                const profile = await Profile.defaults(profileArgs).$promise;
                                await profile.loadDependencies();

                                return new Scenario(angular.extend(
                                        {
                                            'description': 'Condition Set ' + (project.metadata.scenarios + 1),
                                            'project_id': project.project_id
                                        },
                                        DefaultScenarioParameters,
                                        profile.scenario,
                                        Authenticator.user().preferences.scenario

                                    ));
                            }],
                        }
                    };
                }]
            }).
            modalState('projects.detail.scenarios.edit', {
                url: '/{scenario_id:[0-9]+}/edit',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        windowClass: 'scenario-modal',
                        templateUrl: require('helioscope/app/projects/partials/scenarios/new.html'),
                        controller: 'ScenarioCtrl',
                        resolve: {
                            googleMaps: () => loadGoogleMaps(),
                            scenario: ['ScenarioProvider', function (sp) { return sp($stateParams); }],
                        }
                    };
                }]
            }).
            modalState('projects.detail.scenarios.summary', {
                url: '/{scenario_id:[0-9]+}',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        windowClass: 'project-modal',
                        controller: 'ProjectScenarioDetailCtrl',
                        templateUrl: require('helioscope/app/projects/partials/scenarios/summary.html'),
                        resolve: {
                            scenario: ['ScenarioProvider', function (sp) {return sp($stateParams); }]
                        }
                    };
                }],
            }).

            state('projects.detail.reports', {
                url: '/reports',
                templateUrl: require('helioscope/app/projects/partials/reports/matrix.html'),
                controller: 'ProjectReportsCtrl',
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/reports/matrix.help.html'
                }
            }).
            modalState('projects.detail.reports.detail', {
                url: '/{simulation_id:[0-9]+}',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        windowClass: 'full-screen-modal',
                        controller: 'AnnualReportModalCtrl',
                        controllerAs: 'annualReportCtrl',
                        templateUrl: require('helioscope/app/projects/partials/reports/annual.modal.html'),
                        resolve: {
                            wires: () => wireLibrary(),
                            clipper: loadClipper,
                            simulation: ['SimulationProvider', async (simulationProvider) => {
                                const simulation = await simulationProvider($stateParams);
                                await simulation.loadDependencies();
                                await simulation.design.loadDependencies();
                                return simulation;
                            }]
                        }
                    };
                }],
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/reports/annual.modal.help.html'
                }
            }).
            modalState('projects.detail.reports.shading', {
                url: '/{simulation_id:[0-9]+}/shading',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        windowClass: 'full-screen-modal',
                        controller: reports.ShadingReportCtrl,
                        controllerAs: 'shadingReportCtrl',
                        templateUrl: require('helioscope/app/projects/reports/partials/shading.modal.html'),
                        resolve: {
                            wires: () => wireLibrary(),
                            clipper: loadClipper,
                            simulation: ['SimulationProvider', async (simulationProvider) => {
                                const simulation = await simulationProvider($stateParams);
                                await simulation.loadDependencies();
                                await simulation.design.loadDependencies();
                                return simulation;
                            }]
                        }
                    };
                }],
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/reports/annual.modal.help.html'
                }
            }).
            state('projects.detail.reports.print_detail', {
                url: '/{simulation_id:[0-9]+}/print',
                views: {
                    '@': {
                        controller: 'AnnualReportPrintCtrl',
                        templateUrl: require('helioscope/app/projects/partials/reports/annual.printable.html'),
                        resolve: {
                            wires: () => wireLibrary(),
                            clipper: loadClipper,
                            simulation: ['SimulationProvider', '$stateParams', async (simulationProvider, $stateParams) => {
                                const simulation = await simulationProvider($stateParams);
                                await simulation.loadDependencies();
                                await simulation.design.loadDependencies();
                                return simulation;
                            }]
                        }
                    }
                }
            }).
            state('projects.detail.reports.print_shading', {
                url: '/{simulation_id:[0-9]+}/shading/print',
                views: {
                    '@': {
                        controller: reports.ShadingReportCtrl,
                        controllerAs: 'shadingReportCtrl',
                        templateUrl: require('helioscope/app/projects/reports/partials/shading.printable.html'),
                        resolve: {
                            wires: () => wireLibrary(),
                            clipper: loadClipper,
                            simulation: ['SimulationProvider', '$stateParams', async (simulationProvider, $stateParams) => {
                                const simulation = await simulationProvider($stateParams);
                                await simulation.loadDependencies();
                                await simulation.design.loadDependencies();
                                return simulation;
                            }]
                        }
                    }
                }
            }).
            state('projects.detail.users', {
                url: '/users',
                templateUrl: require('helioscope/app/projects/partials/project.users.html'),
                controller: 'ProjectUsersCtrl',
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/project.users.help.html'
                }
            }).
            state('projects.detail.sketchup', {
                url: '/shade_profiles',
                templateUrl: require('helioscope/app/projects/partials/sketchup/list.html'),
                controller: 'ProjectSketchupCtrl',
            }).
            state('projects.detail.sketchup.horizons', {
                url: '/horizons',
                templateUrl: require('helioscope/app/projects/partials/sketchup/horizons.list.html'),
                controller: 'ProjectHorizonListCtrl',
                data: {
                    helpOverlayUrl: 'helioscope/app/projects/partials/sketchup/horizons.help.html'
                }
            }).
            modalState('projects.detail.sketchup.horizons.upload', {
                url: '/upload',
                modalOptionsFactory: [function () {
                    return {
                        controller: 'ProjectHorizonUploadCtrl',
                        templateUrl: require('helioscope/app/projects/partials/sketchup/horizons.upload.html'),
                    };
                }]
            }).
            modalState('projects.detail.sketchup.horizons.details', {
                url: '/{horizon_id:[0-9]+}',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        size: 'lg',
                        controller: 'ProjectHorizonDetailsCtrl',
                        templateUrl: require('helioscope/app/projects/partials/sketchup/horizons.details.html'),
                        resolve: {
                            horizon: ['HorizonProvider', function (hp) {return hp($stateParams); }]
                        }
                    };
                }],
            }).
            modalState('projects.detail.sketchup.renders', {
                url: '/{sketchup_model_id:[0-9]+}',
                modalOptionsFactory: ['$stateParams', function ($stateParams) {
                    return {
                        windowClass: 'project-modal',
                        controller: 'ProjectSketchupDetailCtrl',
                        templateUrl: require('helioscope/app/projects/partials/sketchup/renders.html'),
                        resolve: {
                            sketchupModel: ['SketchupProvider', function (sp) {return sp($stateParams); }]
                        }
                    };
                }]
            }).
            state('projects.detail.document_set', {
                url: '/documents',
                templateUrl: require('helioscope/app/projects/document_set/partials/index.html'),
                controller: documentSet.DocumentSetCtrl,
                controllerAs: 'docCtrl',
                resolve: {
                    designs: (project) => {
                        if (project.designs.length > 0) {
                            return project.designs;
                        }

                        return Design.query({ project_id: project.project_id }).$promise;
                    },
                },
            });
    }]);

    mod.factory('ProjectProvider', ['Project', '$q', function (Project, $q) {
        return function ($stateParams, metadata) {
            var deferred = $q.defer();
            Project.get({project_id: $stateParams.project_id, metadata: metadata === true},
                function (project) {
                    deferred.resolve(project);
                },
                function (resp) {
                    deferred.reject({type: resp.status, message: "Could not load the project"});
                });
            return deferred.promise;
        };
    }]);

    mod.factory('ScenarioProvider', ['Scenario', '$q', function (Scenario, $q) {
        return function ($stateParams) {
            var deferred = $q.defer();
            Scenario.get($stateParams,
                function (scenario) {
                    deferred.resolve(scenario);
                },
                function (resp) {
                    deferred.reject({type: resp.status, message: "Could not load the Condition Set"});
                });
            return deferred.promise;
        };
    }]);

    mod.factory('SketchupProvider', ['SketchupModel', '$q', function (SketchupModel, $q) {
        return function ($stateParams) {
            var deferred = $q.defer();
            SketchupModel.get($stateParams,
                function (sketchupModel) {
                    deferred.resolve(sketchupModel);
                },
                function (resp) {
                    deferred.reject({type: resp.status, message: "Could not load the Shade Profile"});
                });
            return deferred.promise;
        };
    }]);

    mod.factory('SimulationProvider', ['Simulation', '$q', function (Simulation, $q) {
        return function ($stateParams) {
            var deferred = $q.defer();
            Simulation.get($stateParams,
                function (simulation) {
                    deferred.resolve(simulation);
                },
                function (resp) {
                    deferred.reject({type: resp.status, message: "Could not load the simulation results"});
                });
            return deferred.promise;
        };
    }]);

    mod.factory('ShareProjectProvider', ['Project', '$q', '$state', 'Messager', '$timeout',
                     function (Project, $q, $state, messager, $timeout) {

            return function ($stateParams) {
                var deferred = $q.defer();
                Project.share($stateParams,
                    function (project) {
                        messager.success("Congratulations! You now have access to this project");
                        $timeout(function () {
                            $state.go('projects.detail.designs', project);
                        });
                        deferred.resolve(project);
                    },
                    function (resp) {
                        deferred.reject({type: resp.status, message: "Could not add you to the project at this time"});
                    });
                return deferred.promise;
            };
        }]);

    mod.factory('HorizonProvider', ['Horizon', '$q', function (Horizon, $q) {
        return function ($stateParams) {
            var deferred = $q.defer();

            Horizon.get($stateParams,
                function (horizon) {
                    deferred.resolve(horizon);
                },
                function (resp) {
                    deferred.reject({type: resp.status, message: 'Could not load the horizon profile'});
                });
            return deferred.promise;
        };
    }]);


    mod.value('DefaultScenarioParameters', {
        // description
        // weather_source
        // horizon
        // sketchup

        //soiling
        default_soiling: 2.0,
        jan_soiling: 2.0,
        feb_soiling: 2.0,
        mar_soiling: 2.0,
        apr_soiling: 2.0,
        may_soiling: 2.0,
        jun_soiling: 2.0,
        jul_soiling: 2.0,
        aug_soiling: 2.0,
        sep_soiling: 2.0,
        oct_soiling: 2.0,
        nov_soiling: 2.0,
        dec_soiling: 2.0,

        //cell temp
        cell_temp_model: 'sandia',
        temperature_parameters: {
            sandia: [
                {'rack_type': 'rack', 'a': -3.560, 'b': -0.075, 'delta_temperature': 3},
                {'rack_type': 'dual', 'a': -3.560, 'b': -0.075, 'delta_temperature': 3},
                {'rack_type': 'flush', 'a': -2.810, 'b': -0.0455, 'delta_temperature': 0},
                {'rack_type': 'carport', 'a': -3.560, 'b': -0.075, 'delta_temperature': 3},
            ],
            diffuse: [
                {'rack_type': 'rack', 'u_const': 29, 'u_wind': 0},
                {'rack_type': 'dual', 'u_const': 29, 'u_wind': 0},
                {'rack_type': 'flush', 'u_const': 15, 'u_wind': 0},
                {'rack_type': 'carport', 'u_const': 29, 'u_wind': 0},
            ]
        },

        //mismatch
        temperature_variance: 4.0,
        irradiation_variance: 5.0,
        min_module_binning: -2.5,
        max_module_binning: 2.5,

        //components

        //advanced
        transposition_model: 'perez',
        use_project_location: false,
        use_spectral_adjustment: true,

        //ac
        ac_conductor_derate: 0.0,

        // trackers
        tracker_max_angle: 60.0,
        tracker_backtrack: true,
    });

}(angular));
