import { $log } from 'helioscope/app/utilities/ng';
import { RelationalBase, relationship, deserializeObject } from 'helioscope/app/relational';
import { Vector, Bounds, rotatedGridVector } from 'helioscope/app/utilities/geometry';
import { flatten } from 'helioscope/app/utilities/helpers';
import { WiringZone } from 'helioscope/app/designer/wiring_zone';

import {
    DEFAULT_AC_CONFIG,
    singleInput,
    seriesConnection,
    parallelConnection,
    wireLossTransformation,
    powerComponentMixin,
 } from './electrical';


class ComponentBase extends RelationalBase {
    static relationName = '_ComponentBase';

    constructor(data) {
        super(data);

        this.getChildren().forEach(child => {
            child.parent = this;
        });
    }


    // todo: axe this
    getChildren() {
        if (this.children) return this.children;
        if (this.child) return [this.child];

        return [];
    }

    toJSON() {
        const rtn = _.assign({}, this);
        delete rtn.parent;
        return rtn;
    }
}

ComponentBase.configureRelationships({}, { options: { cache: false } });

const gBounds = new Bounds();

@powerComponentMixin({
    source: parallelConnection,
})
export class FieldCombiner extends ComponentBase {
    static relationName = 'FieldCombiner';
    component_type = 'combiner';

    constructor(data) {
        super(data);
        this.setLocation(this.location);
    }

    addChild(child) {
        child.parent = this;
        this.children.push(child);
        child.createConnection();
    }

    getAzimuth() {
        let weightedAzimuth = 0;
        let totalCount = 0;
        for (const child of this.getChildren()) {
            const { azimuth, count } = child.getAzimuth();
            weightedAzimuth += azimuth * count;
            totalCount += count;
        }

        return (totalCount > 0
                ? { azimuth: weightedAzimuth / totalCount, count: totalCount }
                : { azimuth: 180, count: 0 });
    }

    setLocation(location) {
        const children = this.getChildren();
        if (location === undefined) {
            gBounds.reset();
            for (let i = 0; i < children.length; i++) {
                const loc = children[i].location;
                gBounds.extendVector(loc);
            }
            this.location = gBounds.midpoint;
        } else {
            this.location = location;
        }

        if (!this.location) return;

        if (this.parent && this.parent.createConnection) {
            this.parent.createConnection();
        }

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.createConnection) {
                child.createConnection();
            }
        }
    }

    static create(wiringZone, children, tier = null) {
        const Cls = this;

        return new Cls({
            wiring_zone_id: wiringZone.wiring_zone_id,
            children,
            tier,
        });
    }
}

FieldCombiner.configureRelationships(
    {
        location: deserializeObject(Vector),
    },
    { id: 'field_component_id' }
);

@powerComponentMixin({
    source: parallelConnection,
    outputTransformation: (fieldInverter) => {
        const wiringZone = WiringZone.cached(fieldInverter.wiring_zone_id);
        const { voltage, phase } = wiringZone.inverterAcConfig();
        const { max_power: power } = fieldInverter.inverter;

        return {
            phase,
            voltage,
            power,
            current: power / voltage,
        };
    },
})
export class FieldInverter extends FieldCombiner {
    static relationName = 'FieldInverter';
    component_type = 'inverter';

    /**
     * model FieldInverterChildren as an array, where every entry is considered
     * an individual mppt point, and can contain it's own arrayof items
     * connected in parallel
     */
    getChildren() {
        return flatten(this.children);
    }


    addChild(child) {
        if (Array.isArray(child)) {
            child.forEach(x => this.addChild(x));
            return;
        }

        super.addChild(child);
    }

    /**
     * for inveters, children should be an array of arrays where each subarray
     * represents an MPPT Input
     */
    static create(wiringZone, inverterId, mpptInputs) {
        const Cls = this;

        return new Cls({
            wiring_zone_id: wiringZone.wiring_zone_id,
            power_device_id: inverterId,
            children: mpptInputs,
        });
    }

    get acConfig() {
        return this.inverter.ac_config || DEFAULT_AC_CONFIG;
    }

    power() {
        const { inverter, acConfig } = this;
        const powerOut = inverter.max_power;
        const { voltage, phase } = acConfig;

        return {
            powerOut,
            voltage,
            current: powerOut / voltage,
            phase,
        };
    }
}

FieldInverter.configureRelationships(
    {
        inverter: relationship('PowerDevice', { id: 'power_device_id' }),
        location: deserializeObject(Vector),
    },
    { id: 'field_component_id' }
);


@powerComponentMixin({
    source: singleInput,
    outputTransformation: wireLossTransformation,
})
export class FieldBus extends ComponentBase {
    static relationName = 'FieldBus';

    component_type = 'bus';

    get location() {
        return (this.child ? this.child.location : null);
    }

    getAzimuth() {
        return this.child ? this.child.getAzimuth() : { azimuth: 180, count: 0 };
    }

    createConnection(lengthMultiply = 2) {
        if (!this.parent) {
            return;
        }

        const angle = (180 - this.getAzimuth().azimuth);
        const location = this.location;
        const { midpoint, distance } = rotatedGridVector(location, this.parent.location, angle);

        this.path = [location, midpoint, this.parent.location];
        this.length = distance;
        this.bom_length = distance * lengthMultiply;
    }

    static create(wiringZone, wireGaugeId, child, tier) {
        const Cls = this;

        return new Cls({
            wiring_zone_id: wiringZone.wiring_zone_id,
            wire_gauge_id: wireGaugeId,
            child,
            tier,
        });
    }
}

FieldBus.configureRelationships(
    {
        wire: relationship('Wire', { id: 'wire_gauge_id' }),
    },
    { id: 'field_component_id' }
);


@powerComponentMixin({
    source: seriesConnection,
    outputTransformation: wireLossTransformation,
})
export class FieldString extends ComponentBase {
    static relationName = 'FieldString';
    component_type = 'string';

    get location() {
        const first = this.children[0];
        return first && first.location;
    }

    // TODO: remove after replacing all callers with getModules()
    get modules() {
        return this.children;
    }

    getModules() {
        return this.children;
    }

    /**
     * align the strings to the orientation of the modules
     */
    getAzimuth() {
        const modules = this.getModules();
        if (modules.length === 0) {
            return { azimuth: 180, count: 0 };
        }

        // take the azimuth from the 'exit-point', though it's weighted by the total string length
        return { azimuth: modules[0].fieldSegment.azimuth, count: modules.length };
    }

    createConnection(lengthMultiply = 2) {
        if (this.children.length === 0) {
            return;
        }

        const angle = (180 - this.getAzimuth().azimuth);
        const { midpoint, distance } = rotatedGridVector(this.location, this.parent.location, angle);

        this.midpoint = midpoint;
        let moduleLength = 0;
        let lastMod = null;

        const modules = this.getModules();
        for (const mod of modules) {
            if (lastMod) {
                moduleLength += lastMod.path[0].distance(mod.path[0]);
            }

            lastMod = mod;
        }

        this.bom_length = distance * lengthMultiply;
        this.length = distance + moduleLength; // include the jumpers between modules for line losses, but not for bom calcs

        this.path = this.calculatePath();
    }

    calculatePath() {
        const modules = this.getModules();
        const firstChild = modules[0];
        const d1 = firstChild.path[0].distance(this.midpoint);
        const d2 = firstChild.path[1].distance(this.midpoint);
        const sign = firstChild.path[0].x < this.midpoint.x ? -1 : 1;

        let jitter;
        let moduleStart;
        if (d1 < d2) {
            jitter = new Vector(0, sign * Math.max(d1 / 1000, 0.05)).rotateZSelf(180 - firstChild.fieldSegment.azimuth);
            moduleStart = firstChild.path[0].add(jitter);
        } else {
            jitter = new Vector(0, sign * Math.max(d2 / 1000, 0.05)).rotateZSelf(180 - firstChild.fieldSegment.azimuth);
            moduleStart = firstChild.path[1].add(jitter);
        }

        const n = 3 + modules.length;
        const rtn = Array(n);
        rtn[0] = this.parent.location.add(jitter);
        rtn[1] = this.midpoint.add(jitter);
        rtn[2] = moduleStart;
        const bounds = new Bounds();
        for (let i = 0; i < modules.length; i++) {
            const mod = modules[i];
            bounds.resetVectorArray(mod.path);
            rtn[3 + i] = bounds.midpoint;
        }
        return rtn;
    }

    static create(wiringZone, wireGaugeId, children) {
        const Cls = this;

        return new Cls({
            wiring_zone_id: wiringZone.wiring_zone_id,
            wire_gauge_id: wireGaugeId,
            children,
        });
    }

}

FieldString.configureRelationships(
    {
        wire: relationship('Wire', { id: 'wire_gauge_id' }),
    },
    { id: 'field_component_id' }
);


@powerComponentMixin({
    source: seriesConnection,
})
export class FieldOptimizer extends ComponentBase {
    static relationName = 'FieldOptimizer';
    component_type = 'optimizer';

    static create(wiringZone, powerDeviceId, modules) {
        const Cls = this;

        return new Cls({
            wiring_zone_id: wiringZone.wiring_zone_id,
            power_device_id: powerDeviceId,
            children: modules,
        });
    }

    get location() {
        return _.first(this.children).location;
    }

    // pass through getter directly to the module
    get fieldSegment() {
        return _.first(this.children).fieldSegment;
    }

    get path() {
        return _.first(this.children).path;
    }
}

FieldOptimizer.configureRelationships(
    {
        optimizer: relationship('PowerDevice', { id: 'power_device_id' }),
    },
    { id: 'field_component_id' }
);



@powerComponentMixin({
    source: seriesConnection,
})
export class SeriesConnection extends ComponentBase {
    static relationName = 'SeriesConnection'
    component_type = 'series_connection';

    static create(wiringZone, modules) {
        const Cls = this;

        return new Cls({
            wiring_zone_id: wiringZone.wiring_zone_id,
            children: modules,
        });
    }

    get location() {
        return _.first(this.children).location;
    }

    // pass through getter directly to the module
    get fieldSegment() {
        return _.first(this.children).fieldSegment;
    }

    get path() {
        return _.first(this.children).path;
    }
}



@powerComponentMixin({
    source: parallelConnection,
})
export class ParallelConnection extends ComponentBase {
    static relationName = 'ParallelConnection'
    component_type = 'parallel_connection';

    static create(wiringZone, modules) {
        const Cls = this;

        return new Cls({
            wiring_zone_id: wiringZone.wiring_zone_id,
            children: modules,
        });
    }

    get location() {
        return _.first(this.children).location;
    }

    // pass through getter directly to the module
    get fieldSegment() {
        return _.first(this.children).fieldSegment;
    }

    get path() {
        return _.first(this.children).path;
    }
}



// when we calculate total length of the wire connecting modules, we have to
// multiply the distance by constant factor that depends on how many physical
// wires are needed for a given connection between components
function getLengthMultiplyForWiringZone(wiringZoneID) {
    const wiringZone = WiringZone.cached(wiringZoneID);
    const acConfig = wiringZone.inverterAcConfig();

    // The rules, from https://github.com/aurorasolar/helioscope/issues/303:
    // 1 phase Delta: 2 conductors
    // 1 phase WYE: 3 conductors (not sure if this is a thing)
    // 3 phase Delta: 3 conductors
    // 3 phase WYE: 4 conductors
    let lengthMultiply = 2;
    const phase = acConfig.phase;
    const type = (acConfig.ac_circuit || 'wye').toLowerCase(); // 'wye' or 'delta'
    if (phase === 1) {
        if (type === 'wye') {
            lengthMultiply = 3;
        }
    } else if (phase === 3) {
        if (type === 'wye') {
            lengthMultiply = 4;
        } else if (type === 'delta') {
            lengthMultiply = 3;
        } else {
            $log.error(`invalid acConfig.ac_circuit: '${type}' (must be 'wye' or 'delta')`);
        }
    } else {
        $log.error(`invalid acConfig.phase: ${phase} (must be 1 or 3)`);
    }
    return lengthMultiply;
}

@powerComponentMixin({
    source: singleInput,
    outputTransformation: wireLossTransformation,
})
export class AcRun extends FieldBus {
    static relationName = 'AcRun';
    component_type = 'ac_run';

    createConnection() {
        const lengthMultiply = getLengthMultiplyForWiringZone(this.wiring_zone_id);
        super.createConnection(lengthMultiply);
    }
}

AcRun.configureRelationships(
    {
        wire: relationship('Wire', { id: 'wire_gauge_id' }),
    },
    { id: 'field_component_id' }
);

@powerComponentMixin({
    source: parallelConnection,
    outputTransformation: (acPanel, inputPower) => {
        const wiringZone = WiringZone.cached(acPanel.wiring_zone_id);

        const { voltage: panelVoltage, phase: panelPhase } = wiringZone.panel_transformer_config || {};
        const { power, voltage, phase } = inputPower;

        const useTransformers = (wiringZone.use_transformers && panelVoltage && panelPhase);

        return {
            phase: useTransformers ? panelPhase : phase,
            voltage: useTransformers ? panelVoltage : voltage,
            power,
            current: power / (useTransformers ? panelVoltage : voltage),
        };
    },
})
export class AcPanel extends FieldCombiner {
    static relationName = 'AcPanel';
    component_type = 'ac_panel';
}


export class AcBranch extends FieldString {
    static relationName = 'AcBranch';
    component_type = 'ac_branch';

    // TODO: replace all callers with getModules()
    get modules() {
        return this.getModules();
    }

    getModules() {
        // children of a branch are an array of an inverters with a collection of modules
        const children = this.getChildren();
        const rtn = [];
        for (let i = 0; i < children.length; i++) {
            const component = children[i];
            const subChildren = component.getChildren();
            rtn.push.apply(rtn, subChildren);
        }
        return rtn;
    }

    createConnection() {
        if (this.children.length === 0) {
            return;
        }

        const angle = (180 - this.getAzimuth().azimuth);
        const { midpoint, distance } = rotatedGridVector(this.location, this.parent.location, angle);

        this.midpoint = midpoint;

        const lengthMultiply = getLengthMultiplyForWiringZone(this.wiring_zone_id);
        this.bom_length = distance * lengthMultiply;
        // do not include the jumpers between modules for line losses, harness loss takes this into account
        this.length = distance;

        this.path = this.calculatePath();
    }
}

AcBranch.configureRelationships(
    {
        wire: relationship('Wire', { id: 'wire_gauge_id' }),
    },
    { id: 'field_component_id' }
);

export class Interconnect extends FieldCombiner {
    static relationName = 'Interconnect';
    component_type = 'interconnect';


    static create(location, children) {
        const Cls = this;

        return new Cls({ location, children });
    }
}

Interconnect.configureRelationships();

// flattenComponentTree() filter to get all components deriving from FieldCombiner
export const allCombinerTypesFilter = {
    combiner: true,
    inverter: true,
    ac_panel: true,
    interconnect: true,
};
